import {
  CoverageSelection,
  CoverageSelectionItemAkko,
  CoverageSelectionItemPhone,
} from '@/types/plan-registration'
import { PlanIntervals } from '@/constants/coverages'

export const getCoverageKey = (
  cs: CoverageSelection,
): 'annualCoverage' | 'monthlyCoverage' =>
  cs.selectedCoverageInterval == PlanIntervals.ANNUAL
    ? 'annualCoverage'
    : 'monthlyCoverage'

export function checkForCoverageSelectionCompleteness(
  selection: CoverageSelection,
): boolean {
  if (selection.type === 'phone') {
    return !!(
      selection.monthlyCoverage &&
      selection.annualCoverage &&
      (<CoverageSelectionItemPhone>selection.detail).maker &&
      (<CoverageSelectionItemPhone>selection.detail).model
    )
  } else if (selection.type === 'akko') {
    return !!(
      selection.annualCoverage &&
      selection.monthlyCoverage &&
      (<CoverageSelectionItemAkko>selection.detail).type
    )
  } else {
    return !!(
      selection.annualCoverage &&
      selection.monthlyCoverage &&
      (<any>selection.detail).type
    )
  }
}
