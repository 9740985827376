export enum LoosType {
  CRACKED_SCREEN = 'Cracked Screen',
  DROPPED = 'Dropped',
}

export enum ClaimStatus {
  NEW = 'NEW',
  INITIAL_REVIEW_AKKO = 'INITIAL_REVIEW_AKKO',
  WAITING_ON_CUSTOMER = 'WAITING_ON_CUSTOMER',
  ESTIMATE_OR_DIAGNOSIS_NEEDED = 'ESTIMATE_OR_DIAGNOSIS_NEEDED',
  REPAIR_IN_PROCESS = 'REPAIR_IN_PROCESS',
  WAITING_ON_REPAIR_PROVIDER = 'WAITING_ON_REPAIR_PROVIDER',
  IN_REVIEW_AKKO = 'IN_REVIEW_AKKO',
  APPROVED = 'APPROVED',
  DENIED_NOT_A_COVERED_LOSS = 'DENIED_NOT_A_COVERED_LOSS',
  DENIED_FRAUD = 'DENIED_FRAUD',
  DENIED_NOT_VERIFIABLE = 'DENIED_NOT_VERIFIABLE',
  COMPLETED = 'COMPLETED',
}

export enum ClaimStatusUserFriendly {
  NEW = 'New',
  INITIAL_REVIEW_AKKO = 'Initial Review - AKKO',
  WAITING_ON_CUSTOMER = 'Waiting on Customer',
  ESTIMATE_OR_DIAGNOSIS_NEEDED = 'Estimate/Diagnosis Needed',
  REPAIR_IN_PROCESS = 'Repair in Process',
  WAITING_ON_REPAIR_PROVIDER = 'Waiting on Repair Provider',
  IN_REVIEW_AKKO = 'In Review - AKKO',
  APPROVED = 'Approved',
  DENIED_NOT_A_COVERED_LOSS = 'Denied - Not a Covered Loss',
  DENIED_FRAUD = 'Denied - Fraud',
  DENIED_NOT_VERIFIABLE = 'Denied - Not Verifiable',
  COMPLETED = 'Completed',
}

export enum ClaimDeductibleType {
  WAIVE_DEDUCTIBLE = 'Waive deductible',
  REPAIR = 'Repair',
  REPLACEMENT = 'Replacement',
  SCREEN_REPLACEMENT = 'Screen Replacement',
  DEDUCTIBLES = 'Deductibles',
}
