import { FeatureFlagState, FeatureFlagStore } from '@/types/feature-flag'

export enum FeatureFlagMutations {
  SET_FEATURE_FLAGS_ACCESS = 'setFeatureFlagsAccess',
}

const mutations: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: FeatureFlagState, newData: any) => void
} = {}

mutations[FeatureFlagMutations.SET_FEATURE_FLAGS_ACCESS] = (
  state: FeatureFlagState,
  featureFlags: FeatureFlagStore[],
): void => {
  state.feature_flags = featureFlags
}

export default mutations
