
import { defineComponent, PropType } from 'vue'
import PhysicalStorePropertyForm from '@/components/properties/edit-creation-forms/physical-store-property-form.vue'
import { PartnerPropertyFormOptions } from '@/constants/partner-property'
import ElectronicPropertyForm from '@/components/properties/edit-creation-forms/electronic-property-form.vue'
import MobilePropertyForm from '@/components/properties/edit-creation-forms/mobile-property-form.vue'
import { PartnerPropertyStore } from '@/types/partner-property'
import { PartnerPropertyType } from '@/types/app'

export default defineComponent({
  name: 'partner-property-form',
  components: {
    ElectronicPropertyForm,
    MobilePropertyForm,
    PhysicalStorePropertyForm,
  },
  props: {
    initialValues: {
      type: Object as PropType<PartnerPropertyStore | null>,
      default: null,
    },
    isNewProperty: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    propertyType: {
      type: Object as PropType<PartnerPropertyType>,
      required: true,
    },
  },
  emits: ['onCancel'],
  computed: {
    isPhysicalStore(): boolean {
      return (
        this.propertyType.form_type ===
        PartnerPropertyFormOptions.PHYSICAL_STORE
      )
    },
    isMobile(): boolean {
      return (
        this.propertyType.form_type ===
        PartnerPropertyFormOptions.MOBILE_PARTNER
      )
    },
    isElectronic(): boolean {
      return (
        this.propertyType.form_type ===
        PartnerPropertyFormOptions.ELECTRONIC_PARTNER
      )
    },
  },
})
