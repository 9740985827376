import { CoveragesSummaryState } from '@/types/coverages-summary.d'
import {
  CoverageSelection,
  CoverageSelectionItemPhone,
} from '@/types/plan-registration'
import { getCoverageKey } from '@/services/coverages'
import { CoverageType, PlanIntervals } from '@/constants/coverages'
import { DiscountCode } from '@/types/discount-code'
import { Ref } from 'vue'

export default class {
  private summary: CoveragesSummaryState
  private couponRegister: Map<DiscountCode, number>
  private coupons: DiscountCode[]

  constructor(summary: CoveragesSummaryState) {
    this.summary = summary
    this.couponRegister = new Map()
    this.coupons = []
  }

  private calculateAnnualizedCost(
    coverageSelection: Ref<CoverageSelection[]>,
  ): void {
    this.summary.annualizedAnnualCoverageCost = coverageSelection.value.reduce(
      (accumulator, cs) => (cs.annualCoverage?.price || 0) + accumulator,
      0,
    )
    this.summary.annualizedMonthlyCoverageCost = coverageSelection.value.reduce(
      (accumulator, cs) => (cs.monthlyCoverage?.price || 0) * 12 + accumulator,
      0,
    )
  }

  private calculatePerItemSummary(
    coverageSelection: Ref<CoverageSelection[]>,
    discounts: Ref<DiscountCode[]>,
  ): void {
    this.coupons = discounts.value.filter((d) => d.coupon == true)
    const couponDiscount = this.coupons.reduce(
      (accumulator, d) => d.amount + accumulator,
      0,
    )

    this.summary.billed.month =
      coverageSelection.value.reduce(
        (accumulator: number, cs: CoverageSelection): number => {
          if (
            cs[getCoverageKey(cs)]?.planInterval ===
            PlanIntervals.ANNUAL.toLocaleLowerCase()
          ) {
            return accumulator + (cs[getCoverageKey(cs)]?.price || 1) / 12
          } else if (
            cs[getCoverageKey(cs)]?.planInterval ===
            PlanIntervals.MONTHLY.toLocaleLowerCase()
          ) {
            return accumulator + (cs[getCoverageKey(cs)]?.price || 0)
          }
          return 0
        },
        0,
      ) *
      ((100 - couponDiscount) / 100)

    this.summary.billed.year =
      coverageSelection.value.reduce(
        (accumulator: number, cs: CoverageSelection): number => {
          if (
            cs[getCoverageKey(cs)]?.planInterval ===
            PlanIntervals.ANNUAL.toLocaleLowerCase()
          ) {
            return accumulator + (cs[getCoverageKey(cs)]?.price || 0)
          } else if (
            cs[getCoverageKey(cs)]?.planInterval ===
            PlanIntervals.MONTHLY.toLocaleLowerCase()
          ) {
            return accumulator + (cs[getCoverageKey(cs)]?.price || 0) * 12
          }
          return 0
        },
        0,
      ) *
      ((100 - couponDiscount) / 100)

    const plansCount = new Map()

    for (const index in coverageSelection.value) {
      let mapKey = null
      if (coverageSelection.value[index].type == CoverageType.phone) {
        const model = (
          coverageSelection.value[index].detail as CoverageSelectionItemPhone
        ).model
        mapKey = `${coverageSelection.value[index].type}-${model}-${
          coverageSelection.value[index][
            getCoverageKey(coverageSelection.value[index])
          ]?.id
        }`
      } else if (coverageSelection.value[index].type == CoverageType.AKKO) {
        mapKey = `${coverageSelection.value[index].type}-${
          coverageSelection.value[index][
            getCoverageKey(coverageSelection.value[index])
          ]?.id
        }`
      } else {
        mapKey = `${coverageSelection.value[index].type}-${
          coverageSelection.value[index][
            getCoverageKey(coverageSelection.value[index])
          ]?.id
        }`
      }

      if (plansCount.has(mapKey)) {
        plansCount.set(mapKey, {
          count: plansCount.get(mapKey).count + 1,
          detail: plansCount.get(mapKey).detail,
        })
      } else {
        plansCount.set(mapKey, {
          count: 1,
          detail: coverageSelection.value[index],
        })
      }
    }

    this.summary.items = []
    this.couponRegister = new Map()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_, { count, detail }] of plansCount) {
      this.summary.items.push({
        coverageName: detail[getCoverageKey(detail)].name,
        repairDeductible: CoverageType.phone
          ? detail.detail.repairDeductible
          : '',
        replacementDeductible: CoverageType.phone
          ? detail.detail.replacementDeductible
          : '',
        screenReplacementDeductible: CoverageType.phone
          ? detail.detail.screenReplacementDeductible
          : '',
        count: count,
        uiId: detail.uiId,
        type: detail.type as CoverageType,
        phoneMaker:
          detail.type == CoverageType.phone ? detail.detail.maker : '',
        phoneModel:
          detail.type == CoverageType.phone ? detail.detail.model : '',
        akkoCoverage:
          detail.type == CoverageType.AKKO
            ? detail[getCoverageKey(detail)]?.name
            : '',
        price:
          detail[getCoverageKey(detail)].price * ((100 - couponDiscount) / 100),
        fullPrice: detail[getCoverageKey(detail)].price,
        interval: detail[getCoverageKey(detail)].planInterval,
      })

      this.registerCouponDiscount(detail[getCoverageKey(detail)].price, count)
    }
    this.summary.couponDiscount = this.couponRegister
  }

  private registerCouponDiscount(price: number, count: number) {
    for (const couponKey in this.coupons) {
      if (!this.couponRegister.has(this.coupons[couponKey])) {
        this.couponRegister.set(this.coupons[couponKey], 0)
      }
      this.couponRegister.set(
        this.coupons[couponKey],
        <number>this.couponRegister.get(this.coupons[couponKey]) +
          ((price * this.coupons[couponKey].amount) / 100) * count,
      )
    }
  }

  private calculateMonthlyDiscounts(
    coverageSelection: Ref<CoverageSelection[]>,
    discounts: Ref<DiscountCode[]>,
  ): void {
    const monthlyDiscounts = discounts.value.filter((d) => d.coupon == false)

    const discountMap = new Map()

    for (const monthlyDiscountsKey in monthlyDiscounts) {
      const discount = monthlyDiscounts[monthlyDiscountsKey]

      let sum = 0
      for (const summaryItem in this.summary.items) {
        const item = this.summary.items[summaryItem]
        if (item.interval.toUpperCase() === PlanIntervals.MONTHLY) {
          sum += ((item.price * discount.amount) / 100) * item.count
        } else if (item.interval.toUpperCase() === PlanIntervals.ANNUAL) {
          sum +=
            (((item.price / 12) * discount.amount) / 100) *
            item.count *
            discount.numberMonths
        }
      }
      discountMap.set(discount, sum)
    }
    this.summary.monthlyDiscount = discountMap
  }

  private buildSummary(
    coverageSelection: Ref<CoverageSelection[]>,
    discounts: Ref<DiscountCode[]>,
  ): void {
    this.calculatePerItemSummary(coverageSelection, discounts)
    this.calculateMonthlyDiscounts(coverageSelection, discounts)
  }

  public calculate(
    coverageSelection: Ref<CoverageSelection[]>,
    discounts: Ref<DiscountCode[]>,
  ): void {
    this.calculateAnnualizedCost(coverageSelection)
    this.buildSummary(coverageSelection, discounts)
  }
}
