
import { defineComponent } from 'vue'
import { AppGetters } from '@/store/modules/app/getters'
import { ModulesIdentifiers } from '@/store'
import { Alert } from '@/types/app.d'
import AlertBubble from '@/components/common/AlertBubble.vue'

export default defineComponent({
  name: 'Alerts',
  components: {
    AlertBubble,
  },
  computed: {
    alerts(): Alert[] {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.ALERTS}`
      ]
    },
  },
})
