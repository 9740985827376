import { Store } from 'vuex'
import { ModulesIdentifiers } from '@/store'
import { CoveragesActions } from '@/store/modules/coverages/actions'
import { State } from '@/types/vuex'

export const loadCoveragesAndDevices = async (
  store: Store<State>,
): Promise<void> => {
  await Promise.all([
    store.dispatch(
      `${ModulesIdentifiers.COVERAGES}/${CoveragesActions.LOAD_COVERAGES}`,
    ),
    store.dispatch(
      `${ModulesIdentifiers.COVERAGES}/${CoveragesActions.LOAD_DEVICES}`,
    ),
  ])
}
