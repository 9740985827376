
import DistanceInput from '@/components/common/DistanceInput.vue'
import MoneyAmount from '@/components/common/MoneyAmount.vue'
import DeleteIcon from '@/components/common/icons/Delete-Icon.vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'RadiusLimitsAndAssociatedCost',
  components: {
    DistanceInput,
    MoneyAmount,
    DeleteIcon,
  },
  emits: ['onChangeEndAmount', 'onChangeMoneyAmount', 'onDelete'],
  props: {
    moneyAmountLabel: {
      type: String as PropType<string>,
      required: true,
    },
    startRadiusLabel: {
      type: String as PropType<string>,
      required: true,
    },
    startAmount: {
      type: Number as PropType<number>,
      required: true,
    },
    endRadiusLabel: {
      type: String as PropType<string>,
      required: true,
    },
    endAmount: {
      type: Number as PropType<number>,
      required: true,
    },
    disabledEnd: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showDelete: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    travelFee: {
      type: Number as PropType<number | undefined>,
      required: true,
    },
    moneyAmountError: {
      type: String as PropType<string>,
      default: '',
    },
    endRadiusError: {
      type: String as PropType<string>,
      default: '',
    },
  },
  computed: {
    moneyAmountCssClass(): string {
      if (this.moneyAmountError) {
        return 'is-invalid'
      }
      return ''
    },
  },
})
