import { PlanRegistrationState } from '@/types/plan-registration'
import { FIRST_MONTH_FREE_DISCOUNT_CODE } from '@/constants/plan-registration'
import { State } from '@/types/vuex'
import { Product, ProductInCart, ProductPrice } from '@/types/product'
import { PlanIntervals } from '@/constants/coverages'
import { ModulesIdentifiers } from '@/store'
import { ProductGetters } from '@/store/modules/products/getters'
import { DiscountCode } from '@/types/discount-code'

export enum PlanRegistrationGetters {
  USER_WITH_ACCESS_TO_PP = 'userWithAccessToPartnerProperty',
  REGISTRATION_SUMMARY = 'registrationSummary',
  WAS_FIRST_MONTH_FREE_APPLIED = 'wasFirstMonthFreeApplied',
  SALES_REPRESENTATIVE_ID = 'salesRepresentativeId',
  PREFERRED_SALES_REPRESENTATIVE_ID = 'preferredSalesRepresentativeId',
  PREFERRED_PARTNER_PROPERTY_ID = 'preferredPartnerPropertyId',
  CARD_TOKEN = 'cardToken',
  WAS_DISCOUNT_CODE_APPLIED = 'wasDiscountCodeApplied',
  GET_COUPON_DISCOUNT_MAP = 'getCouponDiscountMap',
  GET_MONTHLY_DISCOUNT_MAP = 'getMonthlyDiscountMap',
  GET_BILLED_AMOUNT_INDIVIDUAL_PRODUCT = 'getBilledAmountIndividualProduct',
  GET_TOTAL_BILLED_AFTER_DISCOUNTS_INDIVIDUAL_PRODUCT = 'getTotalBilledAfterDiscountsIndividualProduct',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getters: any = {}

getters[PlanRegistrationGetters.USER_WITH_ACCESS_TO_PP] = function (
  state: PlanRegistrationState,
) {
  return state.usersWithAccessToPartnerProperty
}

getters[PlanRegistrationGetters.REGISTRATION_SUMMARY] = function (
  state: PlanRegistrationState,
) {
  return state.registrationSummary
}

getters[PlanRegistrationGetters.WAS_FIRST_MONTH_FREE_APPLIED] = function (
  state: PlanRegistrationState,
) {
  return !!state.discounts.find(
    (dc) => dc.code === FIRST_MONTH_FREE_DISCOUNT_CODE,
  )
}

getters[PlanRegistrationGetters.SALES_REPRESENTATIVE_ID] = function (
  state: PlanRegistrationState,
) {
  return state.salesRepId
}

getters[PlanRegistrationGetters.PREFERRED_SALES_REPRESENTATIVE_ID] = function (
  state: PlanRegistrationState,
) {
  return state.preferredSalesRepId
}

getters[PlanRegistrationGetters.PREFERRED_PARTNER_PROPERTY_ID] = function (
  state: PlanRegistrationState,
) {
  return state.preferredPartnerPropertyId
}

getters[PlanRegistrationGetters.CARD_TOKEN] = function (
  state: PlanRegistrationState,
) {
  return state.paymentInformation.creditCardToken
}

getters[PlanRegistrationGetters.WAS_DISCOUNT_CODE_APPLIED] = function (
  state: PlanRegistrationState,
) {
  return (code: string): boolean => {
    return !!state.discounts.find((d) => d.code === code)
  }
}

getters[PlanRegistrationGetters.GET_COUPON_DISCOUNT_MAP] = function (
  state: PlanRegistrationState,
  _: any,
  rootState: State,
) {
  const couponRegister = new Map()

  let flatCart = Object.keys(rootState.products.productCart).reduce(
    (prevProductGroupInCart: Product[], productGroupInCartKey, index) => {
      return prevProductGroupInCart.concat(
        rootState.products.productCart[productGroupInCartKey].filter(
          (p) => p !== null,
        ) as ProductInCart[],
      )
    },
    [],
  )
  flatCart = flatCart.filter((p) => p !== null)

  const flatPrices = (flatCart as ProductInCart[]).map((p: ProductInCart) =>
    p.prices.find(
      (price) =>
        price.interval === rootState.products.productCartInterval.toLowerCase(),
    ),
  )

  const coupons = state.discounts.filter((dis) => dis.coupon)

  for (const productPrice of flatPrices) {
    if (!productPrice) continue
    for (const couponKey in coupons) {
      if (!couponRegister.has(coupons[couponKey])) {
        couponRegister.set(coupons[couponKey], 0)
      }
      couponRegister.set(
        coupons[couponKey],
        <number>couponRegister.get(coupons[couponKey]) +
          ((productPrice.currentPrice / 100) * coupons[couponKey].amount) / 100,
      )
    }
  }

  return couponRegister
}

getters[PlanRegistrationGetters.GET_MONTHLY_DISCOUNT_MAP] = function (
  state: PlanRegistrationState,
  getters: any,
  rootState: State,
  rootGetters: any,
) {
  const targetPrices =
    rootGetters[
      `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_TARGET_PRICES}`
    ]
  const couponDiscounts = getters[
    PlanRegistrationGetters.GET_COUPON_DISCOUNT_MAP
  ] as Map<DiscountCode, number>

  const preDiscountTotal = targetPrices.reduce(
    (acum: number, price: ProductPrice, index: number): number => {
      let discountTotal = 0
      for (const couponPair of couponDiscounts) {
        const coupon = couponPair[0] as DiscountCode
        discountTotal += price.currentPrice * (coupon.amount / 100)
      }
      return price.currentPrice - discountTotal + acum
    },
    0,
  )

  const monthlyDiscountMap = new Map()

  const monthlyDiscounts = state.discounts.filter((dis) => !dis.coupon)

  for (const discount of monthlyDiscounts) {
    if (!monthlyDiscountMap.has(discount)) {
      monthlyDiscountMap.set(discount, 0)
    }
    monthlyDiscountMap.set(
      discount,
      <number>monthlyDiscountMap.get(discount) +
        (((preDiscountTotal *
          rootState.products.intervalMonthlyTransformationRatios[
            rootState.products.productCartInterval.toLowerCase()
          ]) /
          100) *
          discount.amount) /
          100,
    )
  }

  return monthlyDiscountMap
}

getters[PlanRegistrationGetters.GET_BILLED_AMOUNT_INDIVIDUAL_PRODUCT] =
  function (
    state: PlanRegistrationState,
    getters: any,
    rootState: State,
    rootGetters: any,
  ) {
    const coupons = state.discounts.filter((d) => d.coupon)
    const couponDiscount = coupons.reduce(
      (accumulator, d) => d.amount + accumulator,
      0,
    )

    const productsInCart =
      rootGetters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_CART_FLAT_LIST}`
      ]

    const targetPrices = productsInCart.map((p: ProductInCart) => {
      return p.prices.find(
        (price) =>
          price.interval ===
          rootState.products.productCartInterval.toLowerCase(),
      )
    })

    if (targetPrices.length <= 0) return { month: 0, year: 0 }

    const totalBilled = targetPrices.reduce(
      (
        acum: { month: number; year: number },
        price: ProductPrice,
        index: number,
      ): { month: number; year: number } => {
        acum.year +=
          (price.currentPrice *
            rootState.products.intervalMonthlyTransformationRatios[
              price.interval
            ] *
            12) /
          100
        acum.month +=
          (price.currentPrice *
            rootState.products.intervalMonthlyTransformationRatios[
              price.interval
            ]) /
          100
        return acum
      },
      { month: 0, year: 0 },
    )

    totalBilled.year *= (100 - couponDiscount) / 100
    totalBilled.month *= (100 - couponDiscount) / 100

    return totalBilled
  }

getters[
  PlanRegistrationGetters.GET_TOTAL_BILLED_AFTER_DISCOUNTS_INDIVIDUAL_PRODUCT
] = function (
  state: PlanRegistrationState,
  getters: any,
  rootState: State,
  rootGetters: any,
) {
  const totalBeforeDiscounts =
    getters[PlanRegistrationGetters.GET_BILLED_AMOUNT_INDIVIDUAL_PRODUCT]

  const targetTotal =
    rootGetters[
      `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_CART_INTERVAL}`
    ] === PlanIntervals.ANNUAL
      ? totalBeforeDiscounts.year
      : totalBeforeDiscounts.month

  const discounts = getters[PlanRegistrationGetters.GET_MONTHLY_DISCOUNT_MAP]

  let totalDiscounted = 0

  for (const discountPair of discounts) {
    totalDiscounted += discountPair[1]
  }

  return targetTotal - totalDiscounted
}

export default getters
