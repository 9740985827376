
import { defineComponent } from 'vue'
import UserProfile from '@/components/account/UserProfile.vue'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import PayoutInfo from '@/components/account/PayoutInfo.vue'
import { cleanAndReStartSession } from '@/services/intercom'
import { RoutePath } from '@/constants/router'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { reset } from '@/services/posthog'

export default defineComponent({
  name: 'Account',
  components: {
    PayoutInfo,
    UserProfile,
  },
  methods: {
    logout(): void {
      reset()
      this.$store.dispatch(`${ModulesIdentifiers.USER}/${UserActions.LOGOUT}`)
      cleanAndReStartSession()
      this.$router.push(RoutePath.SIGNUP)
      window.location.href = '/'
    },
  },
  computed: {
    accesPayout(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_ADMIN_PAYOUT_INFORMATION,
        PermissionValues.EDIT,
      )
    },
  },
})
