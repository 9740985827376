import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "pe-2" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { colspan: "8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyText = _resolveComponent("CopyText")!
  const _component_EyesOpenIcon = _resolveComponent("EyesOpenIcon")!
  const _component_EyesClosedIcon = _resolveComponent("EyesClosedIcon")!
  const _component_EndUserClaimDeviceTable = _resolveComponent("EndUserClaimDeviceTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, [
        _createElementVNode("span", null, [
          _createVNode(_component_CopyText, {
            text: _ctx.claim.claim_id.toString()
          }, null, 8, ["text"])
        ])
      ]),
      _createElementVNode("td", null, _toDisplayString(_ctx.claim.claim_status), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.claim.claim_loss_date), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.claim.claim_loss_type), 1),
      _createElementVNode("td", _hoisted_1, [
        (_ctx.claim.claim_devices_quantity == 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.claim.claim_devices_quantity), 1))
          : _createCommentVNode("", true),
        (_ctx.claim.claim_devices_quantity > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("a", {
                class: "nav-link",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onViewDevices()))
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.claim.claim_devices_quantity), 1),
                (!_ctx.showDevices)
                  ? (_openBlock(), _createBlock(_component_EyesOpenIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                (_ctx.showDevices)
                  ? (_openBlock(), _createBlock(_component_EyesClosedIcon, { key: 1 }))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showDevices)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
          _createElementVNode("td", _hoisted_6, [
            _createVNode(_component_EndUserClaimDeviceTable, {
              css: "mb-5",
              devices: _ctx.claim.claim_devices
            }, null, 8, ["devices"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}