import { ModulesIdentifiers } from '@/store'
import { PlanRegistrationMutations } from '@/store/modules/plan-registration/mutations'
import {
  AkkoCoverageType,
  InjuredGadgetsCoverageType,
  PlanIntervals,
  CoverageType,
  HomePlusCoverageType,
  HomeRegularCoverageType,
} from '@/constants/coverages'
import { coveragesGetters } from '@/store/modules/coverages/getters'
import { Coverage, DeviceModel, DeviceWithCoverages } from '@/types/coverage'
import {
  CoverageSelectionItemAkko,
  CoverageSelectionItemInjuredGadgets,
  CoverageSelectionItemPhone,
  CoverageSelectionMap,
  CoverageSelection,
} from '@/types/plan-registration'
import { Store } from 'vuex'
import { State } from '@/types/vuex'
import { PartnerPrograms } from '@/constants/partner-programs'
import { PhoneMakers } from '@/constants/phone'
import isEmpty from 'lodash/isEmpty'

export default class {
  public selectionMap: CoverageSelectionMap
  private readonly adultCoverages: { MONTHLY: Coverage; ANNUAL: Coverage }
  private readonly studentCoverages: { MONTHLY: Coverage; ANNUAL: Coverage }
  private readonly injuredGadgetsPlanCoverages: {
    MONTHLY: Coverage
    ANNUAL: Coverage
  }
  private readonly injuredGadgetsPlanProCoverages: {
    MONTHLY: Coverage
    ANNUAL: Coverage
  }
  private readonly injuredGadgetsPlanPhoneCoverages: {
    MONTHLY: Coverage
    ANNUAL: Coverage
  }
  private readonly injuredGadgetsPlanFoldablePhoneCoverages: {
    MONTHLY: Coverage
    ANNUAL: Coverage
  }
  private readonly phoneMakerList: Set<string>
  private readonly store: Store<State>
  private readonly homePlusCoverages: { MONTHLY: Coverage; ANNUAL: Coverage }
  private readonly homeRegularCoverages: { MONTHLY: Coverage; ANNUAL: Coverage }

  coverageType: CoverageType | null = null

  constructor(selectionMap: CoverageSelectionMap, store: Store<State>) {
    this.selectionMap = selectionMap

    this.store = store

    this.adultCoverages = this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.AKKO_COVERAGES}`
    ](AkkoCoverageType.ADULT)

    this.studentCoverages = this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.AKKO_COVERAGES}`
    ](AkkoCoverageType.STUDENT)

    this.phoneMakerList = new Set(
      this.store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.DEVICES_WITH_COVERAGES}`
      ].map((dvc: DeviceWithCoverages) => dvc.maker),
    )

    this.injuredGadgetsPlanCoverages = this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.INJURED_GADGETS_COVERAGES}`
    ](InjuredGadgetsCoverageType.PLAN_BUNDLED)

    this.injuredGadgetsPlanProCoverages = this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.INJURED_GADGETS_COVERAGES}`
    ](InjuredGadgetsCoverageType.PLAN_PRO)

    this.injuredGadgetsPlanPhoneCoverages = this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.INJURED_GADGETS_COVERAGES}`
    ](InjuredGadgetsCoverageType.PLAN_PHONE)

    this.injuredGadgetsPlanFoldablePhoneCoverages = this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.INJURED_GADGETS_COVERAGES}`
    ](InjuredGadgetsCoverageType.PLAN_FOLDABLE_PHONE)

    this.homePlusCoverages = this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.HOME_PLUS_COVERAGES}`
    ](HomePlusCoverageType.PLUS)

    this.homeRegularCoverages = this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.HOME_REGULAR_COVERAGES}`
    ](HomeRegularCoverageType.REGULAR)
  }

  get phoneMakers(): Array<string> {
    // Sort maker list according to marketing priority
    return Array.from(this.phoneMakerList).sort((makerA, makerB): number => {
      if (makerA == PhoneMakers.APPLE) {
        return -1
      }
      if (makerB == PhoneMakers.APPLE) {
        return 1
      }
      if (makerA == PhoneMakers.SAMSUNG) {
        return -1
      }
      if (makerB == PhoneMakers.SAMSUNG) {
        return 1
      }
      if (makerA == PhoneMakers.OTHER) {
        return 1
      }
      if (makerB == PhoneMakers.OTHER) {
        return -1
      }
      return 0
    })
  }

  private submitSelection() {
    this.store.commit(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PLAN_SELECTION}`,
      this.selectionMap,
    )
  }

  public phoneModels(maker: string): string[] {
    return this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.DEVICES_WITH_COVERAGES}`
    ]
      .filter((dv: DeviceModel) => dv.maker == maker)
      .sort((a: DeviceModel, b: DeviceModel) => {
        if (a.displayOrder && b.displayOrder) {
          return a.displayOrder - b.displayOrder
        } else if (a.displayOrder && !b.displayOrder) {
          return 1
        } else if (!a.displayOrder && b.displayOrder) {
          return -1
        } else {
          return a.maker.localeCompare(b.maker)
        }
      })
      .map((dv: DeviceModel) => dv.model)
  }

  public addAkkoPlan(planInterval: PlanIntervals): void {
    this.coverageType = CoverageType.AKKO
    this.selectionMap.selections[this.selectionMap.indexer++] = {
      type: CoverageType.AKKO,
      annualCoverage: null,
      monthlyCoverage: null,
      selectedCoverageInterval: planInterval,
      detail: {
        type: null,
      },
    }
  }

  public addInjuredGadgetsPlan(planInterval: PlanIntervals): void {
    this.selectionMap.selections[this.selectionMap.indexer++] = {
      type: PartnerPrograms.INJURED_GADGETS,
      annualCoverage: null,
      monthlyCoverage: null,
      selectedCoverageInterval: planInterval,
      detail: {
        type: null,
      },
    }
  }

  public addPhonePlan(planInterval: PlanIntervals): void {
    this.coverageType = CoverageType.phone
    this.selectionMap.selections[this.selectionMap.indexer++] = {
      type: CoverageType.phone,
      annualCoverage: null,
      monthlyCoverage: null,
      selectedCoverageInterval: planInterval,
      detail: {
        maker: null,
        model: null,
        repairDeductible: null,
        replacementDeductible: null,
        screenReplacementDeductible: null,
      },
    }
  }

  public addHomePlusPlan(planInterval: PlanIntervals): void {
    this.coverageType = CoverageType.HOME_ELECTRONIC_PLUS_PLAN
    this.selectionMap.selections[this.selectionMap.indexer++] = {
      type: CoverageType.HOME_ELECTRONIC_PLUS_PLAN,
      annualCoverage: null,
      monthlyCoverage: null,
      selectedCoverageInterval: planInterval,
      detail: {
        type: HomePlusCoverageType.PLUS,
      },
    }
  }

  public addHomeRegularPlan(planInterval: PlanIntervals): void {
    this.coverageType = CoverageType.HOME_ELECTRONIC_REGULAR_PLAN
    this.selectionMap.selections[this.selectionMap.indexer++] = {
      type: CoverageType.HOME_ELECTRONIC_REGULAR_PLAN,
      annualCoverage: null,
      monthlyCoverage: null,
      selectedCoverageInterval: planInterval,
      detail: {
        type: HomeRegularCoverageType.REGULAR,
      },
    }
  }

  public registerDeviceSelection(
    event: Event,
    selection: {
      annualCoverage: Coverage
      monthlyCoverage: Coverage
      type: string
      detail: CoverageSelectionItemPhone
    },
  ): void {
    const deviceWithCoverage = this.store.getters[
      `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.DEVICES_WITH_COVERAGES}`
    ].find((dvc: DeviceWithCoverages) => {
      return (
        dvc.maker === selection.detail.maker &&
        dvc.model === (event.target as HTMLInputElement).value
      )
    })

    selection.detail.model = deviceWithCoverage.model
    selection.detail.replacementDeductible =
      deviceWithCoverage.replacementDeductible
    selection.detail.repairDeductible = deviceWithCoverage.repairDeductible
    selection.detail.screenReplacementDeductible =
      deviceWithCoverage.screenReplacementDeductible
    selection.annualCoverage = deviceWithCoverage.annualCoverage
    selection.monthlyCoverage = deviceWithCoverage.monthlyCoverage

    this.submitSelection()
  }

  public registerAkkoPlanTypeSelection(
    event: Event,
    selection: {
      annualCoverage: Coverage
      monthlyCoverage: Coverage
      type: string
      detail: CoverageSelectionItemAkko
    },
  ): void {
    if (selection.detail.type == AkkoCoverageType.ADULT) {
      selection.annualCoverage = this.adultCoverages[PlanIntervals.ANNUAL]
      selection.monthlyCoverage = this.adultCoverages[PlanIntervals.MONTHLY]
    } else if (selection.detail.type == AkkoCoverageType.STUDENT) {
      selection.annualCoverage = this.studentCoverages[PlanIntervals.ANNUAL]
      selection.monthlyCoverage = this.studentCoverages[PlanIntervals.MONTHLY]
    }
    this.submitSelection()
  }

  public registerInjureGadgetPlanTypeSelection(
    event: Event,
    selection: {
      annualCoverage: Coverage
      monthlyCoverage: Coverage
      type: string
      detail: CoverageSelectionItemInjuredGadgets
    },
  ): void {
    if (selection.detail.type == InjuredGadgetsCoverageType.PLAN_BUNDLED) {
      selection.annualCoverage =
        this.injuredGadgetsPlanCoverages[PlanIntervals.ANNUAL]
      selection.monthlyCoverage =
        this.injuredGadgetsPlanCoverages[PlanIntervals.MONTHLY]
    } else if (selection.detail.type == InjuredGadgetsCoverageType.PLAN_PRO) {
      selection.annualCoverage =
        this.injuredGadgetsPlanProCoverages[PlanIntervals.ANNUAL]
      selection.monthlyCoverage =
        this.injuredGadgetsPlanProCoverages[PlanIntervals.MONTHLY]
    } else if (selection.detail.type == InjuredGadgetsCoverageType.PLAN_PHONE) {
      selection.annualCoverage =
        this.injuredGadgetsPlanPhoneCoverages[PlanIntervals.ANNUAL]
      selection.monthlyCoverage =
        this.injuredGadgetsPlanPhoneCoverages[PlanIntervals.MONTHLY]
    } else if (
      selection.detail.type == InjuredGadgetsCoverageType.PLAN_FOLDABLE_PHONE
    ) {
      selection.annualCoverage =
        this.injuredGadgetsPlanFoldablePhoneCoverages[PlanIntervals.ANNUAL]
      selection.monthlyCoverage =
        this.injuredGadgetsPlanFoldablePhoneCoverages[PlanIntervals.MONTHLY]
    }
    this.submitSelection()
  }

  public removeSelection(key: number): void {
    delete this.selectionMap.selections[key]
    if (isEmpty(this.selectionMap.selections)) {
      this.coverageType = null
    }
    this.submitSelection()
  }

  public recalculateCoverages(planInterval: PlanIntervals): void {
    for (const key in this.selectionMap.selections) {
      this.selectionMap.selections[key].selectedCoverageInterval = planInterval
    }
    this.submitSelection()
  }

  public registerHomePlusPlanTypeSelection(selection: CoverageSelection): void {
    selection.annualCoverage = this.homePlusCoverages[PlanIntervals.ANNUAL]
    selection.monthlyCoverage = this.homePlusCoverages[PlanIntervals.MONTHLY]
    this.submitSelection()
  }

  public registerHomeRegularPlanTypeSelection(
    selection: CoverageSelection,
  ): void {
    selection.annualCoverage = this.homeRegularCoverages[PlanIntervals.ANNUAL]
    selection.monthlyCoverage = this.homeRegularCoverages[PlanIntervals.MONTHLY]
    this.submitSelection()
  }
}
