
import { defineComponent } from 'vue'
import {
  AkkoCoverageType,
  CoverageType,
  InjuredGadgetsCoverageType,
} from '@/constants/coverages'

export default defineComponent({
  name: 'ExtendedWarrantyForFigPlanSelector',
  setup() {
    return {
      CoverageType,
      AkkoCoverageType,
      InjuredGadgetsCoverageType,
    }
  },
  props: {
    selectExtendedWarrantyForFig: {
      type: Function,
      default: () => {
        /* */
      },
    },
  },
})
