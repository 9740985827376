import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-590d0c88")
const _hoisted_1 = { class: "col-12 mt-3 text-end" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadiusLimitsAndAssociatedCost = _resolveComponent("RadiusLimitsAndAssociatedCost")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfRadius, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass({ 'bottom-separator': _ctx.isNotLastItem(index) })
      }, [
        _createVNode(_component_RadiusLimitsAndAssociatedCost, {
          "money-amount-label": "Travel to customer fee",
          "start-radius-label": "Radius start",
          startAmount: item.radiusStart,
          "end-radius-label": "Radius end",
          endAmount: item.radiusEnd,
          travelFee: item.travelFee,
          disabledEnd: _ctx.isNotLastItem(index),
          showDelete: !_ctx.isNotLastItem(index) && _ctx.listOfRadius.length > 1,
          endRadiusError: _ctx.getEndRadiusError(index),
          moneyAmountError: _ctx.getMoneyAmountError(index),
          onOnChangeEndAmount: (payload) => _ctx.handleOnchangeEndAmount(payload, item),
          onOnChangeMoneyAmount: 
        (payload) => _ctx.handleOnchangeMoneyAmount(payload, item)
      ,
          onOnDelete: () => _ctx.handleDeleteItem(index)
        }, null, 8, ["startAmount", "endAmount", "travelFee", "disabledEnd", "showDelete", "endRadiusError", "moneyAmountError", "onOnChangeEndAmount", "onOnChangeMoneyAmount", "onOnDelete"])
      ], 2))
    }), 128)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        class: _normalizeClass(["pointer", { linkDisabled: !_ctx.allowedToAddLevel }]),
        disabled: true,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addRadiusLevel()))
      }, "Add radius level", 2)
    ])
  ], 64))
}