
import { defineComponent, PropType } from 'vue'
import { ClaimStatus } from '@/constants/claim'

export default defineComponent({
  name: 'ClaimsInstructionsHeader',
  setup() {
    return {
      ClaimStatus,
    }
  },
  props: {
    claimStatus: {
      type: String as PropType<ClaimStatus>,
      required: true,
    },
  },
})
