import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export enum ReportTypeNames {
  last7days = 'last7days',
  last30days = 'last30days',
  lastmonth = 'lastmonth',
  customMonth = 'customMonth',
  today = 'today',
  total = 'total',
}

export enum ReportsRange {
  LAST_7_DAYS = 7,
  LAST_30_DAYS = 30,
  LAST_MONTH = 'last_month',
  CUSTOM_MONTH = 'custom_month',
  TOTAL = 'all',
  TODAY = 0,
}

export default {
  state: {
    filter: {
      partnerProperties: [],
      salesRepresentatives: [],
    },
    reports: {},
    salesOverview: null,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
