import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyText = _resolveComponent("CopyText")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createElementVNode("span", null, [
        _createVNode(_component_CopyText, {
          text: _ctx.device.id.toString()
        }, null, 8, ["text"])
      ])
    ]),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.kind), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.brand), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.model), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.serial_number), 1),
    _createElementVNode("td", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.device.loss_types, (lossType, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: lossType.id
        }, [
          (index > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "/"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(lossType.name), 1)
        ]))
      }), 128))
    ])
  ]))
}