import LogRocket from 'logrocket'
import { UserState } from '@/types/user'
import { Partner } from '@/types/partner'

export const initTacking = function (): void {
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.VUE_APP_LOGROCKET_KEY
  ) {
    LogRocket.init(process.env.VUE_APP_LOGROCKET_KEY)
  }
}

export const identifyUser = function (user: UserState, partner: Partner): void {
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.VUE_APP_LOGROCKET_KEY
  ) {
    LogRocket.identify(String(user.id), {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      phone: user.phone,
      idNumber: user.idNumber,
      positionTitle: user.positionTitle,
      partnerName: partner.name,
      partnerId: partner.partnerId,
    })
  }
}
