import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  state: {
    plans: [],
    discounts: [],
    usersWithAccessToPartnerProperty: [],
    paymentInformation: {
      cardHolderFirstName: '',
      cardHolderLastName: '',
      primaryEmailAddress: '',
      creditCardToken: null,
    },
    address: '',
    postalCode: '',
    taxRate: 0,
    partnerPropertyId: NaN,
    preferredPartnerPropertyId: NaN,
    salesRepId: NaN,
    preferredSalesRepId: NaN,
    registrationSummary: null,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
  modules: {},
}
