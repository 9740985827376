import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { MOBILE_WINDOW_WIDTH_BREAKPOINT } from '@/constants/window-property'

export default {
  state: {
    openSidebar: false,
    loadingQue: [],
    alerts: [],
    partnerProgram: '',
    partnerProgramId: NaN,
    permissions: {},
    aboutAkko: [],
    isMobile: window.innerWidth <= MOBILE_WINDOW_WIDTH_BREAKPOINT,
    country: [],
    partnerType: [],
    partnerPropertyType: [],
  },
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
  getters: {
    ...getters,
  },
}
