
import { ModulesIdentifiers } from '@/store'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import { defineComponent } from 'vue'
import { PlanRegistrationMutations } from '@/store/modules/plan-registration/mutations'
import { formatPhone } from '@/utils'

export default defineComponent({
  name: 'RegistrationSummary',
  methods: {
    formatPhone,
    doneReadingSummary() {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_REGISTER_SUMMARY}`,
      )
    },
  },
  computed: {
    registrationSummary(): {
      phoneNumbers: string
      transactionId: string
    } {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.REGISTRATION_SUMMARY}`
      ]
    },
  },
})
