
import { defineComponent } from 'vue'
import EndUserDeviceRow from '@/components/end-users/EndUserDeviceRow.vue'

export default defineComponent({
  name: 'EndUserDeviceTable',
  components: {
    EndUserDeviceRow,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    devices: {
      type: Array,
      default() {
        return []
      },
    },
  },
})
