
import { defineComponent } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { formatPhone, isInvalid } from '@/utils'
import { endUserSearchFormValidationSchema } from '@/services/authentication/validations'
import { ModulesIdentifiers } from '@/store'
import { EndUserSearch, EndUserState } from '@/types/end-user'
import { EndUsersActions } from '@/store/modules/end-users/actions'
import { EndUsersGetters } from '@/store/modules/end-users/getters'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'EndUserSearch',
  components: {
    InputMask,
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    hasPermission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneMaskedValue: '',
      serial_number: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      hasFiltered: false,
      validationSchema: endUserSearchFormValidationSchema(),
    }
  },
  methods: {
    isInvalid,
    formatPhone,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: EndUserSearch): Promise<any> {
      try {
        let action = `${ModulesIdentifiers.END_USER}/${EndUsersActions.FILTER}`
        await this.$store.dispatch(action, values)
        this.hasFiltered = Boolean(values.phone || values.serial_number)
        this.error = false
        this.errorMessage = ''
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onClear(): Promise<any> {
      try {
        let action = `${ModulesIdentifiers.END_USER}/${EndUsersActions.CLEAN_FITLER}`
        await this.$store.dispatch(action)
        this.hasFiltered = false
        this.serial_number = ''
        this.phoneMaskedValue = ''
        this.error = false
        this.errorMessage = ''
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
  },
  computed: {
    endUserState(): EndUserState {
      return this.$store.getters[
        `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USER_STATE}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
})
