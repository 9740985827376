
import { defineComponent, PropType, ref } from 'vue'
import ZipCodeAndAssociatedCost from '@/components/common/ZipCodeAndAssociatedCost.vue'

export default defineComponent({
  name: 'ZipCodeAndAssociatedCostList',
  emits: ['onListChanges'],
  components: {
    ZipCodeAndAssociatedCost,
  },
  setup(props) {
    let listOfZipCodes

    if (props.initialValues) {
      const initialArray = []
      for (let index in props.initialValues) {
        initialArray.push({
          zipCode: props.initialValues[index].zipCode,
          travelFee: props.initialValues[index].travelFee,
        })
      }
      listOfZipCodes = ref(initialArray)
    } else {
      listOfZipCodes = ref([] as Array<{ zipCode: string; travelFee: number }>)
    }

    return {
      list: listOfZipCodes,
    }
  },
  mounted() {
    this.$emit('onListChanges', this.list)
  },
  props: {
    formErrors: {
      type: Object as PropType<any>,
      required: true,
    },
    initialValues: {
      type: Object as PropType<any[] | undefined>,
      default: undefined,
    },
  },
  methods: {
    addItem() {
      this.list.push({
        zipCode: '',
        travelFee: NaN,
      })
      this.$emit('onListChanges', this.list)
    },
    isNotLastItem(index: number) {
      return index !== this.list.length - 1
    },
    handleItemChange(payload: any, item: any) {
      item.zipCode = payload.zipCode
      item.travelFee = payload.travelFee
      this.$emit('onListChanges', this.list)
    },
    handleOnDeleteElement(index: number) {
      this.list.splice(index, 1)
      this.$emit('onListChanges', this.list)
    },
    getZipCodeError(index: number) {
      const error = (this.formErrors as any)[
        `zipCodesAndFeeds[${index}].zipCode`
      ]
      return error ? error : ''
    },
    getMoneyAmountError(index: number) {
      const error = (this.formErrors as any)[
        `zipCodesAndFeeds[${index}].travelFee`
      ]
      return error ? error : ''
    },
  },
})
