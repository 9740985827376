
import { defineComponent } from 'vue'
import Header from '../common/Header.vue'
import AuthTab from './Tab.vue'

export default defineComponent({
  props: {
    css: {
      type: String,
    },
  },
  name: 'Layout',
  components: {
    AkkoHeader: Header,
    AuthTab,
  },
  date() {
    return {
      customcss: 'cisto-csss',
    }
  },
})
