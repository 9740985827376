import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-51d7133e")
const _hoisted_1 = { class: "col-12 mt-3 text-end" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZipCodeAndAssociatedCost = _resolveComponent("ZipCodeAndAssociatedCost")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item,
        class: _normalizeClass(["mt-3", { 'bottom-separator': _ctx.isNotLastItem(index) }])
      }, [
        _createVNode(_component_ZipCodeAndAssociatedCost, {
          "location-input-place-holder": "Search zipcode",
          "location-input-label": "ZipCode",
          "money-amount-label": "Travel to customer fee",
          cssClassesLocationInput: 'form-control',
          validationListIdentifier: `zipCodesAndFeeds[${index}]`,
          formErrors: _ctx.formErrors,
          preventDeletion: index === 0,
          zipCodeError: _ctx.getZipCodeError(index),
          index: index,
          moneyAmountError: _ctx.getMoneyAmountError(index),
          initialValues: item,
          onOnChange: 
        (payload) => {
          _ctx.handleItemChange(payload, item, index)
        }
      ,
          onOnDelete: () => _ctx.handleOnDeleteElement(index)
        }, null, 8, ["validationListIdentifier", "formErrors", "preventDeletion", "zipCodeError", "index", "moneyAmountError", "initialValues", "onOnChange", "onOnDelete"])
      ], 2))
    }), 128)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        class: "pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addItem()))
      }, "Add zipcode")
    ])
  ], 64))
}