import {
  AboutAkko,
  Alert,
  AppState,
  Country,
  PartnerPropertyType,
  PartnerType,
} from '@/types/app.d'
import { v4 as uuidv4 } from 'uuid'
import { AlertType } from '@/constants/alerts'

export enum AppMutations {
  TOGGLE_SIDEBAR = 'toggleSidebar',
  SUBMIT_LOADING_OPERATION = 'submitLoadingOperation',
  REMOVE_LOADING_OPERATION = 'removeLoadingOperation',
  SUBMIT_ALERT = 'submitAlert',
  REMOVE_ALERT = 'removeAlert',
  SET_PARTNER_PROGRAM = 'setPartnerProgram',
  SET_PERMISSIONS = 'setPermissions',
  SET_ABOUT_AKKO = 'setAboutAkko',
  SET_IS_MOBILE = 'setIsMobile',
  SET_COUNTRY = 'setCountry',
  SET_PARTNER_TYPE = 'setPartnerType',
  SET_PARTNER_PROPERTY_TYPE = 'setPartnerPropertyType',
  SET_PARTNER_PROPERTY_TYPE_SELECTED = 'setPartnerPropertyTypeSelected',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mutations: any = {}

mutations[AppMutations.TOGGLE_SIDEBAR] = (
  state: AppState,
  open: boolean,
): void => {
  state.openSidebar = open
}

mutations[AppMutations.SUBMIT_LOADING_OPERATION] = (
  state: AppState,
  payload: {
    message?: string
    id: string
  },
): void => {
  state.loadingQue.push(payload)
}

mutations[AppMutations.REMOVE_LOADING_OPERATION] = (
  state: AppState,
  operation: { id: string },
): void => {
  state.loadingQue = state.loadingQue.filter((op) => op.id != operation.id)
}

mutations[AppMutations.SUBMIT_ALERT] = (
  state: AppState,
  { message, type }: { message: string; type: AlertType },
): void => {
  state.alerts.push({ message, type, id: uuidv4() })
}

mutations[AppMutations.REMOVE_ALERT] = (state: AppState, id: string): void => {
  state.alerts = state.alerts.filter((op: Alert) => op.id != id)
}

mutations[AppMutations.SET_PARTNER_PROGRAM] = (
  state: AppState,
  payload: { partnerProgram: string; partnerProgramId: number },
): void => {
  state.partnerProgram = payload.partnerProgram
  state.partnerProgramId = payload.partnerProgramId
}

mutations[AppMutations.SET_PERMISSIONS] = (
  state: AppState,
  permissions: { [key: string]: string[] },
): void => {
  state.permissions = permissions
}

mutations[AppMutations.SET_ABOUT_AKKO] = (
  state: AppState,
  data: AboutAkko[],
): void => {
  state.aboutAkko = [...data]
}

mutations[AppMutations.SET_IS_MOBILE] = (
  state: AppState,
  data: boolean,
): void => {
  state.isMobile = data
}

mutations[AppMutations.SET_COUNTRY] = (
  state: AppState,
  data: Country[],
): void => {
  state.country = [...data]
}

mutations[AppMutations.SET_PARTNER_TYPE] = (
  state: AppState,
  data: PartnerType[],
): void => {
  state.partnerType = [...data]
}

mutations[AppMutations.SET_PARTNER_PROPERTY_TYPE] = (
  state: AppState,
  data: PartnerPropertyType[],
): void => {
  state.partnerPropertyType = [...data]
}

mutations[AppMutations.SET_PARTNER_PROPERTY_TYPE_SELECTED] = (
  state: AppState,
  partnerPropertyTypeId: string,
): void => {
  const partnerPropertyType = state.partnerPropertyType.find(
    (el: PartnerPropertyType) => el.id === partnerPropertyTypeId,
  )
  if (partnerPropertyType) partnerPropertyType.is_selected = true
  state.partnerPropertyType = [...state.partnerPropertyType]
}

export default mutations
