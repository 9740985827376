
import { defineComponent, PropType, ref } from 'vue'
import RadiusLimitsAndAssociatedCost from '@/components/common/RadiusLimitsAndAssociatedCost.vue'

export default defineComponent({
  name: 'RadiusLimitsAndAssociatedCostList',
  components: {
    RadiusLimitsAndAssociatedCost,
  },
  emits: ['onListChanges'],
  setup(props) {
    let listOfRadius
    if (props.initialValues) {
      const initialArray = []
      for (let index in props.initialValues) {
        initialArray.push({
          radiusStart: props.initialValues[index].radiusStart,
          radiusEnd: props.initialValues[index].radiusEnd,
          travelFee: props.initialValues[index].travelFee,
        })
      }
      listOfRadius = ref(initialArray)
    } else {
      listOfRadius = ref([
        {
          radiusStart: 0,
          radiusEnd: undefined as number | undefined,
          travelFee: undefined as number | undefined,
        },
      ])
    }

    return {
      listOfRadius,
    }
  },
  props: {
    formErrors: {
      type: Object as PropType<any>,
      required: true,
    },
    initialValues: {
      type: Object as PropType<any[] | undefined>,
      default: undefined,
    },
  },
  mounted() {
    this.$emit('onListChanges', this.listOfRadius)
  },
  methods: {
    addRadiusLevel() {
      if (this.allowedToAddLevel) {
        this.listOfRadius.push({
          radiusStart: this.listOfRadius[this.listOfRadius.length - 1]
            .radiusEnd as number,
          radiusEnd: undefined,
          travelFee: undefined,
        })
      }
      this.$emit('onListChanges', this.listOfRadius)
    },
    handleOnchangeEndAmount(payload: number, item: { radiusEnd: number }) {
      item.radiusEnd = payload
      this.$emit('onListChanges', this.listOfRadius)
    },
    handleOnchangeMoneyAmount(payload: number, item: { travelFee: number }) {
      item.travelFee = payload
      this.$emit('onListChanges', this.listOfRadius)
    },
    isNotLastItem(index: number) {
      return index !== this.listOfRadius.length - 1
    },
    handleDeleteItem(index: number) {
      this.listOfRadius.splice(index, 1)
      this.$emit('onListChanges', this.listOfRadius)
    },
    getMoneyAmountError(index: number) {
      const error = (this.formErrors as any)[
        `radiusAndFeeds[${index}].travelFee`
      ]
      return error ? error : ''
    },
    getEndRadiusError(index: number) {
      const error = (this.formErrors as any)[
        `radiusAndFeeds[${index}].radiusEnd`
      ]
      return error ? error : ''
    },
  },
  computed: {
    allowedToAddLevel(): boolean {
      return !Number.isNaN(
        this.listOfRadius[this.listOfRadius.length - 1].radiusEnd,
      )
    },
  },
})
