
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { ModulesIdentifiers } from '@/store'
import Header from '../common/Header.vue'
import { addPaypalEmailValidationSchema } from '@/services/authentication/validations'
import { PartnerActions } from '@/store/modules/partner/actions'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'PaypalEmail',
  components: {
    AkkoHeader: Header,
    Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
  },
  data() {
    return {
      validationSchema: addPaypalEmailValidationSchema(),
      error: false,
      errorMessage: '',
    }
  },
  methods: {
    isInvalid(errors: { [key: string]: string }, name: string): string {
      return errors[name] ? 'is-invalid' : ''
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: { email: string }): Promise<any> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER}/${PartnerActions.UPDATE_PAYOUT_EMAIL}`,
          values,
        )
        this.$router.push(RoutePath.HOME)
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
  },
})
