import { ActionContext } from 'vuex'
import axios from 'axios'
import { State as RootState } from '@/types/vuex'
import {
  CREATE_PARTNER_PORTAL_USER,
  GET_PARTNER_USERS,
  TEAM_USERS,
} from '@/services/api'
import { v4 as uuidv4 } from 'uuid'
import { ModulesIdentifiers } from '@/store'
import { AlertType } from '@/constants/alerts'
import { parseNetworkErrorMessage, pushAlert, root } from '@/utils'
import { TeamState, TeamUser } from '@/types/team'
import { TeamMutations } from '@/store/modules/team/mutations'
import { AppActions } from '../app/actions'
import { registerTeamMemberAdded } from '@/services/gtm'

export enum TeamActions {
  LIST = 'list',
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

const actions: {
  [key: string]: (
    context: ActionContext<TeamState, RootState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ) => void
} = {}

actions[TeamActions.LIST] = async (
  context: ActionContext<TeamState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.get(GET_PARTNER_USERS)
    const { data } = response
    context.commit(TeamMutations.LIST, data.data)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[TeamActions.READ] = async (
  context: ActionContext<TeamState, RootState>,
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const action = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const operation = { id: uuidv4(), show: false }
  try {
    context.dispatch(action, { ...operation, show: true }, root)
    await axios.get(`${TEAM_USERS}${id}/`)
    context.dispatch(action, operation, root)
  } catch (err) {
    context.dispatch(action, operation, root)
    throw parseNetworkErrorMessage(err)
  }
}

actions[TeamActions.CREATE] = async (
  context: ActionContext<TeamState, RootState>,
  values: TeamUser,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const action = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const message = 'Creating sales representative ...'
  const operation = { id: uuidv4(), show: false, message }
  try {
    context.dispatch(action, { ...operation, show: true }, root)
    const response = await axios.post(CREATE_PARTNER_PORTAL_USER, values)
    const { data } = response.data
    context.commit(TeamMutations.CREATE, data)
    const alertMessage = 'Sales representative account created.'
    pushAlert(context, alertMessage, AlertType.SUCCESS)
    context.dispatch(action, operation, root)
    // GTM call
    registerTeamMemberAdded(
      context.rootState.partner.partnerId,
      context.rootState.user.id,
      response.data.data.id,
      response.data.data.position_title,
    )
  } catch (err) {
    const message = parseNetworkErrorMessage(err)
    pushAlert(context, message, AlertType.ERROR)
    context.dispatch(action, operation, root)
    throw parseNetworkErrorMessage(err)
  }
}

actions[TeamActions.UPDATE] = async (
  context: ActionContext<TeamState, RootState>,
  values: TeamUser,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const action = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const operation = { id: uuidv4(), show: false }
  try {
    context.dispatch(action, { ...operation, show: true }, root)
    const { id } = values
    const response = await axios.put(`${TEAM_USERS}${id}/`, values)
    const { data } = response.data
    context.commit(TeamMutations.UPDATE, data)
    context.dispatch(action, operation, root)
    pushAlert(context, 'User updated', AlertType.SUCCESS)
  } catch (err) {
    context.dispatch(action, operation, root)
    throw parseNetworkErrorMessage(err)
  }
}

actions[TeamActions.DELETE] = async (
  context: ActionContext<TeamState, RootState>,
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const action = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const operation = { id: uuidv4(), show: false }
  try {
    context.dispatch(action, { ...operation, show: true }, root)
    await axios.delete(`${TEAM_USERS}${id}/`)
    context.commit(TeamMutations.DELETE, id)
    context.dispatch(action, operation, root)
    pushAlert(context, 'User deleted', AlertType.SUCCESS)
  } catch (err) {
    context.dispatch(action, operation, root)
    return parseNetworkErrorMessage(err)
  }
}

export default actions
