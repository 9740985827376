import {
  PartnerPropertyState,
  PartnerPropertyStore,
  PropertiesNames,
  RepairDeviceType,
  SellDeviceType,
  PartnerProperty,
  CellularServiceType,
} from '@/types/partner-property'
import { OTHER_TYPE } from '@/constants/partner-property'

export enum PartnerPropertyGetters {
  GET_PROPERTIES = 'getProperties',
  GET_PROPERTIES_SALES_ACCESS = 'getPropertiesSalesAccess',
  GET_PARTNER_ID = 'getPartnerId',
  GET_PAYPAL_EMAIL = 'getPaypalEmail',
  GET_PARTNER_PROPERTY = 'getPartnerProperty',
  GET_PROPERTIES_NAMES = 'gesPropertiesNames',
  GET_REPAIR_DEVICES = 'getRepairDevices',
  GET_REPAIR_DEVICE_NAME = 'getRepairDeviceName',
  GET_SELL_DEVICES = 'getSellDevices',
  GET_OTHER_SELL_DEVICE = 'getOtherSellDevice',
  GET_SELL_DEVICE_NAME = 'getSellDeviceName',
  CHECK_SALES_PERMISSION = 'checkSalesPermission',
  GET_OTHER_REPAIR_DEVICE = 'getOtherRepairDevice',
  GET_CELLULAR_SERVICES = 'getCellularServices',
  GET_OTHER_CELLULAR_SERVICE = 'getOtherCellularService',
  GET_CELLULAR_SERVICE_NAME = 'getCellularServiceName',
}

const getters: { [key: string]: (state: PartnerPropertyState) => void } = {}

getters[PartnerPropertyGetters.GET_PROPERTIES] = (
  state: PartnerPropertyState,
): PartnerPropertyStore[] => {
  return state.properties
}

getters[PartnerPropertyGetters.GET_PROPERTIES_SALES_ACCESS] = (
  state: PartnerPropertyState,
): PartnerPropertyStore[] => {
  return state.propertiesSalesAccess
}

getters[PartnerPropertyGetters.GET_PARTNER_ID] = (
  state: PartnerPropertyState,
): number => {
  return state.partnerId
}

getters[PartnerPropertyGetters.GET_PAYPAL_EMAIL] = (
  state: PartnerPropertyState,
): string => {
  return state.paypalEmail
}

getters[PartnerPropertyGetters.GET_PARTNER_PROPERTY] =
  (state: PartnerPropertyState) =>
  (id: number): PartnerPropertyStore | undefined => {
    return state.properties.find(
      (store: PartnerPropertyStore) => store.id === id,
    )
  }

getters[PartnerPropertyGetters.GET_PROPERTIES_NAMES] = (
  state: PartnerPropertyState,
): PropertiesNames => {
  return state.propertiesNames
}

getters[PartnerPropertyGetters.GET_REPAIR_DEVICES] = (
  state: PartnerPropertyState,
): RepairDeviceType[] => {
  return state.repairDeviceTypes
}

getters[PartnerPropertyGetters.GET_REPAIR_DEVICE_NAME] =
  (state: PartnerPropertyState) =>
  (id: number): string => {
    const repairDeviceType = state.repairDeviceTypes.find(
      (repairType: RepairDeviceType) => repairType.id == id,
    )
    if (repairDeviceType) return repairDeviceType.name
    return ''
  }

getters[PartnerPropertyGetters.GET_SELL_DEVICES] = (
  state: PartnerPropertyState,
): SellDeviceType[] => {
  return state.sellDeviceTypes
}

getters[PartnerPropertyGetters.GET_OTHER_SELL_DEVICE] = (
  state: PartnerPropertyState,
): SellDeviceType | undefined => {
  return state.sellDeviceTypes.find(
    (obj) => obj.name.toLowerCase() == OTHER_TYPE,
  )
}

getters[PartnerPropertyGetters.GET_SELL_DEVICE_NAME] =
  (state: PartnerPropertyState) =>
  (id: string): string => {
    const sellDeviceType = state.sellDeviceTypes.find(
      (sellType: SellDeviceType) => sellType.id == id,
    )
    if (sellDeviceType) return sellDeviceType.name
    return ''
  }

getters[PartnerPropertyGetters.CHECK_SALES_PERMISSION] =
  (state: PartnerPropertyState) =>
  (partnerProperty: PartnerProperty, scope: string, value: string): boolean => {
    if (partnerProperty.permissions) {
      const permission = partnerProperty.permissions.find(
        (p) => p.scope === scope,
      )
      if (permission) return permission.value === value
    }
    return false
  }

getters[PartnerPropertyGetters.GET_OTHER_REPAIR_DEVICE] = (
  state: PartnerPropertyState,
): RepairDeviceType | undefined => {
  return state.repairDeviceTypes.find(
    (obj) => obj.name.toLowerCase() == OTHER_TYPE,
  )
}

getters[PartnerPropertyGetters.GET_CELLULAR_SERVICES] = (
  state: PartnerPropertyState,
): CellularServiceType[] => {
  return state.cellularServiceTypes
}

getters[PartnerPropertyGetters.GET_OTHER_CELLULAR_SERVICE] = (
  state: PartnerPropertyState,
): CellularServiceType | undefined => {
  return state.cellularServiceTypes.find(
    (obj) => obj.name.toLowerCase() == OTHER_TYPE,
  )
}

getters[PartnerPropertyGetters.GET_CELLULAR_SERVICE_NAME] =
  (state: PartnerPropertyState) =>
  (id: string): string => {
    const cellularServiceType = state.cellularServiceTypes.find(
      (serviceType: CellularServiceType) => serviceType.id == id,
    )
    if (cellularServiceType) return cellularServiceType.name
    return ''
  }

export default getters
