// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-ts-comment
// @ts-ignore
const impactInstance = function (...args): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.ire) return
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.ire(...args)
}

export const identifyUser = (partnerId: number): void => {
  impactInstance('identify', {
    customerid: partnerId,
    // We don't expose emails to externals
    customeremail: '',
  })
}

export const trackConversion = (newUserId: number, partnerId: number): void => {
  impactInstance(
    'trackConversion',
    27041,
    {
      orderId: partnerId,
      customerId: partnerId,
      note: `Partner id: ${partnerId}, Primary User Id: ${newUserId}`,
    },
    { verifySiteDefinitionMatch: true },
  )
}
