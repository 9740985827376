
import { defineComponent } from 'vue'
import { CoverageType } from '@/constants/coverages'
import { ModulesIdentifiers } from '@/store'
import { coveragesGetters } from '@/store/modules/coverages/getters'
import {
  HomePlusCoverageType,
  HomeRegularCoverageType,
  PlanIntervals,
} from '@/constants/coverages'

export default defineComponent({
  name: 'PlanInfo',
  props: {
    type: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      includeOpen: false,
    }
  },
  setup() {
    return {
      PlanIntervals,
    }
  },
  computed: {
    isAKKOPlan() {
      return this.type === CoverageType.AKKO
    },
    isPhonePlan() {
      return this.type === CoverageType.phone
    },
    isHomeRegularPlan() {
      return this.type === CoverageType.HOME_ELECTRONIC_REGULAR_PLAN
    },
    isHomePlusPlan() {
      return this.type === CoverageType.HOME_ELECTRONIC_PLUS_PLAN
    },
    homePlusCoverage() {
      return this.$store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.HOME_PLUS_COVERAGES}`
      ](HomePlusCoverageType.PLUS)
    },
    homeRegularCoverage() {
      return this.$store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.HOME_REGULAR_COVERAGES}`
      ](HomeRegularCoverageType.REGULAR)
    },
  },
  methods: {
    toggleInclude() {
      this.includeOpen = !this.includeOpen
    },
  },
})
