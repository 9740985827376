
import { defineComponent } from 'vue'
import { DateTime } from 'luxon'
import { claimSearchFormValidationSchema } from '@/services/authentication/validations'
import { ModulesIdentifiers } from '@/store'
import { Claim } from '@/types/claim'
import { ClaimGetters } from '@/store/modules/claim/getters'
import { ClaimStatus } from '@/constants/claim'
import { RoutePath, RouteNames } from '@/constants/router'

export default defineComponent({
  name: 'ClaimDeviceInfo',
  data() {
    return {
      phoneMaskedValue: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      validationSchema: claimSearchFormValidationSchema(),
      ClaimStatus,
      tabNameActive: '',
    }
  },
  setup() {
    return {
      RoutePath,
      RouteNames,
    }
  },
  mounted() {
    if (this.claim && this.claim.devices) {
      const initialDeviceId = this.claim.devices[0].id
      this.activeTab(`Tab${initialDeviceId}`)
    }
  },
  methods: {
    formatLocal: (year: number, month: number, day: number): string => {
      return DateTime.local(year, month, day).toLocaleString(DateTime.DATE_MED)
    },
    activeTab(tabName: string): void {
      this.tabNameActive = tabName
    },
    isActiveTab(tabName: string): boolean {
      return this.tabNameActive === tabName
    },
  },
  computed: {
    claim(): Claim {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.CLAIM}`
      ]
    },
  },
})
