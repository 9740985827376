import { AlertType } from '@/constants/alerts'
import { ModulesIdentifiers } from '@/store'
import { State as RootState } from '@/types/vuex'
import { AppMutations } from '@/store/modules/app/mutations'
import { PartnerState } from '@/types/partner'
import { RequestFilterParams } from '@/types/sales'
import { ActionContext } from 'vuex'
import { TeamState } from '@/types/team'
import { AccessAdministrationState } from '@/types/access-administration'
import { RouteNames } from '@/constants/router'
import { camelCase, transform, isObject } from 'lodash'
import { DateTime } from 'luxon'
import { EndUserState } from '@/types/end-user'

export const stringfyParams = (params: RequestFilterParams): string => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&')
}

export const parseNetworkErrorMessage = (
  error: any,
  fallback?: string,
): string => {
  return error?.response?.status && error?.response?.status != 500
    ? error?.response?.data?.message ||
        error?.response?.data ||
        error.message ||
        fallback ||
        'An unknown error occurred.'
    : 'Something went wrong. Please try again, or contact our support by clicking the blue chat icon in the bottom right of the screen'
}

export const formatPhone = (p: string): string => {
  return p.replace(/([0-9]{3})([0-9]{3})([0-9]{3})/, (_, $1, $2, $3) => {
    return `(${$1}) ${$2}-${$3}`
  })
}

export const pushAlert = (
  context: ActionContext<
    PartnerState | TeamState | AccessAdministrationState | EndUserState,
    RootState
  >,
  message: string,
  type: AlertType,
): void => {
  context.commit(
    `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
    { message, type },
    { root: true },
  )
}

export const maskLastName = (lastname: string): string => {
  return `${lastname.charAt(0).toUpperCase()}.`
}

export const maskPhoneNumber = (phone: string): string => {
  return `(*${phone.substring(phone.length - 4)})`
}

export const isInvalid = (
  errors: { [key: string]: string },
  name: string,
): string => {
  return errors[name] ? 'is-invalid' : ''
}

export const root = { root: true }

export const isProduction = process.env.NODE_ENV === 'production'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInternalPage = (router: any): boolean => {
  return !![
    RouteNames.HOME_MENU,
    RouteNames.HOME_CLAIMS,
    RouteNames.HOME_SALES,
    RouteNames.HOME_REPRESENTATIVES,
    RouteNames.HOME_PROPERTIES,
    RouteNames.HOME_ACCOUNT,
    RouteNames.HOME_REGISTER_NEW_PLAN,
    RouteNames.HOME_MARKETING_MATERIALS,
  ].find((route) => route === router.currentRoute.value.name)
}

export const snakeCaseToCamelCase = (obj: any): any => {
  return transform(obj, function (result, value, key: string) {
    result[camelCase(key)] = isObject(value)
      ? snakeCaseToCamelCase(value)
      : value
  })
}

export const getFirstDayOfMonth = (sourceDate: DateTime): DateTime => {
  return sourceDate.set({ day: 1 })
}

export const getLastDayOfMonth = (sourceDate: DateTime): DateTime => {
  return sourceDate.set({ day: 1 }).plus({ months: 1 }).minus({ days: 1 })
}

export const formatDate_YYYY_MM_DD = (sourceDate: DateTime): string => {
  return sourceDate.toISODate()
}

export const getMonthName = (sourceDate: DateTime): string => {
  return sourceDate.toFormat('MMMM')
}

export const formatDate_MM_DD_YY = (sourceDate: DateTime): string => {
  return sourceDate.toFormat('MM/dd/yy')
}

export const getMonthNameAndDay = (sourceDate: DateTime): string => {
  return sourceDate.toFormat('MMMM dd')
}
