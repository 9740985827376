import {
  Compensation,
  CompensationState,
  SingleTimePayout,
} from '@/types/compensation'

export enum CompensationGetters {
  GET_COMPENSATION_SUMMARY_AMOUNT = 'getCompensationSummaryAMOUNT',
  GET_COMPENSATION = 'getCompensations',
  GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED = 'getCompensationSinglePayoutsSearched',
  GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED_QUANTITY = 'getCompensationSinglePayoutsSearchedQuantity',
  GET_COMPENSATION_SINGLE_PAYOUTS_QUANTITY = 'getCompensationSinglePayoutsQuantity',
  GET_COMPENSATION_SINGLE_PAYOUTS_SUMMARY_AMOUNT = 'getCompensationSinglePayoutsSummaryAmount',
}

const getters: { [key: string]: (state: CompensationState) => void } = {}

getters[CompensationGetters.GET_COMPENSATION_SUMMARY_AMOUNT] = (
  state: CompensationState,
): number => {
  return state.compensation.compensation
}

getters[CompensationGetters.GET_COMPENSATION] = (
  state: CompensationState,
): Compensation => {
  return state.compensation
}

getters[CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED] = (
  state: CompensationState,
): SingleTimePayout[] => {
  return state.singleTimePayoutsSearched
}

getters[CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED_QUANTITY] =
  (state: CompensationState): number => {
    return state.singleTimePayoutsSearchedQuantity
  }

getters[CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_QUANTITY] = (
  state: CompensationState,
): number => {
  return state.singleTimePayoutsQuantity
}

getters[CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SUMMARY_AMOUNT] = (
  state: CompensationState,
): number => {
  return state.singleTimePayoutsSearched.reduce(
    (previousValus: number, payout: SingleTimePayout) => {
      return previousValus + payout.final_amount
    },
    0,
  )
}

export default getters
