import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row mt-3"
}
const _hoisted_2 = { class: "alert alert-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.messageByPartnerStatus)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", null, _toDisplayString(_ctx.messageByPartnerStatus), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}