
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyStore } from '@/types/partner-property'
import CopyText from '@/components/common/CopyText.vue'

export default defineComponent({
  name: 'TeamRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    CopyText,
  },
  methods: {
    getPartnerProperyName(id: string): string {
      const store: PartnerPropertyStore =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PARTNER_PROPERTY}`
        ](id)
      return store ? store.name : ''
    },
  },
})
