import { EndUserState, EndUser } from '@/types/end-user'

export enum EndUsersGetters {
  END_USERS = 'end_users',
  END_USERS_BY_PAGE = 'end_users_by_page',
  END_USER_STATE = 'end_user_state',
  END_USER_CLAIM_V2_SITE = 'end_user_claim_v2_site',
}

const getters: { [key: string]: (state: EndUserState) => void } = {}

getters[EndUsersGetters.END_USERS] = (state: EndUserState): EndUser[] => {
  return state.endUsers
}

getters[EndUsersGetters.END_USERS_BY_PAGE] = (
  state: EndUserState,
): EndUser[] => {
  return state.endUsersByPage
}

getters[EndUsersGetters.END_USER_STATE] = (
  state: EndUserState,
): EndUserState => {
  return state
}

getters[EndUsersGetters.END_USER_CLAIM_V2_SITE] = (
  state: EndUserState,
): string | null => {
  return state.endUserClaimV2Site
}

export default getters
