import { ActionContext } from 'vuex'
import axios from 'axios'
import { State as RootState } from '@/types/vuex'
import { END_USERS, DELETE_END_USERS, AUTH_CLAIM_AS_USER } from '@/services/api'
import { parseNetworkErrorMessage, pushAlert, root } from '@/utils'
import { EndUserState, ParamsPage, EndUserSearch } from '@/types/end-user'
import { EndUsersMutations } from './mutations'
import { ModulesIdentifiers } from '@/store'
import { AppActions } from '../app/actions'
import { v4 as uuidv4 } from 'uuid'
import { AlertType } from '@/constants/alerts'

export enum EndUsersActions {
  LIST = 'list',
  PARAMS_PAGE = 'params_page',
  CURRENT_PAGE = 'current_page',
  FILTER = 'filter',
  CLEAN_FITLER = 'clean_filter',
  DELETE = 'delete',
  AUTH_AS_USER = 'auth_as_user',
}

const actions: {
  [key: string]: (
    context: ActionContext<EndUserState, RootState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ) => void
} = {}

actions[EndUsersActions.LIST] = async (
  context: ActionContext<EndUserState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.get(`${END_USERS}`)
    const { data } = response
    context.commit(EndUsersMutations.LIST, data.data)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[EndUsersActions.PARAMS_PAGE] = async (
  context: ActionContext<EndUserState, RootState>,
  params_page: ParamsPage,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    context.commit(EndUsersMutations.PARAMS_PAGE, params_page)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[EndUsersActions.FILTER] = async (
  context: ActionContext<EndUserState, RootState>,
  endUserSearch: EndUserSearch,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    context.commit(EndUsersMutations.FILTER, endUserSearch)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[EndUsersActions.CLEAN_FITLER] = async (
  context: ActionContext<EndUserState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    context.commit(EndUsersMutations.CLEAN_FITLER)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[EndUsersActions.CURRENT_PAGE] = async (
  context: ActionContext<EndUserState, RootState>,
  current_page: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    context.commit(EndUsersMutations.CURRENT_PAGE, current_page)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[EndUsersActions.DELETE] = async (
  context: ActionContext<EndUserState, RootState>,
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const action = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const operation = { id: uuidv4(), show: false }
  try {
    context.dispatch(action, { ...operation, show: true }, root)
    const basepath = DELETE_END_USERS.replace(
      '{0}',
      context.rootState.partner.partnerId.toString(),
    ).replace('{1}', id.toString())
    await axios.delete(`${basepath}`)
    context.commit(EndUsersMutations.DELETE, id)
    context.commit(EndUsersMutations.CLEAN_FITLER)
    context.dispatch(action, operation, root)
    pushAlert(context, 'User deleted', AlertType.SUCCESS)
  } catch (err) {
    context.dispatch(action, operation, root)
    return parseNetworkErrorMessage(err)
  }
}

actions[EndUsersActions.AUTH_AS_USER] = async (
  context: ActionContext<EndUserState, RootState>,
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const action = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const operation = { id: uuidv4(), show: false }
  try {
    context.commit(EndUsersMutations.CLEAN_AUTH_CLAIM_V2_SITE)
    context.dispatch(action, { ...operation, show: true }, root)
    const basepath = `${AUTH_CLAIM_AS_USER}?user_id=${id.toString()}&visitor_platform=PARTNER_PORTAL`
    const response = await axios.get(basepath)
    const { data } = response
    context.commit(EndUsersMutations.AUTH_CLAIM_V2_SITE, data['token'])
    context.dispatch(action, operation, root)
    pushAlert(context, 'Auth as user', AlertType.SUCCESS)
  } catch (err) {
    context.dispatch(action, operation, root)
    throw parseNetworkErrorMessage(err)
  }
}

export default actions
