
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyStore } from '@/types/partner-property'
import CopyText from '@/components/common/CopyText.vue'
import { PartnerPrograms } from '@/constants/partner-programs'
import { AppGetters } from '@/store/modules/app/getters'

export default defineComponent({
  name: 'UserRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
    onUpdate: {
      type: Function,
    },
    onDelete: {
      type: Function,
    },
  },
  components: {
    CopyText,
  },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  data() {
    return {
      deleting: false,
    }
  },
  methods: {
    onDeleting(deleting: boolean) {
      this.deleting = deleting
    },
    getPartnerProperyName(id: string): string {
      const store: PartnerPropertyStore =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PARTNER_PROPERTY}`
        ](id)
      return store ? store.name : ''
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
  },
})
