
import { defineComponent, PropType } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import { AppActions } from '@/store/modules/app/actions'
import { PartnerPropertyType } from '@/types/app'

export default defineComponent({
  name: 'PartnerPropertyTypeSelector',
  setup() {
    return {
      partnerPropertyTypeSelected: null as PartnerPropertyType | null,
      initiallySelected: false,
      AKKO_PROGRAM: PartnerPrograms.AKKO,
    }
  },
  async mounted() {
    await this.$store.dispatch(
      `${ModulesIdentifiers.APP}/${AppActions.GET_PARTNER_PROPERTY_TYPE}`,
    )
    this.setOptionSelectedOnLoad()
  },
  props: {
    classNames: {
      type: String as PropType<string>,
      default: '',
    },
    onlyAllowPhysicalProperty: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    initialValue: {
      type: Object as PropType<PartnerPropertyType>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: null as any,
    },
  },
  emits: ['changeSelection'],
  methods: {
    onChangePartnerPropertyType(): void {
      this.$emit('changeSelection', this.partnerPropertyTypeSelected)
      this.$store.dispatch(
        `${ModulesIdentifiers.APP}/${AppActions.SET_PARTNER_PROPERTY_TYPE_SELECTED}`,
        this.partnerPropertyTypeSelected?.id || '',
      )
    },
    isOptionDisabled(option: PartnerPropertyType): boolean {
      if (!this.onlyAllowPhysicalProperty) return false

      return option.type !== this.partnerPropertyTypePhysicalDefault.type
    },
    showOptionAsSelected(option: PartnerPropertyType): boolean {
      return option === this.partnerPropertyTypeSelected
    },
    optionToolTip(option: PartnerPropertyType) {
      if (
        this.onlyAllowPhysicalProperty &&
        option.type !== this.partnerPropertyTypePhysicalDefault.type
      ) {
        return 'This type of property can be created later on the application'
      }

      if (
        this.onlyAllowPhysicalProperty &&
        option.type === this.partnerPropertyTypePhysicalDefault.type
      ) {
        return 'Other types of property can be created later on the application'
      }

      return ''
    },
    setOptionSelectedOnLoad(): void {
      if (
        !this.partnerPropertyTypeSelected &&
        this.initialValue &&
        this.partnerPropertyTypeOptions
      ) {
        this.partnerPropertyTypeSelected = {
          ...this.initialValue,
          is_selected: true,
        }
        this.$emit('changeSelection', this.partnerPropertyTypeSelected)
        this.$store.dispatch(
          `${ModulesIdentifiers.APP}/${AppActions.SET_PARTNER_PROPERTY_TYPE_SELECTED}`,
          this.initialValue.id,
        )
      } else {
        this.$emit('changeSelection', this.partnerPropertyTypePhysicalDefault)
        this.partnerPropertyTypeSelected =
          this.partnerPropertyTypePhysicalDefault
        this.$store.dispatch(
          `${ModulesIdentifiers.APP}/${AppActions.SET_PARTNER_PROPERTY_TYPE_SELECTED}`,
          this.partnerPropertyTypeSelected.id,
        )
      }
    },
  },
  computed: {
    partnerProgram(): string {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      return this.$store.getters[key]
    },
    partnerPropertyTypeOptions(): PartnerPropertyType[] {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_PARTNER_PROPERTY_TYPE}`
      return this.$store.getters[key]
    },
    partnerPropertyTypePhysicalDefault(): PartnerPropertyType {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_PARTNER_PROPERTY_TYPE_PHYSICAL_DEFAULT}`
      return this.$store.getters[key]
    },
  },
})
