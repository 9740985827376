
import { defineComponent, PropType } from 'vue'
import { isInvalid } from '@/utils'
import { Field, ErrorMessage, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import isEmpty from 'lodash/isEmpty'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { PartnerPropertyStore } from '@/types/partner-property'
import { electronicPartnerPropertyFormValidationSchema } from '@/services/authentication/validations'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { PartnerPropertyType } from '@/types/app'

export default defineComponent({
  name: 'electronic-property-form',
  setup(props: any) {
    const setInitialValues = () => {
      if (isEmpty(props.initialValues)) return {}
      return {
        email: props.initialValues?.email || '',
        name: props.initialValues?.name || '',
        phone: props.initialValues?.phone || '',
        website: props.initialValues?.website || undefined,
      }
    }

    return {
      phoneMaskedValue: props?.initialValues?.phone || '',
      isEmpty,
      errorMessage: '',
      error: false,
      formValues: setInitialValues(),
      validationSchema: electronicPartnerPropertyFormValidationSchema(),
    }
  },
  components: {
    Field,
    Form,
    ErrorMessage,
    InputMask,
    SpinnerLoading,
  },
  emits: ['onCancel'],
  props: {
    initialValues: {
      type: Object as PropType<PartnerPropertyStore | null>,
      default: null,
    },
    propertyType: {
      type: Object as PropType<PartnerPropertyType>,
      required: true,
    },
    isNewProperty: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    formErrors: {
      type: Object as PropType<any>,
      required: false,
      default: () => ({}),
    },
    setFieldHelper: {
      type: Function as PropType<any>,
      required: true,
    },
    setFieldTouchedHelper: {
      type: Function as PropType<(name: string, val: boolean) => void>,
      required: true,
    },
    resetForm: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  methods: {
    isInvalid,
    async onSubmit(values: PartnerPropertyStore) {
      try {
        let action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.CREATE}`
        let data = { ...values, property_type: this.propertyType }
        if (!this.isNewProperty) {
          action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.UPDATE}`
          data = { ...data, id: this.initialValues?.id }
        }
        await this.$store.dispatch(action, data)
        this.$emit('onCancel')
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
  },
})
