export const CUT_DAY = 15

export enum PayableDocumentStatusOptions {
  PAID = 'Paid',
  AVAILABLE_FOR_PAYMENT = 'Available for payment',
  BLOCKED_FOR_PAYMENT = 'Blocked for payment',
  PERMANENTLY_BLOCKED_FOR_PAYMENT = 'Permanently blocked for payment',
}

export enum PaymentFrontendOptions {
  PAID = 'Paid',
  NOT_PAID = 'Not Paid',
}
