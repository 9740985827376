
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TrashIcon',
  props: {
    stroke: {
      type: String,
      default: '#F3103D',
    },
  },
})
