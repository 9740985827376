
import { defineComponent, PropType } from 'vue'
import { Field, ErrorMessage } from 'vee-validate'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { RepairDeviceType } from '@/types/partner-property'
import { isInvalid } from '@/utils'

export default defineComponent({
  name: 'RepairDeviceTypeSelector',
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_REPAIR_DEVICES}`,
    )
    this.loading = false
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    fieldIdBase: {
      type: String,
      required: true,
    },
    values: {
      type: Object as PropType<{
        stores: { [key: string]: any }[]
        provides_repairs: boolean
        repair_devices: string[]
      }>,
      default: () => {
        return { stores: null }
      },
    },
    errors: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['changeSelection'],
  methods: {
    isInvalid,
    isOtherSelected(): boolean {
      if (this.loading) return false
      if (this.repairDeviceTypeOptions && this.repairDeviceTypeOther) {
        if (this.index < 0 && this.values.repair_devices) {
          return (
            this.values.repair_devices.findIndex(
              (obj: string) => obj == this.repairDeviceTypeOther.id.toString(),
            ) >= 0
          )
        }
        if (this.values.stores === null) return false
        if (!this.values.stores) return false
        if (
          this.values.stores.length <= 0 ||
          this.values.stores[this.index] === undefined
        )
          return false

        if (this.values.stores[this.index].repair_devices)
          return (
            this.values.stores[this.index].repair_devices.findIndex(
              (obj: string) => obj == this.repairDeviceTypeOther.id.toString(),
            ) >= 0
          )
      }
      return false
    },
    getDeviceName(id: string): string {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_REPAIR_DEVICE_NAME}`
      return this.$store.getters[key](id)
    },
    getRepairDevices(): string[] {
      if (this.index < 0) return this.values.repair_devices
      if (this.values.stores === null) return []
      if (!this.values.stores) return []
      if (
        this.values.stores.length <= 0 ||
        this.values.stores[this.index] === undefined
      )
        return []
      return this.values.stores[this.index].repair_devices
    },
    existRepairDevices(): boolean {
      if (this.index < 0)
        return (
          this.values.repair_devices && this.values.repair_devices.length > 0
        )
      if (this.values.stores === null) return false
      if (!this.values.stores) return false
      if (
        this.values.stores.length <= 0 ||
        this.values.stores[this.index] === undefined
      )
        return false
      return (
        this.values.stores[this.index].repair_devices &&
        this.values.stores[this.index].repair_devices.length > 0
      )
    },
  },
  computed: {
    repairDeviceTypeOptions(): RepairDeviceType[] {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_REPAIR_DEVICES}`
      return this.$store.getters[key]
    },
    repairDeviceTypeOther(): RepairDeviceType {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_OTHER_REPAIR_DEVICE}`
      return this.$store.getters[key]
    },
  },
})
