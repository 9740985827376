import { ActionContext } from 'vuex'
import axios from 'axios'
import { State as RootState } from '@/types/vuex'
import {
  CLAIMS_LOOKUP,
  CLAIMS_GET_REPLACEMENT_COST,
  CLAIMS_GET_DEDUCTIBLES,
} from '@/services/api'
import { v4 as uuidv4 } from 'uuid'
import { ModulesIdentifiers } from '@/store'
import { parseNetworkErrorMessage, root, snakeCaseToCamelCase } from '@/utils'
import {
  ClaimsState,
  Claim,
  ClaimsSearch,
  DeductiblesFilter,
} from '@/types/claim'
import { AppActions } from '../app/actions'
import { ClaimsMutations } from './mutations'

export enum ClaimActions {
  LIST = 'list',
  SEARCH = 'search',
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  GET_REPLACEMENT_COST_BY_DEVICE = 'getReplacementCostByDevice',
  GET_DEDUCTIBLES_BY_DEVICE = 'getDeductiblesByDevice',
}

const actions: {
  [key: string]: (
    context: ActionContext<ClaimsState, RootState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ) => void
} = {}

actions[ClaimActions.LIST] = async (
  context: ActionContext<ClaimsState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return Promise.resolve({})
}

actions[ClaimActions.READ] = async (
  context: ActionContext<ClaimsState, RootState>,
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return Promise.resolve({})
}

actions[ClaimActions.CREATE] = async (
  context: ActionContext<ClaimsState, RootState>,
  values: Claim,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return Promise.resolve({})
}

actions[ClaimActions.UPDATE] = async (
  context: ActionContext<ClaimsState, RootState>,
  values: Claim,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return Promise.resolve({})
}

actions[ClaimActions.DELETE] = async (
  context: ActionContext<ClaimsState, RootState>,
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return Promise.resolve({})
}

actions[ClaimActions.SEARCH] = async (
  context: ActionContext<ClaimsState, RootState>,
  filter: ClaimsSearch,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const loadingAction = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const operation = { id: uuidv4(), show: false }
  try {
    context.dispatch(loadingAction, { ...operation, show: true }, root)
    const path = `${CLAIMS_LOOKUP}?claim_id=${filter.claim_id}&phone=${filter.phone}`
    const response = await axios.get(path)
    const { data } = response
    context.commit(ClaimsMutations.ADD, snakeCaseToCamelCase(data))
    context.commit(ClaimsMutations.FILTER, filter)
    context.dispatch(loadingAction, operation, root)
  } catch (err) {
    context.dispatch(loadingAction, operation, root)
    throw parseNetworkErrorMessage(err)
  }
}

actions[ClaimActions.GET_REPLACEMENT_COST_BY_DEVICE] = async (
  context: ActionContext<ClaimsState, RootState>,
  deviceId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const basepath = CLAIMS_GET_REPLACEMENT_COST.replace(
      '{0}',
      deviceId.toString(),
    )
    const response = await axios.get(basepath)
    const { data } = response
    context.commit(ClaimsMutations.SET_REPLACEMENT_COST, data.data)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[ClaimActions.GET_DEDUCTIBLES_BY_DEVICE] = async (
  context: ActionContext<ClaimsState, RootState>,
  filter: DeductiblesFilter,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const basepath = CLAIMS_GET_DEDUCTIBLES.replace(
      '{0}',
      filter.claimId.toString(),
    ).replace('{1}', filter.deviceId.toString())
    const path = `${basepath}?user_id=${filter.userId}`
    const response = await axios.get(path)
    const { data } = response
    context.commit(ClaimsMutations.SET_DEDUCTIBLES, data.data)
    context.commit(ClaimsMutations.CLEAN_DEDUCTIBLES)
    context.commit(ClaimsMutations.SET_SCREEN_REPLACEMENT_DEDUCTIBLE, data.data)
    context.commit(ClaimsMutations.SET_REPAIR_DEDUCTIBLE, data.data)
    context.commit(ClaimsMutations.SET_REPLACEMENT_DEDUCTIBLE, data.data)
    context.commit(ClaimsMutations.SET_COVERAGE_DEDUCTIBLES, data.data)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

export default actions
