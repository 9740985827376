
import { defineComponent, PropType, toRefs, watch } from 'vue'
import {
  AkkoCoverageType,
  CoverageType,
  InjuredGadgetsCoverageType,
  PlanIntervals,
} from '@/constants/coverages'
import { CoverageSelection } from '@/types/plan-registration'
import CoveragesCombo from '@/composables/coverages-combo'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'BusinessPlanSelector',
  setup(props: {
    planInterval: PlanIntervals
    coverageSelection: CoverageSelection[]
    coverageCombo: CoveragesCombo
  }) {
    const { planInterval, coverageSelection, coverageCombo } = toRefs(props)

    watch(planInterval, () =>
      coverageCombo.value.recalculateCoverages(planInterval.value),
    )

    watch(
      coverageSelection,
      (newValues, prevValues) => {
        if (newValues?.length === 0 && prevValues?.length > 0) {
          coverageCombo.value.selectionMap.indexer = 0
          coverageCombo.value.selectionMap.selections = {}
          coverageCombo.value.recalculateCoverages(planInterval.value)
        }
      },
      { deep: true },
    )

    coverageCombo.value.recalculateCoverages(planInterval.value)
    return {
      CoverageType,
      AkkoCoverageType,
      InjuredGadgetsCoverageType,
      PartnerPrograms,
    }
  },
  methods: {
    isCoverageType(type: CoverageType | null): boolean {
      return this.coverageCombo.coverageType === type
    },
    addPhonePlan() {
      this.coverageCombo.addPhonePlan(this.planInterval)
    },
    addAkkoPlan() {
      this.coverageCombo.addAkkoPlan(this.planInterval)
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
  props: {
    planInterval: {
      type: String as PropType<PlanIntervals>,
      required: true,
    },
    coverageSelection: {
      type: Array as PropType<CoverageSelection[]>,
      required: true,
    },
    coverageCombo: {
      type: Object as PropType<CoveragesCombo>,
      required: true,
    },
    selectBusinessPlans: {
      type: Function,
      default: () => {
        /* */
      },
    },
  },
})
