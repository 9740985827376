
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { AppMutations } from '@/store/modules/app/mutations'
import { AppGetters } from '@/store/modules/app/getters'
import { isInternalPage } from '@/utils'
import { PartnerPrograms } from '@/constants/partner-programs'
import Navigation from '@/components/common/Navigation/Navigation.vue'

export default defineComponent({
  name: 'NavButton',
  components: { Navigation },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  methods: {
    toggleSidebar() {
      this.$store.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.TOGGLE_SIDEBAR}`,
        !this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.OPEN_SIDEBAR}`
        ],
      )
    },
  },
  computed: {
    isInternalPage(): boolean {
      return isInternalPage(this.$router)
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isSidebarShowing(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.OPEN_SIDEBAR}`
      ]
    },
  },
})
