import {
  CoverageSelection,
  CoverageSelectionItemAkko,
  CoverageSelectionItemPhone,
  CoverageSelectionMap,
  PlanRegistrationPaymentFields,
  PlanRegistrationState,
  UserWithAccessToPartnerProperty,
} from '@/types/plan-registration'
import { checkForCoverageSelectionCompleteness } from '@/services/coverages'
import { cloneDeep } from 'lodash'
import { DiscountCode } from '@/types/discount-code'
import { Token } from '@stripe/stripe-js'
import {
  BULK_DISCOUNT_CODE,
  MULTIPLAN_DISCOUNT_CODE,
} from '@/constants/plan-registration'

export enum PlanRegistrationMutations {
  SET_PLAN_SELECTION = 'setPlanSelections',
  ADD_DISCOUNT_CODE = 'addDiscountCode',
  REMOVE_DISCOUNT_CODE = 'removeDiscountCode',
  SET_PLAN_SELECTION_PHONE = 'setPlanSelectionPhone',
  SET_PLAN_SELECTION_AS_PRIMARY = 'setPlanSelectionAsPrimary',
  SET_PAYMENT_INFORMATION_FILED = 'setPaymentInformationField',
  SET_LIST_OF_USERS_WITH_ACCESS_TO_PP = 'setListOfUsersWithAccessToPP',
  SET_PARTNER_PROPERTY_ID = 'setPartnerPropertyId',
  SET_SALES_REP_ID = 'setSalesRepId',
  SET_SUCCESSFUL_REGISTER_SUMMARY = 'setSuccessfulRegisterSummary',
  CLEAN_REGISTER_SUMMARY = 'cleanRegisterSummary',
  CLEAN_PAYMENT_INFORMATION = 'cleanPaymentInformation',
  CLEAN_STORE_AND_SALES_REP = 'cleanStoreAndSalesRep',
  CLEAN_REGISTRATION_STATE = 'cleanRegistrationState',
  REMOVE_NON_AUTOMATIC_DISCOUNT_CODES = 'removeNonAutomaticDiscountCodes',
  CLEAN_DISCOUNTS = 'cleanDiscountsState',
  CLEAN_NON_AUTOMATED_DISCOUNTS = 'cleanNonAutomatedDiscountsState',
  SET_POSTAL_CODE = 'setPostalCode',
  SET_ADDRESS = 'setAddress',
  SET_TAX_RATE = 'setTaxRate',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mutations: any = {}

mutations[PlanRegistrationMutations.SET_PLAN_SELECTION] = function (
  state: PlanRegistrationState,
  coverageSelectionMap: CoverageSelectionMap,
): void {
  state.plans = []
  for (const key in coverageSelectionMap.selections) {
    if (
      checkForCoverageSelectionCompleteness(
        coverageSelectionMap.selections[key],
      )
    ) {
      if (coverageSelectionMap.selections[key].type === 'phone') {
        state.plans.push({
          type: 'phone',
          annualCoverage: cloneDeep(
            coverageSelectionMap.selections[key].annualCoverage,
          ),
          monthlyCoverage: cloneDeep(
            coverageSelectionMap.selections[key].monthlyCoverage,
          ),
          selectedCoverageInterval:
            coverageSelectionMap.selections[key].selectedCoverageInterval,
          detail: {
            maker: (<CoverageSelectionItemPhone>(
              coverageSelectionMap.selections[key].detail
            )).maker,
            model: (<CoverageSelectionItemPhone>(
              coverageSelectionMap.selections[key].detail
            )).model,
            replacementDeductible: (<CoverageSelectionItemPhone>(
              coverageSelectionMap.selections[key].detail
            )).replacementDeductible,
            screenReplacementDeductible: (<CoverageSelectionItemPhone>(
              coverageSelectionMap.selections[key].detail
            )).screenReplacementDeductible,
            repairDeductible: (<CoverageSelectionItemPhone>(
              coverageSelectionMap.selections[key].detail
            )).repairDeductible,
          },
          phoneNumber: null,
          primary: null,
          uiId: Number(key),
        })
      } else if (coverageSelectionMap.selections[key].type === 'akko') {
        state.plans.push({
          type: 'akko',
          annualCoverage: cloneDeep(
            coverageSelectionMap.selections[key].annualCoverage,
          ),
          monthlyCoverage: cloneDeep(
            coverageSelectionMap.selections[key].monthlyCoverage,
          ),
          selectedCoverageInterval:
            coverageSelectionMap.selections[key].selectedCoverageInterval,
          detail: {
            type: (<CoverageSelectionItemAkko>(
              coverageSelectionMap.selections[key].detail
            )).type,
          },
          phoneNumber: null,
          primary: null,
          uiId: Number(key),
        })
      } else {
        state.plans.push({
          type: coverageSelectionMap.selections[key].type,
          annualCoverage: cloneDeep(
            coverageSelectionMap.selections[key].annualCoverage,
          ),
          monthlyCoverage: cloneDeep(
            coverageSelectionMap.selections[key].monthlyCoverage,
          ),
          selectedCoverageInterval:
            coverageSelectionMap.selections[key].selectedCoverageInterval,
          detail: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            type: (coverageSelectionMap.selections[key].detail as any).type,
          },
          phoneNumber: null,
          primary: null,
          uiId: Number(key),
        })
      }
    }
  }
}

mutations[PlanRegistrationMutations.REMOVE_DISCOUNT_CODE] = function (
  state: PlanRegistrationState,
  code: string,
): void {
  state.discounts = state.discounts.filter((d) => d.code != code)
}

mutations[PlanRegistrationMutations.REMOVE_NON_AUTOMATIC_DISCOUNT_CODES] =
  function (state: PlanRegistrationState): void {
    state.discounts = state.discounts.filter(
      (d) =>
        d.code === MULTIPLAN_DISCOUNT_CODE || d.code === BULK_DISCOUNT_CODE,
    )
  }

mutations[PlanRegistrationMutations.ADD_DISCOUNT_CODE] = function (
  state: PlanRegistrationState,
  discountCodePayload: DiscountCode,
): void {
  if (state.discounts.find((d) => discountCodePayload.code == d.code)) return
  state.discounts.push(discountCodePayload)
}

mutations[PlanRegistrationMutations.SET_PLAN_SELECTION_PHONE] = function (
  state: PlanRegistrationState,
  {
    coverageSelection,
    phoneNumber,
  }: { coverageSelection: CoverageSelection; phoneNumber: string },
): void {
  const plan = state.plans.find((plan) => coverageSelection.uiId === plan.uiId)
  if (plan) plan.phoneNumber = phoneNumber
}

mutations[PlanRegistrationMutations.SET_PLAN_SELECTION_AS_PRIMARY] = function (
  state: PlanRegistrationState,
  coverageSelection: CoverageSelection,
): void {
  state.plans.forEach((plan) => {
    plan.primary = plan.uiId === coverageSelection.uiId
  })
}

mutations[PlanRegistrationMutations.SET_PAYMENT_INFORMATION_FILED] = function (
  state: PlanRegistrationState,
  payload: {
    field: PlanRegistrationPaymentFields
    value: string & Token
  },
): void {
  state.paymentInformation[payload.field] = payload.value
}

mutations[PlanRegistrationMutations.SET_LIST_OF_USERS_WITH_ACCESS_TO_PP] =
  function (
    state: PlanRegistrationState,
    payload: UserWithAccessToPartnerProperty[],
  ): void {
    state.usersWithAccessToPartnerProperty = payload
  }

mutations[PlanRegistrationMutations.SET_PARTNER_PROPERTY_ID] = function (
  state: PlanRegistrationState,
  partnerPropertyId: number,
): void {
  state.partnerPropertyId = partnerPropertyId
  state.preferredPartnerPropertyId = partnerPropertyId
}

mutations[PlanRegistrationMutations.SET_SALES_REP_ID] = function (
  state: PlanRegistrationState,
  salesRepId: number,
): void {
  state.salesRepId = salesRepId
  state.preferredSalesRepId = salesRepId
}

mutations[PlanRegistrationMutations.SET_SUCCESSFUL_REGISTER_SUMMARY] =
  function (
    state: PlanRegistrationState,
    payload: {
      transaction_id: string
      registered_phones: string[]
    },
  ): void {
    state.registrationSummary = {
      transactionId: payload.transaction_id,
      phoneNumbers: payload.registered_phones,
    }
  }

mutations[PlanRegistrationMutations.CLEAN_REGISTER_SUMMARY] = function (
  state: PlanRegistrationState,
): void {
  state.registrationSummary = null
}

mutations[PlanRegistrationMutations.CLEAN_PAYMENT_INFORMATION] = function (
  state: PlanRegistrationState,
): void {
  state.paymentInformation = {
    ...state.paymentInformation,
    creditCardToken: null,
  }
}

mutations[PlanRegistrationMutations.CLEAN_REGISTRATION_STATE] = function (
  state: PlanRegistrationState,
): void {
  state.paymentInformation = {
    cardHolderFirstName: '',
    cardHolderLastName: '',
    primaryEmailAddress: '',
    creditCardToken: null,
  }
  state.plans = []
  state.discounts = []
  state.partnerPropertyId = NaN
  state.salesRepId = NaN
  state.address = ''
  state.postalCode = ''
}

mutations[PlanRegistrationMutations.CLEAN_STORE_AND_SALES_REP] = function (
  state: PlanRegistrationState,
): void {
  state.salesRepId = NaN
  state.partnerPropertyId = NaN
}

mutations[PlanRegistrationMutations.CLEAN_DISCOUNTS] = function (
  state: PlanRegistrationState,
): void {
  state.discounts = []
}

mutations[PlanRegistrationMutations.CLEAN_NON_AUTOMATED_DISCOUNTS] = function (
  state: PlanRegistrationState,
): void {
  state.discounts = state.discounts.filter(
    (d) => d.code === MULTIPLAN_DISCOUNT_CODE || d.code === BULK_DISCOUNT_CODE,
  )
}

mutations[PlanRegistrationMutations.SET_ADDRESS] = function (
  state: PlanRegistrationState,
  address: string,
): void {
  state.address = address
}

mutations[PlanRegistrationMutations.SET_POSTAL_CODE] = function (
  state: PlanRegistrationState,
  postalCode: string,
): void {
  state.postalCode = postalCode
}

mutations[PlanRegistrationMutations.SET_TAX_RATE] = function (
  state: PlanRegistrationState,
  rate: number,
) {
  state.taxRate = rate
}

export default mutations
