import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuccessIcon = _resolveComponent("SuccessIcon")!
  const _component_InformationIcon = _resolveComponent("InformationIcon")!
  const _component_DismissIcon = _resolveComponent("DismissIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert alert-dismissible fade", `${_ctx.color(_ctx.kind)} ${_ctx.dismissing ? '' : 'show'}`]),
    role: "alert"
  }, [
    (_ctx.kind === _ctx.AlertType.SUCCESS)
      ? (_openBlock(), _createBlock(_component_SuccessIcon, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.kind === _ctx.AlertType.NOTIFICATION)
      ? (_openBlock(), _createBlock(_component_InformationIcon, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.kind === _ctx.AlertType.ERROR || _ctx.kind === _ctx.AlertType.WARNING)
      ? (_openBlock(), _createBlock(_component_DismissIcon, { key: 2 }))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.message) + " ", 1),
    _createElementVNode("button", {
      type: "button",
      class: "btn-close",
      "data-bs-dismiss": "alert",
      "aria-label": "Close",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss()))
    })
  ], 2))
}