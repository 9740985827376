import {
  ClaimsState,
  Claim,
  ClaimsSearch,
  ClaimDeviceDeductible,
} from '@/types/claim'

export enum ClaimGetters {
  CLAIM = 'claim',
  CLAIMS = 'claims',
  FILTER = 'filter',
  REPLACEMENT_COST = 'replacementCost',
  DEDUCTIBLES = 'deductibles',
  DEDUCTIBLE_BY_TYPE = 'deductibleByType',
  SCREEN_REPLACEMENT_DEDUCTIBLE = 'screenReplacementDeductible',
  REPAIR_DEDUCTIBLE = 'repairDeductible',
  REPLACEMENT_DEDUCTIBLE = 'replacementDeductible',
  COVERAGE_DEDUCTIBLE = 'coverageDeductible',
}

const getters: { [key: string]: (state: ClaimsState) => void } = {}

getters[ClaimGetters.CLAIM] = (state: ClaimsState): Claim => {
  return state.claim
}

getters[ClaimGetters.CLAIMS] = (state: ClaimsState): Claim[] => {
  return state.claims
}

getters[ClaimGetters.FILTER] = (state: ClaimsState): ClaimsSearch => {
  return state.filter
}

getters[ClaimGetters.REPLACEMENT_COST] = (state: ClaimsState): number => {
  if (state.replacementCost) return state.replacementCost.replacement_cost
  return -1
}

getters[ClaimGetters.DEDUCTIBLES] = (
  state: ClaimsState,
): ClaimDeviceDeductible[] => {
  return state.deductibles
}

getters[ClaimGetters.SCREEN_REPLACEMENT_DEDUCTIBLE] = (
  state: ClaimsState,
): number => {
  return state.screenReplacementDeductible
}

getters[ClaimGetters.REPAIR_DEDUCTIBLE] = (state: ClaimsState): number => {
  return state.repairDeductible
}

getters[ClaimGetters.REPLACEMENT_DEDUCTIBLE] = (state: ClaimsState): number => {
  return state.replacementDeductible
}

getters[ClaimGetters.COVERAGE_DEDUCTIBLE] = (state: ClaimsState): number => {
  return state.coverageDeductible
}

export default getters
