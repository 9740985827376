import * as yup from 'yup'

//  Add unique validation for yup array validation
yup.addMethod(yup.array, 'unique', function (message, mapper = (a: any) => a) {
  return this.test('unique', message, function (list) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return list.length === new Set(list.map(mapper)).size
  })
})

export const registrationValidation = (): any => {
  return yup.object().shape({
    partnerProperty: yup.number().required().positive().integer(),
    salesRepresentative: yup.number().required().positive().integer(),
    address: yup.string(),
    postalCode: yup
      .string()
      .required(
        'A valid postal code is required (select an option from the autocomplete widget)',
      ),
    coverages: yup
      .array()
      .of(
        yup
          .string()
          .min(10, 'A valid phone has at least 10 digits')
          .max(11, 'A valid phone number does not have more than 11 digits')
          .required('A phone number is required'),
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .unique('Phone numbers must be unique'),
    cardholderFirstName: yup
      .string()
      .max(100)
      .required('First name is required'),
    cardholderLastName: yup.string().max(100).required('Last name is required'),
    primaryEmailAddress: yup
      .string()
      .email('Should be email')
      .max(60)
      .required('Last name is required'),
    card: yup.object().defined().required('Required'),
  })
}

export const productRegistrationValidation = (): any => {
  return yup.object().shape({
    partnerProperty: yup.number().required().positive().integer(),
    salesRepresentative: yup.number().required().positive().integer(),
    address: yup.string(),
    postalCode: yup
      .string()
      .required(
        'A valid postal code is required (select an option from the autocomplete widget)',
      ),
    products: yup
      .array()
      .of(
        yup
          .string()
          .min(10, 'A valid phone has at least 10 digits')
          .max(11, 'A valid phone number does not have more than 11 digits')
          .required('A phone number is required'),
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .unique('Phone numbers must be unique'),
    cardholderFirstName: yup
      .string()
      .max(100)
      .required('First name is required'),
    cardholderLastName: yup.string().max(100),
    primaryEmailAddress: yup
      .string()
      .email('Should be email')
      .max(60)
      .required('Last name is required'),
    card: yup.object().defined().required('Required'),
  })
}
