
import { defineComponent, PropType, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import isEmpty from 'lodash/isEmpty'
import { isInvalid } from '@/utils'
import { ModulesIdentifiers } from '@/store'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { GooglePlaceResponse } from '@/types/app'
import { MapsSearchInputMode } from '@/constants/google-maps'
import { partnerPropertyMobileFormValidationSchema } from '@/services/authentication/validations'
import RadiusLimitsAndAssociatedCostList from '@/components/common/RadiusLimitsAndAssociatedCostList.vue'
import ZipCodeAndAssociatedCostList from '@/components/common/ZipCodeAndAssociatedCostList.vue'
import { PartnerPropertyType } from '@/types/app'
import { AppGetters } from '@/store/modules/app/getters'
import RepairDeviceTypeSelector from '@/components/common/RepairDeviceTypeSelector.vue'
import SellDeviceTypeSelector from '@/components/common/SellDeviceTypeSelector.vue'
import CellularServiceTypeSelector from '@/components/common/CellularServiceTypeSelector.vue'

export default defineComponent({
  name: 'mobile-property-form',
  components: {
    ZipCodeAndAssociatedCostList,
    Field,
    Form,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
    GoogleMapsInput,
    RadiusLimitsAndAssociatedCostList,
    RepairDeviceTypeSelector,
    SellDeviceTypeSelector,
    CellularServiceTypeSelector,
  },
  props: {
    initialValues: {
      type: Object as PropType<any>,
      required: false,
      default: () => ({} as any),
    },
    propertyType: {
      type: Object as PropType<PartnerPropertyType>,
      required: true,
    },
    isNewProperty: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  emits: ['onCancel'],
  setup(props: any) {
    const setInitialValues = (): any => {
      if (isEmpty(props.initialValues)) return {}
      let obj = {
        propertyType: null as any,
        place: props.initialValues.place || '',
        email: props.initialValues.email || '',
        name: props.initialValues.name || '',
        phone: props.initialValues.phone || '',
        website: props.initialValues.website || null,
        provides_repairs: props.initialValues?.provides_repairs ? true : false,
        repair_devices: props.initialValues?.provides_repairs
          ? props.initialValues.repair_devices
          : [],
        provides_sells: props.initialValues?.provides_sells ? true : false,
        sell_devices: props.initialValues?.provides_sells
          ? props.initialValues.sell_devices
          : [],
        place_details: null,
        radiusAndFeeds: props.initialValues.radius_and_feeds
          ? props.initialValues.radius_and_feeds.map((rf: any) => ({
              radiusStart: rf.radius_start,
              radiusEnd: rf.radius_end,
              travelFee: rf.fee,
            }))
          : null,
        zipCodesAndFeeds: props.initialValues.zip_codes_and_feeds
          ? props.initialValues.zip_codes_and_feeds.map((zf: any) => ({
              zipCode: zf.zipcode,
              travelFee: zf.fee,
            }))
          : null,
        byZipCodes: props.initialValues.zip_codes_and_feeds ? true : false,
        byAddressAndRadius: props.initialValues.radius_and_feeds ? true : false,
        provides_sell_cellular_services: props.initialValues
          ?.provides_sell_cellular_services
          ? true
          : false,
        cellular_services: props.initialValues?.cellular_services
          ? props.initialValues.cellular_services
          : [],
      }
      obj = { ...obj, ...props.initialValues }
      if (props.initialValues.repair_devices) {
        obj['repair_devices'] = props.initialValues.repair_devices.map(
          (r: any) => r.repair_device_id.toString(),
        )
      }
      if (props.initialValues?.sell_devices) {
        obj['sell_devices'] =
          props.initialValues?.sell_devices.map(
            (value: any) => value.sell_device_id,
          ) || []
      }
      if (props.initialValues?.cellular_services) {
        obj['cellular_services'] =
          props.initialValues?.cellular_services.map(
            (value: any) => value.mobile_operator_id,
          ) || []
      }
      return obj
    }

    const initialValues = setInitialValues()

    const form = ref(null) as any
    return {
      error: false,
      errorMessage: '',
      phoneMaskedValue: props.initialValues.phone || '',
      placeValue: props.initialValues.place || '',
      placeDetails: null as any,
      validPlace: true,
      validPlaceTimeId: 0,
      enterZipCodes: true,
      MapsSearchInputMode,
      zipcodes: [''],
      validationSchema: partnerPropertyMobileFormValidationSchema(),
      byZipCodes: isEmpty(initialValues) ? true : initialValues.byZipCodes,
      byAddressAndRadius: isEmpty(initialValues)
        ? true
        : initialValues.byAddressAndRadius,
      formValues: initialValues,
      form,
    }
  },
  mounted() {
    if (this.isNewProperty) {
      this.form?.setFieldValue('byZipCodes', true)
    }
  },
  methods: {
    isEmpty,
    isInvalid,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: any): Promise<any> {
      const website = isEmpty(values.website) ? null : values.website
      let data: any = { website }
      data = { ...data, ...values }
      if (values.byZipCodes) {
        data = Object.assign({}, data, {
          radius_and_feeds: null,
          by_zip_codes: true,
          by_address_and_radius: false,
          zip_codes_and_feeds: values.zipCodesAndFeeds,
          name: values.name,
          email: values.email,
          property_type: this.propertyType,
          phone: values.phone,
          provides_repairs: values.provides_repairs ? 'true' : 'false',
          repair_devices: values.repair_devices,
          provides_selss: values.provides_selss ? 'true' : 'false',
          sell_devices: values.sell_devices,
        })
      } else if (values.byAddressAndRadius) {
        data = Object.assign({}, data, {
          radius_and_feeds: values.radiusAndFeeds,
          by_zip_codes: false,
          by_address_and_radius: true,
          zip_codes_and_feeds: null,
          name: values.name,
          email: values.email,
          property_type: this.propertyType,
          phone: values.phone,
          place_details: this.placeDetails,
          place: this.placeValue,
          provides_repairs: values.provides_repairs ? 'true' : 'false',
          repair_devices: values.repair_devices,
          provides_selss: values.provides_selss ? 'true' : 'false',
          sell_devices: values.sell_devices,
        })
      }
      try {
        let action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.CREATE}`
        if (!this.isNewProperty) {
          action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.UPDATE}`
          data = { ...data, id: this.initialValues?.id }
        }
        await this.$store.dispatch(action, data)
        this.$emit('onCancel')
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    handlePlaceInput(place: GooglePlaceResponse) {
      this.placeValue = `${place.name} ${place.formatted_address}`
      this.placeDetails = {
        name: place.name,
        formatted_address: place.formatted_address,
        business_status: place.business_status,
        formatted_phone_number: place.formatted_phone_number,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        icon: place.icon,
        icon_background_color: place.icon_background_color,
        icon_mask_base_uri: place.icon_mask_base_uri,
        international_phone_number: place.international_phone_number,
        place_id: place.place_id,
        price_level: place.price_level,
        rating: place.rating,
        reference: place.reference,
        url: place.url,
        user_ratings_total: place.user_ratings_total,
        utc_offset_minutes: place.utc_offset_minutes,
        vicinity: place.vicinity,
        website: place.website,
      }
      this.validPlace = true
      clearTimeout(this.validPlaceTimeId)
    },
    setCoverageByZipCodes(): void {
      this.byZipCodes = true
      this.byAddressAndRadius = false
    },
    setCoverageByAddressAndRadius(): void {
      this.byZipCodes = false
      this.byAddressAndRadius = true
    },
    onChange(): void {
      this.validPlaceTimeId = setTimeout(() => {
        this.validPlace = false
      }, 1000)
    },
    addZipCode(): void {
      this.zipcodes.push('')
    },
    handleRadiusLimitsAndAssociatedCostInput(payload: any): void {
      this.form?.setFieldValue('radiusAndFeeds', payload)
      this.form?.validate()
    },
  },
  computed: {
    partnerPropertyTypeFirstMobile(): PartnerPropertyType {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_PARTNER_PROPERTY_TYPE_FIRST_MOBILE}`
      return this.$store.getters[key]
    },
  },
})
