<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4375 11.6562C8.57133 11.6558 8.7004 11.7059 8.79889 11.7965C8.89738 11.8871 8.95804 12.0116 8.96875 12.145V17.0112C8.95804 17.1447 8.89738 17.2691 8.79889 17.3597C8.7004 17.4503 8.57133 17.5004 8.4375 17.5H1.53125C1.39742 17.5004 1.26835 17.4503 1.16986 17.3597C1.07137 17.2691 1.01071 17.1447 1 17.0112V12.145C1.01071 12.0116 1.07137 11.8871 1.16986 11.7965C1.26835 11.7059 1.39742 11.6558 1.53125 11.6562H8.4375ZM17.4688 7.40625C17.6096 7.40625 17.7448 7.46222 17.8444 7.56185C17.944 7.66148 18 7.7966 18 7.9375V16.9687C18 17.1096 17.944 17.2448 17.8444 17.3444C17.7448 17.444 17.6096 17.5 17.4688 17.5H10.5625C10.4216 17.5 10.2865 17.444 10.1868 17.3444C10.0872 17.2448 10.0312 17.1096 10.0312 16.9687V7.9375C10.0312 7.7966 10.0872 7.66148 10.1868 7.56185C10.2865 7.46222 10.4216 7.40625 10.5625 7.40625H17.4688ZM7.90625 12.6284H2.0625V16.5278H7.90625V12.6284ZM16.9375 8.46875H11.0938V16.4375H16.9375V8.46875ZM8.4375 0.500003C8.5784 0.500003 8.71352 0.555974 8.81315 0.655602C8.91278 0.755231 8.96875 0.890356 8.96875 1.03125V10.0625C8.96875 10.2034 8.91278 10.3385 8.81315 10.4382C8.71352 10.5378 8.5784 10.5938 8.4375 10.5938H1.53125C1.39035 10.5938 1.25523 10.5378 1.1556 10.4382C1.05597 10.3385 1 10.2034 1 10.0625V1.03125C1 0.890356 1.05597 0.755231 1.1556 0.655602C1.25523 0.555974 1.39035 0.500003 1.53125 0.500003H8.4375ZM7.90625 1.5625H2.0625V9.53125H7.90625V1.5625ZM17.4688 0.500003C17.6026 0.499574 17.7316 0.549674 17.8301 0.640287C17.9286 0.730899 17.9893 0.855348 18 0.988753V5.855C17.9893 5.98841 17.9286 6.11285 17.8301 6.20347C17.7316 6.29408 17.6026 6.34418 17.4688 6.34375H10.5625C10.4287 6.34418 10.2996 6.29408 10.2011 6.20347C10.1026 6.11285 10.042 5.98841 10.0312 5.855V0.988753C10.042 0.855348 10.1026 0.730899 10.2011 0.640287C10.2996 0.549674 10.4287 0.499574 10.5625 0.500003H17.4688ZM16.9375 1.47219H11.0938V5.37156H16.9375V1.47219Z"
      fill="currentColor"
      stroke-width="0.3"
    />
  </svg>
</template>
