import { AppMutations } from '@/store/modules/app/mutations'
import { State as RootState } from '@/types/vuex'
import { UserState } from '@/types/user'
import { ActionContext } from 'vuex'
import axios from 'axios'
import {
  ABOUT_AKKO,
  COUNTRY,
  PARTNER_TYPE,
  PARTNER_PROPERTY_TYPE,
} from '@/services/api'

export enum AppActions {
  TOGGLE_LOADING_OPERATION = 'toggleLoadingOperation',
  GET_ABOUT_AKKO = 'getAboutAkko',
  GET_COUNTRY = 'getCountry',
  GET_PARTNER_TYPE = 'getPartnerType',
  GET_PARTNER_PROPERTY_TYPE = 'getPartnerPropertyType',
  SET_PARTNER_PROPERTY_TYPE_SELECTED = 'setPartnerPropertyTypeSelected',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actions: any = {}

actions[AppActions.TOGGLE_LOADING_OPERATION] = (
  context: ActionContext<UserState, RootState>,
  data: { id: string; show: boolean; message?: string },
): void => {
  const { id, show, ...others } = data
  const action = show
    ? AppMutations.SUBMIT_LOADING_OPERATION
    : AppMutations.REMOVE_LOADING_OPERATION
  context.commit(action, { id, ...others })
}

actions[AppActions.GET_ABOUT_AKKO] = async (
  context: ActionContext<UserState, RootState>,
): Promise<void> => {
  try {
    const response = await axios.get(ABOUT_AKKO)
    const { data } = response.data
    context.commit(AppMutations.SET_ABOUT_AKKO, data)
  } catch (err) {
    // TODO Log error and notify user
  }
}

actions[AppActions.GET_COUNTRY] = async (
  context: ActionContext<UserState, RootState>,
): Promise<void> => {
  try {
    const response = await axios.get(COUNTRY)
    const { data } = response.data
    context.commit(AppMutations.SET_COUNTRY, data)
  } catch (err) {
    // TODO Log error and notify user
  }
}

actions[AppActions.GET_PARTNER_TYPE] = async (
  context: ActionContext<UserState, RootState>,
): Promise<void> => {
  try {
    const response = await axios.get(PARTNER_TYPE)
    const { data } = response.data
    context.commit(AppMutations.SET_PARTNER_TYPE, data)
  } catch (err) {
    // TODO Log error and notify user
  }
}

actions[AppActions.GET_PARTNER_PROPERTY_TYPE] = async (
  context: ActionContext<UserState, RootState>,
): Promise<void> => {
  try {
    const response = await axios.get(PARTNER_PROPERTY_TYPE)
    const { data } = response.data
    context.commit(AppMutations.SET_PARTNER_PROPERTY_TYPE, data)
  } catch (err) {
    // TODO Log error and notify user
  }
}

actions[AppActions.SET_PARTNER_PROPERTY_TYPE_SELECTED] = async (
  context: ActionContext<UserState, RootState>,
  data: string,
): Promise<void> => {
  try {
    context.commit(AppMutations.SET_PARTNER_PROPERTY_TYPE_SELECTED, data)
  } catch (err) {
    // TODO Log error and notify user
  }
}

export default actions
