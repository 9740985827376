
import { defineComponent, PropType } from 'vue'
import InputMask from 'primevue/inputmask'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { Field, ErrorMessage } from 'vee-validate'
import { GooglePlaceResponse } from '@/types/app'
import PartnerPropertyTypeSelector from '@/components/common/PartnerPropertyTypeSelector.vue'
import { PartnerPropertyType } from '@/types/app'
import { PartnerPrograms } from '@/constants/partner-programs'
import RepairDeviceTypeSelector from '@/components/common/RepairDeviceTypeSelector.vue'
import SellDeviceTypeSelector from '@/components/common/SellDeviceTypeSelector.vue'
import CellularServiceTypeSelector from '@/components/common/CellularServiceTypeSelector.vue'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'

export default defineComponent({
  name: 'NewPropertyFields',
  components: {
    PartnerPropertyTypeSelector,
    Field,
    ErrorMessage,
    InputMask,
    GoogleMapsInput,
    RepairDeviceTypeSelector,
    SellDeviceTypeSelector,
    CellularServiceTypeSelector,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
    },
    onRemoveForm: {
      type: Function,
    },
    values: {
      type: Object as PropType<{
        stores: { [key: string]: string | boolean }[]
      }>,
      default: () => {
        return { stores: null }
      },
    },
    errors: {
      type: Object as PropType<{ [key: string]: string }>,
      default: () => ({}),
    },
    store: {
      type: Object,
      required: true,
    },
    setFieldValue: {
      type: Function,
      default: () => {
        /* */
      },
    },
  },
  data() {
    return {
      phoneMaskedValue: '',
      placeValue: '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      propertyType: null as any,
      validPlace: true,
      validPlaceTimeId: 0,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      placeDetails: null as any,
    }
  },
  emits: {
    onRemoveForm: null,
  },
  methods: {
    typeChange(propertyType: PartnerPropertyType) {
      this.propertyType = propertyType
    },
    isInvalid(name: string): string {
      return this.errors[name] ? 'is-invalid' : ''
    },
    handlePlaceInput(place: GooglePlaceResponse): void {
      this.placeValue = `${place.name} ${place.formatted_address}`
      this.placeDetails = {
        name: place.name,
        formatted_address: place.formatted_address,
        business_status: place.business_status,
        formatted_phone_number: place.formatted_phone_number,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        icon: place.icon,
        icon_background_color: place.icon_background_color,
        icon_mask_base_uri: place.icon_mask_base_uri,
        international_phone_number: place.international_phone_number,
        place_id: place.place_id,
        price_level: place.price_level,
        rating: place.rating,
        reference: place.reference,
        url: place.url,
        user_ratings_total: place.user_ratings_total,
        utc_offset_minutes: place.utc_offset_minutes,
        vicinity: place.vicinity,
        website: place.website,
      }
      this.setFieldValue(
        `stores[${this.index}].place_details`,
        this.placeDetails,
      )
      this.validPlace = true
      clearTimeout(this.validPlaceTimeId)
    },
    onChange(): void {
      this.validPlaceTimeId = setTimeout(() => {
        this.validPlace = false
      }, 1000)
    },
  },
  computed: {
    offerRepairs(): boolean {
      if (this.values.stores === null) return false
      if (this.values.stores[this.index] === undefined) return false
      return this.values.stores[this.index].repairs as boolean
    },
    offerSells(): boolean {
      if (this.values.stores === null) return false
      if (this.values.stores[this.index] === undefined) return false
      return this.values.stores[this.index].provides_sells as boolean
    },
    offerSellCellServices(): boolean {
      if (this.values.stores === null) return false
      if (this.values.stores[this.index] === undefined) return false
      return this.values.stores[this.index]
        .provides_sell_cellular_services as boolean
    },
    isInjuredGadgets(): boolean {
      return (
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
        ] === PartnerPrograms.INJURED_GADGETS
      )
    },
  },
})
