import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { PlanIntervals } from '@/constants/coverages'

export default {
  state: {
    catalogs: [],
    products: [],
    productCart: {},
    productCartInterval: PlanIntervals.ANNUAL,
    currentProductGroupSelection: '',
  },
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
  getters: {
    ...getters,
  },
}
