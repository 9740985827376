
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Avatar',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    shortname() {
      const splited = this.name.split('')
      return splited[0].toUpperCase() + splited[1].toUpperCase()
    },
  },
})
