
import { defineComponent, PropType } from 'vue'
import { Field, ErrorMessage } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
import { isInvalid } from '@/utils'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyStore } from '@/types/partner-property'
import {
  electronicPartnerPropertyFormValidationSchema,
  partnerPropertyMobileFormValidationSchema,
  partnerPropertyPhysicalStoreFormValidationSchema,
} from '@/services/authentication/validations'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import PartnerPropertyTypeSelector from '@/components/common/PartnerPropertyTypeSelector.vue'
// import { GooglePlaceResponse } from '@/types/app'
import PartnerPropertyForm from '@/components/properties/edit-creation-forms/partner-property-form.vue'
import { PartnerPropertyFormOptions } from '@/constants/partner-property'
// import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { PartnerPropertyType } from '@/types/app'

export default defineComponent({
  name: 'CreatePartnerProperty',
  components: {
    Field,
    ErrorMessage,
    PartnerPropertyForm,
    PartnerPropertyTypeSelector,
  },
  props: {
    initialValues: {
      type: Object as PropType<PartnerPropertyStore | null>,
      default: null,
    },
  },
  emits: ['onFinish', 'onCancel'],
  data() {
    return {
      error: false,
      errorMessage: '',
      phoneMaskedValue: this.initialValues?.phone,
      placeValue: this.initialValues?.place || '',
      isNewProperty: !this.initialValues || isEmpty(this.initialValues),
      placeDetails: null as any,
      validPlace: true,
      validPlaceTimeId: 0,
      propertyType: this.initialValues?.partner_property_type,
      testValidation: electronicPartnerPropertyFormValidationSchema(),
    }
  },
  methods: {
    isEmpty,
    isInvalid,
    async onSubmit(values: PartnerPropertyStore) {
      if (
        this.propertyType.form_type ===
        PartnerPropertyFormOptions.PHYSICAL_STORE
      ) {
        if (!values.valid_place) return
      }

      try {
        let action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.CREATE}`
        let data = { ...values, propertyType: this.propertyType }
        if (!this.isNewProperty) {
          action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.UPDATE}`
          data = {
            ...data,
            id: this.initialValues?.id,
            propertyType: this.propertyType,
          }
        }
        await this.$store.dispatch(action, data)
        this.$emit('onFinish')
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    propertyTypeChange(propertyType: PartnerPropertyType) {
      this.propertyType = propertyType
    },
  },
  computed: {
    validationSchema(): any {
      if (
        this.propertyType.form_type ===
        PartnerPropertyFormOptions.ELECTRONIC_PARTNER
      )
        return electronicPartnerPropertyFormValidationSchema()

      if (
        this.propertyType.form_type ===
        PartnerPropertyFormOptions.PHYSICAL_STORE
      )
        return partnerPropertyPhysicalStoreFormValidationSchema()

      if (
        this.propertyType.form_type ===
        PartnerPropertyFormOptions.MOBILE_PARTNER
      )
        return partnerPropertyMobileFormValidationSchema()

      return null
    },
  },
})
