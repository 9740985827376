
import { defineComponent, PropType, reactive, toRefs, watch } from 'vue'
import { CoverageSelection } from '@/types/plan-registration'
import { CoverageType, PlanIntervals } from '@/constants/coverages'
import { DiscountCode } from '@/types/discount-code'
import {
  CoveragesSummaryState,
  CoverageSummaryItem,
} from '@/types/coverages-summary'
import CoveragesSummary from '@/composables/coverages-summary'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'
import ChosenPlanInfo from '@/components/common/ChosenPlanInfo.vue'
import CoveragesCombo from '@/composables/coverages-combo'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import TrashIcon from '@/components/common/icons/Trash-Icon.vue'

export default defineComponent({
  name: 'SummaryCard',
  components: { TrashIcon, ChosenPlanInfo },
  setup(props: {
    coverageSelection: CoverageSelection[]
    discounts: DiscountCode[]
    coverageCombo: CoveragesCombo
  }) {
    const { coverageSelection, discounts } = toRefs(props)
    const summary = reactive<CoveragesSummaryState>({
      annualizedAnnualCoverageCost: 0,
      annualizedMonthlyCoverageCost: 0,
      couponDiscount: null,
      monthlyDiscount: null,
      billed: {
        month: 0,
        year: 0,
      },
      items: [],
    })
    const coveragesSummary = new CoveragesSummary(summary)
    coveragesSummary.calculate(coverageSelection, discounts)

    watch(
      [coverageSelection, discounts],
      () => {
        coveragesSummary.calculate(coverageSelection, discounts)
      },
      { deep: true },
    )
    return {
      summary,
      PlanIntervals,
      CoverageType,
    }
  },
  methods: {
    itemDisplayList(): CoverageSummaryItem[] {
      let toReturn: CoverageSummaryItem[] = []
      this.summary.items.forEach((item) => {
        for (let i = 0; i < item.count; i++) {
          toReturn.push(item)
        }
      })
      return toReturn
    },
    getSummaryItemCaption(summaryItem: CoverageSummaryItem): string {
      const main =
        summaryItem.type == CoverageType.phone
          ? `${summaryItem.phoneMaker} ${summaryItem.phoneModel}`
          : summaryItem.type == CoverageType.AKKO
          ? summaryItem.akkoCoverage
          : summaryItem.coverageName
      const interval =
        summaryItem.interval == PlanIntervals.ANNUAL.toLocaleLowerCase()
          ? '/yr.'
          : '/mo.'
      const priceEach = summaryItem.count > 1 ? ' each' : ''
      return `${this.partnerCurrencySymbol()}${summaryItem.price.toFixed(
        2,
      )} ${interval} - ${main} ${priceEach}`
    },
    getDiscountItemCaption(discount: DiscountCode): string {
      if (discount.amountType == 'PERCENT') {
        return `${discount.amount}% from ${discount.alias} applied`
      }
      return ''
    },
    getDiscountItemAmountCaption(ds: [DiscountCode, number]): string {
      if (ds[0].alias === 'First Month Free') {
        return `${
          ds[0].alias
        } applied (-${this.partnerCurrencySymbol()}${ds[1].toFixed(2)})`
      }

      return `${ds[0].amount}% ${
        ds[0].alias
      } applied (-${this.partnerCurrencySymbol()}${ds[1].toFixed(2)})`
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
    intervalSelected(interval: PlanIntervals): boolean {
      return interval == this.planInterval
    },
    annualTotal(): number {
      let toReturn = this.summary.billed.year
      if (this.summary.monthlyDiscount) {
        this.summary.monthlyDiscount.forEach((value) => {
          toReturn -= value
        })
      }
      if (this.summary.couponDiscount) {
        this.summary.couponDiscount.forEach((value) => {
          toReturn -= value
        })
      }
      return toReturn
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
  props: {
    coverageSelection: {
      type: Array as PropType<CoverageSelection[]>,
      required: true,
    },
    discounts: {
      type: Array as PropType<DiscountCode[]>,
      required: false,
      default: () => [],
    },
    planInterval: {
      type: String as PropType<PlanIntervals>,
      required: true,
    },
    callToActionButtonCaption: {
      type: String,
      required: false,
    },
    setPlanInterval: {
      type: Function,
      required: true,
    },
    coverageCombo: {
      type: Object as PropType<CoveragesCombo>,
      required: true,
    },
  },
  emits: ['callToAction'],
})
