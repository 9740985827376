import {
  Coverage,
  CoverageState,
  CoverageWithDevices,
  DeviceModel,
  DeviceWithCoverages,
} from '@/types/coverage'
import {
  AkkoCoverageType,
  InjuredGadgetsCoverageType,
  PlanIntervals,
  HomeRegularCoverageType,
  HomePlusCoverageType,
} from '@/constants/coverages'

export enum coveragesGetters {
  COVERAGES_WITH_DEVICES = 'coveragesWithDevices',
  DEVICES_WITH_COVERAGES = 'devicesWithCoverages',
  AKKO_COVERAGES = 'akkoCoverages',
  INJURED_GADGETS_COVERAGES = 'injuredGadgetsCoverages',
  HOME_PLUS_COVERAGES = 'homePlusCoverages',
  HOME_REGULAR_COVERAGES = 'homeRegularCoverages',
  COVERAGES = 'coverages',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getters: any = {}

getters[coveragesGetters.COVERAGES_WITH_DEVICES] = function (
  state: CoverageState,
): CoverageWithDevices[] {
  return state.coverages.map((cv: Coverage) => {
    return {
      ...cv,
      devices: state.devices.filter(
        (dv: DeviceModel) =>
          dv.annualCoverageId === cv.id || dv.monthlyCoverageId === cv.id,
      ),
    }
  })
}

getters[coveragesGetters.DEVICES_WITH_COVERAGES] = function (
  state: CoverageState,
): DeviceWithCoverages[] {
  return state.devices.map((dv: DeviceModel) => {
    return {
      ...dv,
      monthlyCoverage: state.coverages.find(
        (cv) => cv.id == dv.monthlyCoverageId,
      ),
      annualCoverage: state.coverages.find(
        (cv) => cv.id == dv.annualCoverageId,
      ),
    }
  })
}

getters[coveragesGetters.AKKO_COVERAGES] = function (state: CoverageState) {
  return (type: AkkoCoverageType): { MONTHLY: Coverage; ANNUAL: Coverage } => {
    const coverages = state.coverages.filter((cv) =>
      cv.tags.find((tag) => tag.includes(type)),
    )
    return {
      [PlanIntervals.ANNUAL]: <Coverage>(
        coverages.find((cv) =>
          cv.tags.find((tag) => tag.includes(PlanIntervals.ANNUAL)),
        )
      ),
      [PlanIntervals.MONTHLY]: <Coverage>(
        coverages.find((cv) =>
          cv.tags.find((tag) => tag.includes(PlanIntervals.MONTHLY)),
        )
      ),
    }
  }
}

getters[coveragesGetters.INJURED_GADGETS_COVERAGES] = function (
  state: CoverageState,
) {
  return (
    type: InjuredGadgetsCoverageType,
  ): { MONTHLY: Coverage; ANNUAL: Coverage } => {
    const coverages = state.coverages.filter((cv) =>
      cv.tags.find(
        (tag) => tag.includes(type) && tag.includes('INJURED_GADGETS'),
      ),
    )
    return {
      [PlanIntervals.ANNUAL]: <Coverage>(
        coverages.find((cv) =>
          cv.tags.find((tag) => tag.includes(PlanIntervals.ANNUAL)),
        )
      ),
      [PlanIntervals.MONTHLY]: <Coverage>(
        coverages.find((cv) =>
          cv.tags.find((tag) => tag.includes(PlanIntervals.MONTHLY)),
        )
      ),
    }
  }
}

getters[coveragesGetters.HOME_PLUS_COVERAGES] = function (
  state: CoverageState,
) {
  return (
    type: HomePlusCoverageType,
  ): { MONTHLY: Coverage; ANNUAL: Coverage } => {
    const coverages = state.coverages.filter((cv) =>
      cv.tags.find((tag) => tag.includes(type)),
    )
    return {
      [PlanIntervals.ANNUAL]: <Coverage>(
        coverages.find((cv) =>
          cv.tags.find((tag) => tag.includes(PlanIntervals.ANNUAL)),
        )
      ),
      [PlanIntervals.MONTHLY]: <Coverage>(
        coverages.find((cv) =>
          cv.tags.find((tag) => tag.includes(PlanIntervals.MONTHLY)),
        )
      ),
    }
  }
}

getters[coveragesGetters.HOME_REGULAR_COVERAGES] = function (
  state: CoverageState,
) {
  return (
    type: HomeRegularCoverageType,
  ): { MONTHLY: Coverage; ANNUAL: Coverage } => {
    const coverages = state.coverages.filter((cv) =>
      cv.tags.find((tag) => tag.includes(type)),
    )
    return {
      [PlanIntervals.ANNUAL]: <Coverage>(
        coverages.find((cv) =>
          cv.tags.find((tag) => tag.includes(PlanIntervals.ANNUAL)),
        )
      ),
      [PlanIntervals.MONTHLY]: <Coverage>(
        coverages.find((cv) =>
          cv.tags.find((tag) => tag.includes(PlanIntervals.MONTHLY)),
        )
      ),
    }
  }
}

getters[coveragesGetters.COVERAGES] = function (
  state: CoverageState,
): Coverage[] {
  return state.coverages
}

export default getters
