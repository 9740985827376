import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    properties: [],
    propertiesSalesAccess: null,
    propertiesNames: {},
    partnerId: null,
    paypalEmail: null,
    repairDeviceTypes: [],
    sellDeviceTypes: [],
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
