import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    filter: null,
    endUsers: null,
    endUsersQuantity: null,
    endUsersByPage: null,
    currentPage: null,
    recordsByPage: null,
    endUserClaimV2Site: null,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
