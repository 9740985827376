
import { defineComponent, reactive, ref } from 'vue'
import isEmpty from 'lodash/isEmpty'
import IndividualPlanSelector from '@/components/registration/IndividualPlanSelector.vue'
import SummaryCard from '@/components/common/SummaryCard.vue'
import Modal from '@/components/common/Modal.vue'
import Checkout from '@/components/registration/Checkout.vue'
import { PlanIntervals, CoverageType } from '@/constants/coverages'
import {
  CoverageSelection,
  CoverageSelectionMap,
} from '@/types/plan-registration'
import { DiscountCode } from '@/types/discount-code'
import { ModulesIdentifiers, key } from '@/store'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import RegistrationSummary from '@/components/registration/RegistrationSummary.vue'
import ForceToCreatePartnerProperties from '@/components/common/ForceToCreatePartnerProperties.vue'
import { useStore } from 'vuex'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerState } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import RequireToAcceptTerms from '@/components/registration/RequireToAcceptTerms.vue'
import { PartnerProgramsIds } from '@/constants/partner-programs'
import { AppGetters } from '@/store/modules/app/getters'
import { UserState } from '@/types/user'
import PartnerStatusAlert from '@/components/registration/PartnerStatusAlert.vue'
import CoveragesCombo from '@/composables/coverages-combo'
import { FeatureFlagGetters } from '@/store/modules/feature-flag/getters'
import { FeatureFlagOptions } from '@/constants/feature-flag'
import PlanFromCatalogSelector from '@/components/registration/PlanFromCatalogSelector.vue'
import ProductSummaryCard from '@/components/common/ProductSummaryCard.vue'
import ProductCheckout from '@/components/registration/ProductCheckout.vue'
import { INDIVIDUAL_PLAN } from '@/constants/products'
import { ProductsMutations } from '@/store/modules/products/mutations'

export default defineComponent({
  name: 'IndividualPlan',
  components: {
    ProductCheckout,
    PlanFromCatalogSelector,
    ProductSummaryCard,
    RequireToAcceptTerms,
    IndividualPlanSelector,
    SummaryCard,
    Checkout,
    Modal,
    RegistrationSummary,
    ForceToCreatePartnerProperties,
    PartnerStatusAlert,
  },
  setup() {
    const store = useStore(key)
    const interval = ref(PlanIntervals.MONTHLY)
    const goToCheckout = ref(false)
    const goToProductCheckout = ref(false)

    store.watch(
      (store) => store.planRegistration.registrationSummary,
      (value, oldValue) => {
        if (!oldValue && value) {
          goToCheckout.value = false
          goToProductCheckout.value = false
        }
      },
    )
    return {
      PlanIntervals,
      goToCheckout,
      interval,
      PartnerProgramsIds,
      goToProductCheckout,
      INDIVIDUAL_PLAN,
    }
  },
  data() {
    return {
      emptyProperty: false,
      coverageCombo: {},
      coverageTypeSelected: '',
    }
  },
  async mounted() {
    const selectionMap = reactive<CoverageSelectionMap>({
      indexer: 0,
      selections: {},
    })
    this.coverageCombo = new CoveragesCombo(selectionMap, this.$store)

    const partnerProperties = this.$store.getters[
      `${ModulesIdentifiers.USER}/${UserGetters.PARTNER_PROPERTIES_WITH_ACCESS}`
    ](`${PermissionScopes.REGISTER_PLAN}:${PermissionValues.ALLOW}`)
    this.emptyProperty = isEmpty(partnerProperties)

    this.$store.commit(
      `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.RESET_PRODUCTS_CART}`,
      null,
    )
  },
  methods: {
    setPlanInterval(newInterval: PlanIntervals): void {
      this.interval = newInterval
    },
    triggerRegistering(): void {
      this.coverageTypeSelected = ''
      this.goToCheckout = true
    },
    cancelRegistration(): void {
      this.goToCheckout = false
    },
    triggerProductRegistering(): void {
      this.goToProductCheckout = true
    },
    cancelProductRegistration(): void {
      this.goToProductCheckout = false
    },
    intervalSelected(interval: PlanIntervals): boolean {
      return interval == this.interval
    },
    addBusinessPlan(type: CoverageType) {
      this.coverageTypeSelected = type
      this.goToCheckout = true
    },
  },
  computed: {
    coverageSelection(): {
      plans: CoverageSelection[]
      discounts: DiscountCode[]
    } {
      return this.$store.state.planRegistration
    },
    registrationSummary(): {
      phoneNumber: string[]
      transactionId: string
    } | null {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.REGISTRATION_SUMMARY}`
      ]
    },

    partnerInformation(): PartnerState {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    partnerProgramId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM_ID}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    forceToCreatePartnerProperties(): boolean {
      return (
        this.emptyProperty &&
        (this.currentUser.termsAccepted ||
          this.partnerProgramId === PartnerProgramsIds.INJURED_GADGETS_ID)
      )
    },
    requireToAcceptTerms(): boolean {
      return (
        !this.currentUser.termsAccepted &&
        this.partnerProgramId !== PartnerProgramsIds.INJURED_GADGETS_ID
      )
    },
    arePartnerCatalogsForIndividualPlanEnabled(): boolean {
      const are_catalog_active = this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_CATALOGS)

      const catalog_is_allow_for_partner =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_CURRENT_PERMISSION_BY_SCOPE}`
        ](PermissionScopes.PARTNER_SALES_CATALOG) === PermissionValues.ALLOW

      const catalog_is_allow_for_partner_for_individual_plan =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_CURRENT_PERMISSION_BY_SCOPE}`
        ](PermissionScopes.PARTNER_SALES_CATALOG_INDIVIDUAL_PLAN) ===
        PermissionValues.ALLOW

      return (
        are_catalog_active &&
        catalog_is_allow_for_partner &&
        catalog_is_allow_for_partner_for_individual_plan
      )
    },
  },
})
