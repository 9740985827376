
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { EndUsersActions } from '@/store/modules/end-users/actions'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { UserGetters } from '@/store/modules/user/getters'
import PayoutCompensationDetail from '@/components/common/compensation/PayoutCompensationDetail.vue'

export default defineComponent({
  name: 'EndUsers',
  components: {
    PayoutCompensationDetail,
  },
  data() {
    return {
      userValues: {},
      emptyProperty: false,
      recordsByPage: 10,
      phoneFilter: '',
    }
  },
  async mounted() {
    await this.$store.dispatch(
      `${ModulesIdentifiers.END_USER}/${EndUsersActions.LIST}`,
    )
    await this.$store.dispatch(
      `${ModulesIdentifiers.END_USER}/${EndUsersActions.PARAMS_PAGE}`,
      { currentPage: 1, recordsByPage: this.recordsByPage },
    )
  },
  computed: {
    canViewCompensationAmount(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_ADMIN_PAYOUT_AMOUNT,
        PermissionValues.VIEW,
      )
    },
  },
})
