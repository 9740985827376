import { ActionContext } from 'vuex'
import axios from 'axios'
import {
  CompensationAmountFilter,
  CompensationState,
  SingleTimePayoutFilter,
  SingleTimePayoutSearch,
} from '@/types/compensation'
import { State as RootState } from '@/types/vuex'
import {
  COMPENSATION_PAYOUT_AMOUNT,
  COMPENSATION_SINGLE_TIME_PAYOUTS,
} from '@/services/api'
import { CompensationMutations } from '@/store/modules/compensation/mutations'
import { parseNetworkErrorMessage } from '@/utils'

export enum CompensationActions {
  GET_COMPENSATIONS_PROPERTIES = 'getCompensationsProperties',
  GET_SINGLE_TIME_PAYOUTS = 'getSingleTimePayouts',
  FILTER_SINGLE_TIME_PAYOUTS = 'filterSingleTimePayouts',
  CLEAN_FILTER_SINGLE_TIME_PAYOUTS = 'CleanFilterSingleTimePayouts',
}

const actions: {
  [key: string]: (
    context: ActionContext<CompensationState, RootState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ) => void
} = {}

actions[CompensationActions.GET_COMPENSATIONS_PROPERTIES] = async (
  context: ActionContext<CompensationState, RootState>,
  filter: CompensationAmountFilter,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const path = `${COMPENSATION_PAYOUT_AMOUNT}?end_date=${filter.endDate}&start_date=${filter.startDate}`
    const response = await axios.get(path)
    const { data } = response

    context.commit(CompensationMutations.SET_COMPENSATIONS, data.data)
    context.commit(CompensationMutations.SET_COMPENSATION_AMOUNT_FILTER, filter)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[CompensationActions.GET_SINGLE_TIME_PAYOUTS] = async (
  context: ActionContext<CompensationState, RootState>,
  filter: SingleTimePayoutFilter,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const basepath = COMPENSATION_SINGLE_TIME_PAYOUTS.replace(
      '{0}',
      context.rootState.partner.partnerId.toString(),
    )
    const path = `${basepath}?month=${filter.month}&year=${filter.year}`
    const response = await axios.get(path)
    const { data } = response
    context.commit(
      CompensationMutations.SET_COMPENSATION_SINGLE_PAYOUTS,
      data.data,
    )
    context.commit(
      CompensationMutations.SET_COMPENSATION_SINGLE_PAYOUTS_FILTER,
      filter,
    )
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[CompensationActions.FILTER_SINGLE_TIME_PAYOUTS] = async (
  context: ActionContext<CompensationState, RootState>,
  singleTimePayoutSearch: SingleTimePayoutSearch,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    context.commit(
      CompensationMutations.FILTER_SINGLE_TIME_PAYOUTS,
      singleTimePayoutSearch,
    )
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[CompensationActions.CLEAN_FILTER_SINGLE_TIME_PAYOUTS] = async (
  context: ActionContext<CompensationState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    context.commit(CompensationMutations.CLEAN_FILTER_SINGLE_TIME_PAYOUTS)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

export default actions
