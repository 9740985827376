
import { defineComponent, PropType } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import CopyText from '@/components/common/CopyText.vue'
import { formatPhone } from '@/utils'
import { PartnerPropertyStore } from '@/types/partner-property'
import { PartnerPropertyFormOptions } from '@/constants/partner-property'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { RepairDeviceType } from '@/types/partner-property'
import { PartnerPrograms } from '@/constants/partner-programs'
import { AppGetters } from '@/store/modules/app/getters'

export default defineComponent({
  name: 'PartnerProperty',
  props: {
    css: {
      type: String,
      default: '',
    },
    store: {
      type: Object as PropType<PartnerPropertyStore>,
      required: true,
    },
    onUpdate: {
      type: Function,
    },
  },
  components: {
    SpinnerLoading,
    CopyText,
  },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  data() {
    return {
      edition: false,
      deletion: false,
      loading: false,
      error: false,
      errorMessage: '',
    }
  },
  methods: {
    formatPhone,
    getDeviceName(id: number): string {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_REPAIR_DEVICE_NAME}`
      return this.$store.getters[key](id)
    },
    getSellDeviceName(id: number): string {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_SELL_DEVICE_NAME}`
      return this.$store.getters[key](id)
    },
    getSellCellServiceName(id: number): string {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_CELLULAR_SERVICE_NAME}`
      return this.$store.getters[key](id)
    },
    showComma(index: number, length: number): string {
      return index < length - 1 ? ',' : ''
    },
    onToggleDelete(onDelete: boolean) {
      this.deletion = onDelete
    },
    async onConfirmDelete(id: number) {
      this.loading = true
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.DELETE}`,
          id,
        )
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
  },
  computed: {
    isPhysicalStore(): boolean {
      return (
        this.store.partner_property_type.form_type ===
        PartnerPropertyFormOptions.PHYSICAL_STORE
      )
    },
    isMobileProperty(): boolean {
      return (
        this.store.partner_property_type.form_type ===
        PartnerPropertyFormOptions.MOBILE_PARTNER
      )
    },
    isElectronicProperty(): boolean {
      return (
        this.store.partner_property_type.form_type ===
        PartnerPropertyFormOptions.ELECTRONIC_PARTNER
      )
    },
    repairDeviceTypeOptions(): RepairDeviceType[] {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_REPAIR_DEVICES}`
      return this.$store.getters[key]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
  },
})
