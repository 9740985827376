import { State } from '@/types/vuex'
import { ModulesIdentifiers } from '@/store'
import { ProductsActions } from '@/store/modules/products/actions'
import { Store } from 'vuex'
import { ProductsMutations } from '@/store/modules/products/mutations'

export const loadProducts = async (store: Store<State>): Promise<void> => {
  store.commit(
    `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.RESET_PRODUCT_CATALOG}`,
  )
  await store.dispatch(
    `${ModulesIdentifiers.PRODUCTS}/${ProductsActions.LOAD_PARTNER_CATALOGS}`,
  )
  await store.dispatch(
    `${ModulesIdentifiers.PRODUCTS}/${ProductsActions.LOAD_INTERVALS}`,
  )
  await store.dispatch(
    `${ModulesIdentifiers.PRODUCTS}/${ProductsActions.LOAD_CATALOG_PRODUCTS}`,
  )
}
