export enum SelectorOptions {
  ALL = 'all',
}

export enum PartnerPropertyFormOptions {
  PHYSICAL_STORE = 'PHYSICAL_STORE',
  MOBILE_PARTNER = 'MOBILE_PARTNER',
  ELECTRONIC_PARTNER = 'ELECTRONIC_PARTNER',
}

export const OTHER_TYPE = 'other'
