<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25.7555" cy="25.7555" r="25.7555" fill="#E8E7E5" />
    <rect
      x="15.6831"
      y="11.3203"
      width="20.4863"
      height="27.9979"
      fill="white"
    />
    <path
      d="M17.4471 18.4678C17.5744 18.4678 17.6967 18.4189 17.7946 18.3259L19.7522 16.3683C19.9431 16.1775 19.9431 15.8692 19.7522 15.6783C19.5613 15.4874 19.253 15.4874 19.0621 15.6783L17.1046 17.6359C16.9137 17.8267 16.9137 18.1351 17.1046 18.3259C17.1976 18.4189 17.3199 18.4678 17.4471 18.4678Z"
      fill="#060606"
      stroke="white"
      stroke-width="0.7"
    />
    <path
      d="M17.4471 20.9148C17.5744 20.9148 17.6967 20.8659 17.7946 20.7729L18.7734 19.7941C18.9643 19.6033 18.9643 19.2949 18.7734 19.1041C18.5825 18.9132 18.2742 18.9132 18.0834 19.1041L17.1046 20.0829C16.9137 20.2737 16.9137 20.5821 17.1046 20.7729C17.1976 20.8659 17.3199 20.9148 17.4471 20.9148Z"
      fill="#060606"
      stroke="white"
      stroke-width="0.7"
    />
    <path
      d="M19.057 18.1202C18.9689 18.2132 18.915 18.3404 18.915 18.4677C18.915 18.5949 18.9689 18.7221 19.057 18.8151C19.1499 18.9032 19.2772 18.957 19.4044 18.957C19.5317 18.957 19.6589 18.9032 19.7519 18.8151C19.84 18.7221 19.8938 18.5949 19.8938 18.4677C19.8938 18.3404 19.84 18.2132 19.7519 18.1202C19.5708 17.9391 19.2429 17.9391 19.057 18.1202Z"
      fill="#060606"
      stroke="white"
      stroke-width="0.7"
    />
    <path
      d="M20.0356 17.8365C20.1335 17.9295 20.2558 17.9785 20.3831 17.9785C20.5103 17.9785 20.6327 17.9295 20.7305 17.8365L22.1987 16.3683C22.3896 16.1775 22.3896 15.8692 22.1987 15.6783C22.0079 15.4874 21.6995 15.4874 21.5087 15.6783L20.0405 17.1465C19.8447 17.3325 19.8447 17.6457 20.0356 17.8365Z"
      fill="#060606"
      stroke="white"
      stroke-width="0.7"
    />
    <path
      d="M21.5037 18.1212L17.0992 22.5257C16.9083 22.7166 16.9083 23.0249 17.0992 23.2158C17.1971 23.3137 17.3194 23.3626 17.4467 23.3626C17.5739 23.3626 17.6963 23.3137 17.7941 23.2207L22.1987 18.8161C22.3896 18.6253 22.3896 18.317 22.1987 18.1261C22.0078 17.9352 21.6946 17.9303 21.5037 18.1212Z"
      fill="#060606"
      stroke="white"
      stroke-width="0.7"
    />
    <path
      d="M22.4827 17.1436C22.3947 17.2366 22.3408 17.3638 22.3408 17.4911C22.3408 17.6183 22.3947 17.7456 22.4827 17.8386C22.5757 17.9266 22.703 17.9805 22.8302 17.9805C22.9575 17.9805 23.0847 17.9266 23.1777 17.8386C23.2658 17.7456 23.3196 17.6183 23.3196 17.4911C23.3196 17.3638 23.2658 17.2366 23.1777 17.1436C22.9917 16.9625 22.6638 16.9625 22.4827 17.1436Z"
      fill="#060606"
      stroke="white"
      stroke-width="0.7"
    />
    <path
      d="M23.9504 15.672L23.461 16.1614C23.2702 16.3522 23.2702 16.6606 23.461 16.8514C23.5589 16.9493 23.6812 16.9982 23.8085 16.9982C23.9357 16.9982 24.0581 16.9493 24.156 16.8563L24.6454 16.3669C24.8362 16.1761 24.8362 15.8677 24.6454 15.6769C24.4545 15.486 24.1413 15.4811 23.9504 15.672Z"
      fill="#060606"
      stroke="white"
      stroke-width="0.7"
    />
    <path
      d="M26.7454 12.1055H26.256C25.9868 12.1055 25.7666 12.3257 25.7666 12.5949C25.7666 12.864 25.9868 13.0843 26.256 13.0843H26.7454C27.0146 13.0843 27.2348 12.864 27.2348 12.5949C27.2348 12.3257 27.0146 12.1055 26.7454 12.1055Z"
      fill="#060606"
      stroke="#E8E7E5"
      stroke-width="0.3"
    />
    <path
      d="M34.6247 10.6367H16.9086C15.8564 10.6367 15 11.5078 15 12.5747V13.5731V36.0852V38.0624C15 39.1292 15.8564 40.0004 16.9086 40.0004H34.6247C35.6769 40.0004 36.5333 39.1292 36.5333 38.0624V36.0852V13.5731V12.5747C36.5333 11.5078 35.6769 10.6367 34.6247 10.6367ZM26.2561 38.5322C25.7177 38.5322 25.2773 38.0917 25.2773 37.5534C25.2773 37.0151 25.7177 36.5746 26.2561 36.5746C26.7944 36.5746 27.2348 37.0151 27.2348 37.5534C27.2348 38.0917 26.7944 38.5322 26.2561 38.5322ZM15.9788 14.5519H35.5545V35.1064H15.9788V14.5519ZM15.9788 38.0624V36.0852H24.9738C24.5628 36.4425 24.2985 36.9661 24.2985 37.5534C24.2985 38.1407 24.5628 38.6643 24.9738 39.0216H16.9086C16.3948 39.0216 15.9788 38.5909 15.9788 38.0624ZM35.5545 38.0624C35.5545 38.5909 35.1386 39.0216 34.6247 39.0216H27.5383C27.9494 38.6643 28.2136 38.1407 28.2136 37.5534C28.2136 36.9661 27.9494 36.4425 27.5383 36.0852H35.5545V38.0624ZM15.9788 13.5731V12.5747C15.9788 12.0462 16.3948 11.6155 16.9086 11.6155H34.6247C35.1386 11.6155 35.5545 12.0462 35.5545 12.5747V13.5731H15.9788Z"
      fill="#060606"
      stroke="#E8E7E5"
      stroke-width="0.3"
    />
    <circle cx="42.1143" cy="39.1143" r="8.11426" fill="#F3103D" />
    <path
      d="M38.5459 39.1172H45.6864"
      stroke="white"
      stroke-width="1.7"
      stroke-linecap="round"
    />
    <path
      d="M42.1172 35.5469V42.6874"
      stroke="white"
      stroke-width="1.7"
      stroke-linecap="round"
    />
  </svg>
</template>
