
import CurrencySelector from '@/components/common/CurrencySelector.vue'
import { defineComponent, PropType } from 'vue'
import { Currencies } from '@/constants/currencies'
import DistanceUnitSelector from '@/components/common/DistanceUnitSelector.vue'
import { DistanceUnits } from '@/constants/distance-units'

export default defineComponent({
  name: 'MoneyAmount',
  components: { DistanceUnitSelector },
  setup() {
    return {
      DistanceUnits,
    }
  },
  emits: ['onChange'],
  props: {
    cssClasses: {
      type: String as PropType<string>,
      default: '',
    },
    value: {
      type: Number as PropType<number>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value: number) {
        this.$emit('onChange', value)
      },
    },
  },
})
