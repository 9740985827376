import {
  EndUser,
  EndUserSearch,
  EndUserState,
  ParamsPage,
} from '@/types/end-user'
import { CLAIM_V2_SITE } from '@/services/api'

export enum EndUsersMutations {
  LIST = 'list',
  PARAMS_PAGE = 'params_page',
  CURRENT_PAGE = 'current_page',
  FILTER = 'filter',
  CLEAN_FITLER = 'clean_filter',
  DELETE = 'delete',
  AUTH_CLAIM_V2_SITE = 'auth_claim_v2_site',
  CLEAN_AUTH_CLAIM_V2_SITE = 'clean_auth_claim_v2_site',
}

const mutations: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: EndUserState, newData: any) => void
} = {}

mutations[EndUsersMutations.LIST] = (
  state: EndUserState,
  data: EndUser[],
): void => {
  state.endUsers = [...data]
  state.endUsersQuantity = state.endUsers.length
  state.endUsersFilter = [...data]
  state.endUsersFilterQuantity = state.endUsersFilter.length
}

mutations[EndUsersMutations.PARAMS_PAGE] = (
  state: EndUserState,
  paramsPage: ParamsPage,
): void => {
  state.recordsByPage = paramsPage.recordsByPage
  state.maxPage = calculateMaxPage(
    state.recordsByPage,
    state.endUsersFilterQuantity,
  )

  calculateAndSetParamsPage(state, paramsPage.currentPage)
  state.endUsersByPage = getEndUsersCurrentPage(state)
}

mutations[EndUsersMutations.FILTER] = (
  state: EndUserState,
  endUserSearch: EndUserSearch,
): void => {
  state.endUsersFilter = state.endUsers.filter((el) => {
    if (!endUserSearch.phone && !endUserSearch.serial_number) {
      return true
    }
    let serialNumberFound = false
    if (endUserSearch.serial_number) {
      el.devices.forEach((device) => {
        serialNumberFound =
          device.device_serial_number.indexOf(endUserSearch.serial_number) >= 0
            ? true
            : serialNumberFound
      })
    }
    if (endUserSearch.phone && endUserSearch.serial_number) {
      return el.phone.indexOf(endUserSearch.phone) >= 0 && serialNumberFound
    } else if (endUserSearch.phone) {
      return el.phone.indexOf(endUserSearch.phone) >= 0
    }
    return serialNumberFound
  })
  state.endUsersFilterQuantity = state.endUsersFilter.length

  state.maxPage = calculateMaxPage(
    state.recordsByPage,
    state.endUsersFilterQuantity,
  )

  calculateAndSetParamsPage(state, 1)
  state.endUsersByPage = getEndUsersCurrentPage(state)
}

mutations[EndUsersMutations.CLEAN_FITLER] = (state: EndUserState): void => {
  state.endUsersFilter = [...state.endUsers]
  state.endUsersFilterQuantity = state.endUsersFilter.length

  state.maxPage = calculateMaxPage(
    state.recordsByPage,
    state.endUsersFilterQuantity,
  )

  calculateAndSetParamsPage(state, 1)
  state.endUsersByPage = getEndUsersCurrentPage(state)
}

mutations[EndUsersMutations.CURRENT_PAGE] = (
  state: EndUserState,
  current_page: number,
): void => {
  calculateAndSetParamsPage(state, current_page)
  state.endUsersByPage = getEndUsersCurrentPage(state)
}

const calculateMaxPage = (recordsByPage: number, endUsersQuantity: number) => {
  const q = endUsersQuantity / recordsByPage
  const r = endUsersQuantity % recordsByPage
  return r == 0 ? q : q + 1
}

const getEndUsersCurrentPage = (state: EndUserState) => {
  if (state.endUsersFilter) {
    if (
      state.currentPage * state.recordsByPage <=
      state.endUsersFilterQuantity
    ) {
      return state.endUsersFilter.slice(
        (state.currentPage - 1) * state.recordsByPage,
        state.currentPage * state.recordsByPage,
      )
    } else {
      return state.endUsersFilter.slice(
        (state.currentPage - 1) * state.recordsByPage,
      )
    }
  }
  return []
}

const calculateAndSetParamsPage = (
  state: EndUserState,
  pageSelected: number,
) => {
  state.currentPage = pageSelected
  if (state.currentPage > 1) {
    state.firstPage = state.currentPage - 1
    state.secondPage = state.currentPage
    state.thirdPage = state.currentPage + 1
    state.notExistPrevious = false
  } else {
    state.firstPage = 1
    state.secondPage = 2
    state.thirdPage = 3
    state.notExistPrevious = true
  }
  if (state.currentPage < state.maxPage) {
    state.notExistNext = false
  } else {
    state.notExistNext = true
  }
  if (state.secondPage <= state.maxPage) {
    state.existSecondPage = true
  } else {
    state.existSecondPage = false
  }
  if (state.thirdPage <= state.maxPage) {
    state.existThirdPage = true
    state.notExistNext = false
  } else {
    state.existThirdPage = false
    state.notExistNext = true
  }
}

mutations[EndUsersMutations.DELETE] = (
  state: EndUserState,
  id: number,
): void => {
  state.endUsers = state.endUsers.filter((user: EndUser) => user.id !== id)
}

mutations[EndUsersMutations.AUTH_CLAIM_V2_SITE] = (
  state: EndUserState,
  token: string,
): void => {
  state.endUserClaimV2Site = `${CLAIM_V2_SITE}${token}`
}

mutations[EndUsersMutations.CLEAN_AUTH_CLAIM_V2_SITE] = (
  state: EndUserState,
): void => {
  state.endUserClaimV2Site = null
}

export default mutations
