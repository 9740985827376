import { DateTime } from 'luxon'
import { CUT_DAY } from '@/constants/compensations'

export function getNextMonth(): DateTime {
  let month = DateTime.now()
  const day = month.day
  if (day > CUT_DAY) {
    month = month.plus({ months: 1 })
  }
  return month
}

export function getPreviousMonth(): DateTime {
  let month = DateTime.now()
  const day = month.day
  if (day <= CUT_DAY) {
    month = month.minus({ months: 2 })
  } else {
    month = month.minus({ months: 1 })
  }
  return month
}
