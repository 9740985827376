
import { defineComponent } from 'vue'
import { ClaimDeviceDeductible } from '@/types/claim'
import { ModulesIdentifiers } from '@/store'
import { ClaimGetters } from '@/store/modules/claim/getters'
import ClaimsInstructionsApproved from '@/components/claim/ClaimsInstructionsApproved.vue'

export default defineComponent({
  name: 'ClaimsInstructionsByDevice',
  components: {
    ClaimsInstructionsApproved,
  },
  computed: {
    claimDeductibles(): ClaimDeviceDeductible[] {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.DEDUCTIBLES}`
      ]
    },
    claimReplacementCost(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.REPLACEMENT_COST}`
      ]
    },
    claimScreenReplacementDeductible(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.SCREEN_REPLACEMENT_DEDUCTIBLE}`
      ]
    },
    claimRepairDeductible(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.REPAIR_DEDUCTIBLE}`
      ]
    },
    claimReplacementDeductible(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.REPLACEMENT_DEDUCTIBLE}`
      ]
    },
    claimCoverageDeductible(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.COVERAGE_DEDUCTIBLE}`
      ]
    },
  },
})
