import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    class: _normalizeClass(_ctx.cssClasses),
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitMonthSelection && _ctx.emitMonthSelection(...args))),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selection = $event))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownValues, (value) => {
      return (_openBlock(), _createElementBlock("option", {
        value: value,
        key: value
      }, _toDisplayString(_ctx.formatValue(value)), 9, _hoisted_1))
    }), 128))
  ], 34)), [
    [_vModelSelect, _ctx.selection]
  ])
}