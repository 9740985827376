
import { defineComponent } from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { ModulesIdentifiers } from '@/store'
import AuthLayout from './Layout.vue'
import { UserActions } from '@/store/modules/user/actions'
import { UserGetters } from '@/store/modules/user/getters'
import { LoginFormFields } from '@/types/user'
import { loginValidationSchema } from '@/services/authentication/validations'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { RouteNames, RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'Login',
  components: {
    AuthLayout,
    Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
  },
  setup() {
    return {
      RoutePath,
    }
  },
  data() {
    return {
      validationSchema: loginValidationSchema(),
      error: false,
      errorMesage: '',
      phoneMaskedValue: '',
      initialValues: {
        phone: '',
        password: '',
      },
    }
  },
  mounted() {
    if (this.hasToken) this.$router.push('/')
  },
  methods: {
    isInvalid(errors: { [key: string]: string }, name: string): string {
      return errors[name] ? 'is-invalid' : ''
    },
    async onSubmit(values: LoginFormFields): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.LOGIN_USER}`,
          values,
        )
        this.$router.push({ name: RouteNames.HOME_MENU })
      } catch (error) {
        this.error = true
        this.errorMesage = error
      }
    },
  },
  computed: {
    hasToken(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER_TOKEN}`
      ]
    },
  },
})
