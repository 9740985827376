
import { defineComponent } from 'vue'
import { RouteNames, RoutePath } from '@/constants/router'
import isEmpty from 'lodash/isEmpty'
import QuickActions from '@/components/dashboard/QuickActions.vue'
import SalesOverview from '@/components/dashboard/SalesOverview.vue'
import { showChat } from '@/services/intercom'
import { PartnerPrograms } from '@/constants/partner-programs'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { ReportActions } from '@/store/modules/report/actions'
import { SalesOverview as SalesOverviewType } from '@/types/report'
import { ReportGetters } from '@/store/modules/report/getters'
import PayoutCompensationBody from '@/components/common/compensation/PayoutCompensationBody.vue'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { ENROLLMENT_FILE_DASHBOARD_URL } from '@/constants/retool'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { FeatureFlagGetters } from '@/store/modules/feature-flag/getters'
import { FeatureFlagOptions } from '@/constants/feature-flag'
import { PartnerIFrames } from '@/constants/partner-iframes'

export default defineComponent({
  name: 'Dashboard',
  components: {
    QuickActions,
    SalesOverview,
    PayoutCompensationBody,
  },
  setup() {
    return {
      RoutePath,
      PartnerPrograms,
    }
  },
  mounted() {
    if (isEmpty(this.salesOverview)) {
      this.$store.dispatch(
        `${ModulesIdentifiers.REPORT}/${ReportActions.SALES_OVERVIEW}`,
      )
    }
  },
  computed: {
    isHomePage(): boolean {
      return this.$router.currentRoute.value.name == RouteNames.HOME_MENU
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    salesOverview(): SalesOverviewType[] {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.SALES_OVERVIEW}`
      return this.$store.getters[key]
    },
    canViewCompensationAmount(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_ADMIN_PAYOUT_AMOUNT,
        PermissionValues.VIEW,
      )
    },
    userToken(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER_TOKEN_VALUE}`
      ]
    },
    partnerId() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_ID}`
      ]
    },
    canEnrollUsingFile() {
      const has_csv_create_user_permission = this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_CSV_CREATE_USER, PermissionValues.ALLOW)

      const has_csv_update_user_permission = this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_CSV_UPDATE_USER, PermissionValues.ALLOW)

      return has_csv_create_user_permission || has_csv_update_user_permission
    },
    fileUploadDashboardSrcUrl(): string {
      const partner_id = this.partnerId
      const url =
        ENROLLMENT_FILE_DASHBOARD_URL +
        `?accesstoken=${this.userToken}&p_id=${partner_id}`
      return url
    },
    isEnableDashboardIframeFeature(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_PORTAL_DASHBOARD_IFRAME)
    },
    iFrameDashboard(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_IFRAME_URL}`
      ](PartnerIFrames.TAB_CODE_DASHBOARD)
    },
    hasPartnerDashboardViewIFramePermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_DASHBOARD_VIEW_IFRAME, PermissionValues.ALLOW)
    },
  },
  methods: {
    showIntercomChat(): void {
      showChat()
    },
  },
})
