
import { defineComponent, PropType } from 'vue'
import { ProductGroup } from '@/types/product'
import { ModulesIdentifiers } from '@/store'
import { ProductGetters } from '@/store/modules/products/getters'
import ProductSelect from '@/components/common/ProductSelect.vue'
import { ProductsMutations } from '@/store/modules/products/mutations'

export default defineComponent({
  name: 'IndividualPlanFromCatalogSelector',
  components: { ProductSelect },
  props: {
    planCategory: {
      type: String as PropType<string>,
    },
  },
  computed: {
    productGroups(): ProductGroup[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PLANS_FROM_CATALOG_FILTERED_BY_CATEGORY}`
      ](this.planCategory)
    },
    areTheyProductsInCart() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.ARE_THEY_PRODUCTS_IN_CART}`
      ]
    },
  },
  methods: {
    doesOtherGroupHaveProductsInCart(groupName: string) {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.DOES_OTHER_PRODUCT_GROUPS_HAVE_PRODUCTS_IN_CART}`
      ](groupName)
    },
    addCurrentProductGroupInCard() {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.ADD_CURRENT_PRODUCT_TO_CART}`,
      )
    },
  },
})
