
import { defineComponent, ref } from 'vue'
import isEmpty from 'lodash/isEmpty'
import MultiBar from '../common/charts/MultiBar.vue'
import AkkoCard from '@/components/common/AkkoCard.vue'
import { MultiBarPayload, SalesAmountReport } from '@/types/sales'
import { Report } from '@/composables/reports'
import { key, ModulesIdentifiers } from '@/store'
import { useStore } from 'vuex'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import LastTwelveMonthsSelector from '@/components/common/LastTwelveMonthsSelector.vue'
import { ReportGetters } from '@/store/modules/report/getters'
import { FilterReport, ReportDataTemplate } from '@/types/report'
import { RoutePath } from '@/constants/router'
import { ReportMutations } from '@/store/modules/report/mutations'
import { RouteNames } from '@/constants/router'

export default defineComponent({
  name: 'Report',
  components: {
    MultiBar,
    AkkoCard,
    LastTwelveMonthsSelector,
  },
  data() {
    return {
      partnerPropertyId: '' as string,
      loading: true as boolean,
      monthSelection: null as unknown,
    }
  },
  setup() {
    const store = useStore(key)
    const report: Report = new Report(store)
    const proceedToRegisterNewCustomer = ref(true)

    return {
      proceedToRegisterNewCustomer,
      report,
      PermissionScopes,
      PermissionValues,
      RoutePath,
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(
      this.report.onReportSalesStateChange,
    )
  },
  mounted() {
    this.setTabReport()
    if (isEmpty(this.getReports)) {
      this.report.dispatchRequestReportSales(this.getFilterParams())
    }
  },
  unmounted() {
    this.unsubscribe()
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    unsubscribe: () => {},
    handleChangeOnMonthSelection(param: { month: number; year: number }) {
      this.monthSelection = param
      const filterParams = this.getFilterParams()
      const params = {
        ...filterParams,
        month: param.month,
        year: param.year,
      }
      this.report.dispatchRequestReportSalesCustomRange(params)
    },
    getFilterParams() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const params: any = {}
      const { partnerProperties, salesRepresentatives, tabSelected } =
        this.filters
      if (!isEmpty(partnerProperties)) {
        params['partner_properties'] = partnerProperties
          .map((v: any) => v.value)
          .join(',')
      }
      if (!isEmpty(salesRepresentatives)) {
        params['sales_representatives'] = salesRepresentatives
          .map((v: any) => v.value)
          .join(',')
      }
      if (tabSelected) {
        params['tab_selected'] = tabSelected
      }
      return params
    },
    setTabReport(): void {
      this.$store.commit(
        `${ModulesIdentifiers.REPORT}/${ReportMutations.SET_FILTER}`,
        { tabSelected: RouteNames.HOME_SALES_REPORT },
      )
    },
  },
  computed: {
    getLast7days(): MultiBarPayload {
      return this.report.getLast7days()
    },
    getLast30days(): MultiBarPayload {
      return this.report.getLast30days()
    },
    getLastmonth(): MultiBarPayload {
      return this.report.getLastmonth()
    },
    getCustomMonth(): MultiBarPayload {
      return this.report.getCustomMonth()
    },
    getToday(): MultiBarPayload {
      return this.report.getToday()
    },
    getTotal(): SalesAmountReport {
      return this.report.getTotal()
    },
    getReports(): ReportDataTemplate {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_REPORTS}`
      return this.$store.getters[key]
    },
    filters(): FilterReport {
      const filters =
        this.$store.getters[
          `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_FILTER}`
        ]
      return filters
    },
  },
})
