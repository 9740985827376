
import { defineComponent } from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { SignupAndClaimFormFields, SignupFormFields } from '@/types/user'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import AuthLayout from './Layout.vue'
import { signupAndPartnerClaimValidationSchema } from '@/services/authentication/validations'
import { PartnerActions } from '@/store/modules/partner/actions'
import { RouteNames } from '@/constants/router'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'

export default defineComponent({
  name: 'Signup',
  components: {
    AuthLayout,
    Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
  },
  data() {
    return {
      validationSchema: signupAndPartnerClaimValidationSchema(),
      error: false,
      errorMesage: '',
      phoneMaskedValue: '',
    }
  },
  methods: {
    isInvalid(errors: { [key: string]: string }, name: string): string {
      return errors[name] ? 'is-invalid' : ''
    },
    async onSubmit(values: SignupAndClaimFormFields): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.SIGNUP_USER_AND_CLAIM_PARTNER}`,
          Object.assign({}, values, { partner_id: Number(values.partner_id) }),
        )
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER}/${PartnerActions.READ_PARTNER}`,
          values,
        )
        this.$router.push({ name: RouteNames.HOME_MENU })
      } catch (error) {
        this.error = true
        this.errorMesage = error
      }
    },
  },
})
