import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-49304b34")
const _hoisted_1 = { class: "d-flex" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurrencySelector = _resolveComponent("CurrencySelector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(["form-control", _ctx.cssClasses]),
      type: "number",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputValue = $event))
    }, null, 2), [
      [_vModelText, _ctx.inputValue]
    ]),
    _createVNode(_component_CurrencySelector, {
      disabled: true,
      "default-value": _ctx.Currencies.USD
    }, null, 8, ["default-value"])
  ]))
}