import {
  ReportState,
  ReportDataTemplateMultiBar,
  ReportDataTemplateAmount,
  FilterReport,
  SalesOverview,
} from '@/types/report.d'

export enum ReportMutations {
  SET_FILTER = 'setFilter',
  SET_REPORTS = 'setReports',
  SET_SALES_OVERVIEW = 'setSalesOverview',
}

const mutations: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: ReportState, newData: any) => void
} = {}

mutations[ReportMutations.SET_FILTER] = (
  state: ReportState,
  newFilter: FilterReport,
): void => {
  state.filter = { ...state.filter, ...newFilter }
}

mutations[ReportMutations.SET_REPORTS] = (
  state: ReportState,
  newReports: ReportDataTemplateMultiBar | ReportDataTemplateAmount,
): void => {
  state.reports = { ...state.reports, ...newReports }
}

mutations[ReportMutations.SET_SALES_OVERVIEW] = (
  state: ReportState,
  data: SalesOverview[],
): void => {
  state.salesOverview = [...data]
}

export default mutations
