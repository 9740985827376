
import AuthLayout from '@/components/authentication/Layout.vue'
import InputMask from 'primevue/inputmask'
import { ErrorMessage, Field, Form } from 'vee-validate'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { resetPasswordValidationSchema } from '@/services/authentication/validations'
import { ResetPasswordFields } from '@/types/user.d'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import { UserGetters } from '@/store/modules/user/getters'
import { defineComponent } from 'vue'
import { RoutePath } from '@/constants/router'
import { isInvalid } from '@/utils'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    AuthLayout,
    Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
  },
  data() {
    return {
      validationSchema: resetPasswordValidationSchema(),
      error: false,
      errorMessage: '',
      phoneMaskedValue: '',
      initialValues: {
        phone: '',
      },
    }
  },
  mounted() {
    if (this.hasToken) this.$router.push('/')
  },
  methods: {
    isInvalid,
    async onSubmit(values: ResetPasswordFields): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.RESET_PASSWORD}`,
          values.phone,
        )
        this.$router.push(RoutePath.LOGIN)
      } catch (error) {
        this.error = true
        this.errorMessage = 'Error requesting password reset'
      }
    },
  },
  computed: {
    hasToken(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER_TOKEN}`
      ]
    },
  },
})
