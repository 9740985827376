import { FeatureFlagState, FeatureFlagStore } from '@/types/feature-flag'

export enum FeatureFlagGetters {
  GET_FEATURE_FLAGS_ACCESS = 'getFeatureFlagsAccess',
  CHECK_FEATURE_FLAG_ENABLED = 'checkFeatureFlagEnabled',
}

const getters: { [key: string]: (state: FeatureFlagState) => void } = {}

getters[FeatureFlagGetters.GET_FEATURE_FLAGS_ACCESS] = (
  state: FeatureFlagState,
): FeatureFlagStore[] => {
  return state.feature_flags
}

getters[FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED] =
  (state: FeatureFlagState) =>
  (feature: string): boolean => {
    const feature_flag = state.feature_flags.find((f) => f.feature === feature)
    if (feature_flag) return feature_flag.state
    return false
  }

export default getters
