
import { defineComponent } from 'vue'
import { RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'ForceToCreatePartnerProperties',
  methods: {
    goToPropertiesPage() {
      this.$router.push(RoutePath.HOME_PROPERTIES)
    },
  },
})
