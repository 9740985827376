import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-5 col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PayoutCompensationTitle = _resolveComponent("PayoutCompensationTitle")!
  const _component_PayoutCompensationBody = _resolveComponent("PayoutCompensationBody")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PayoutCompensationTitle)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_PayoutCompensationBody, {
          cssContainer: _ctx.cssContainer,
          cssBody: _ctx.cssBody
        }, null, 8, ["cssContainer", "cssBody"])
      ])
    ])
  ], 64))
}