import { createStore, Store } from 'vuex'
import AppModule from '@/store/modules/app'
import SalesModule from '@/store/modules/sales'
import UserModule from '@/store/modules/user'
import ReportModule from '@/store/modules/report'
import PartnerPropertyModule from '@/store/modules/partner-property'
import PartnerModule from '@/store/modules/partner'
import TeamModule from '@/store/modules/team'
import CoveragesModule from '@/store/modules/coverages'
import PlanRegistrationModule from '@/store/modules/plan-registration'
import AccessAdministration from '@/store/modules/access-administration'
import ClaimsModule from '@/store/modules/claim'
import CompensationsModule from '@/store/modules/compensation'
import EndUserModule from '@/store/modules/end-users'
import FeatureFlagModule from '@/store/modules/feature-flag'
import ProductsModule from '@/store/modules/products'
import { BusinessLogicEngine } from '@/store/business-logic-engine'
import { InjectionKey } from 'vue'
import { State } from '@/types/vuex'

export enum ModulesIdentifiers {
  APP = 'app',
  CLAIMS = 'claims',
  SALES = 'sales',
  COVERAGES = 'coverages',
  USER = 'user',
  PLAN_REGISTRATION = 'planRegistration',
  REPORT = 'report',
  ACCESS_ADMINISTRATION = 'accessAdministration',
  PARTNER_PROPERTY = 'partnerProperty',
  PARTNER = 'partner',
  TEAM = 'team',
  COMPENSATION = 'compensation',
  END_USER = 'endUser',
  FEATURE_FLAG = 'featureFlag',
  PRODUCTS = 'products',
}

export const key: InjectionKey<Store<State>> = Symbol('partner portal store')

export default createStore<State>({
  strict: true,
  mutations: {},
  actions: {},
  modules: {
    app: {
      namespaced: true,
      ...AppModule,
    },
    [ModulesIdentifiers.SALES]: {
      namespaced: true,
      ...SalesModule,
    },
    [ModulesIdentifiers.COVERAGES]: {
      namespaced: true,
      ...CoveragesModule,
    },
    [ModulesIdentifiers.USER]: {
      namespaced: true,
      ...UserModule,
    },
    [ModulesIdentifiers.PLAN_REGISTRATION]: {
      namespaced: true,
      ...PlanRegistrationModule,
    },
    [ModulesIdentifiers.REPORT]: {
      namespaced: true,
      ...ReportModule,
    },
    [ModulesIdentifiers.ACCESS_ADMINISTRATION]: {
      namespaced: true,
      ...AccessAdministration,
    },
    [ModulesIdentifiers.PARTNER_PROPERTY]: {
      namespaced: true,
      ...PartnerPropertyModule,
    },
    [ModulesIdentifiers.PARTNER]: {
      namespaced: true,
      ...PartnerModule,
    },
    [ModulesIdentifiers.TEAM]: {
      namespaced: true,
      ...TeamModule,
    },
    [ModulesIdentifiers.CLAIMS]: {
      namespaced: true,
      ...ClaimsModule,
    },
    [ModulesIdentifiers.COMPENSATION]: {
      namespaced: true,
      ...CompensationsModule,
    },
    [ModulesIdentifiers.END_USER]: {
      namespaced: true,
      ...EndUserModule,
    },
    [ModulesIdentifiers.FEATURE_FLAG]: {
      namespaced: true,
      ...FeatureFlagModule,
    },
    [ModulesIdentifiers.PRODUCTS]: {
      namespaced: true,
      ...ProductsModule,
    },
  },
  plugins: [BusinessLogicEngine],
})
