
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { ModulesIdentifiers } from '@/store'
import { UserProperties } from '@/constants/access-administration'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { partnerPropertyCreateUserFormValidationSchema } from '@/services/authentication/validations'
import { isInvalid } from '@/utils'
import isEmpty from 'lodash/isEmpty'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import InputMask from 'primevue/inputmask'
import { TeamActions } from '@/store/modules/team/actions'
import { TeamMutations } from '@/store/modules/team/mutations'
import { TeamGetters } from '@/store/modules/team/getters'
import { TeamUser, TeamUserUpdate } from '@/types/team'
import { PartnerPropertyStore } from '@/types/partner-property'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'

export default defineComponent({
  name: 'CreateUser',
  props: ['initialValues'],
  setup() {
    return {
      UserProperties,
      PermissionScopes,
      PermissionValues,
    }
  },
  data() {
    return {
      validationSchema: partnerPropertyCreateUserFormValidationSchema(),
      error: false,
      errorMessage: '' as any,
      phoneMaskedValue: isEmpty(this.initialValues)
        ? ''
        : this.initialValues.phone,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      partnerPropertyId: '' as string,
      isNewProperty: isEmpty(this.initialValues),
      formValues: this.initialValues,
      partnerProperties: this.getPartnerProperties() as PartnerPropertyStore[],
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    InputMask,
    SpinnerLoading,
  },
  emits: ['onFinish'],
  mounted() {
    this.autoSelectPartnerProperty()
  },
  methods: {
    isInvalid,
    getPartnerProperties(): PartnerPropertyStore[] {
      const properties: PartnerPropertyStore[] =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES}`
        ]
      return properties
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: TeamUser): Promise<any> {
      let data: TeamUserUpdate = {
        ...values,
        partner_property_id: parseInt(values.partner_property_id + ''),
      }
      data.id_number = data.id_number || ''
      let action = `${ModulesIdentifiers.TEAM}/${TeamActions.CREATE}`
      if (!this.isNewProperty) {
        action = `${ModulesIdentifiers.TEAM}/${TeamActions.UPDATE}`
        data['id'] = this.initialValues.id
        data['current_partner_property_id'] =
          this.initialValues.partner_property_id
      }
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('main')) {
        delete data.main
      }
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('friendly_id')) {
        delete data.friendly_id
      }
      try {
        this.setPreferredPartnerProperty(values.partner_property_id.toString())
        await this.$store.dispatch(action, data)
        this.$emit('onFinish')
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    autoSelectPartnerProperty(): void {
      if (this.partnerProperties.length == 1) {
        const partnerPropertyFound = this.partnerProperties.find(
          (el) => el.id != undefined,
        )
        if (partnerPropertyFound)
          this.partnerPropertyId = partnerPropertyFound.id?.toString() as string
      } else if (
        this.partnerProperties.length >= 1 &&
        this.preferredPartnerPropertyId
      ) {
        const preferredPartnerPropertyIdAsNumber = parseInt(
          this.preferredPartnerPropertyId,
        )
        const partnerPropertyFound = this.partnerProperties.find(
          (el) => el.id == preferredPartnerPropertyIdAsNumber,
        )
        if (partnerPropertyFound)
          this.partnerPropertyId = this.preferredPartnerPropertyId
      }
    },
    setPreferredPartnerProperty(partnerPropertyId: string): void {
      this.$store.commit(
        `${ModulesIdentifiers.TEAM}/${TeamMutations.SET_PREFERRED_PARTNER_PROPERTY_ID}`,
        partnerPropertyId,
      )
    },
  },
  computed: {
    preferredPartnerPropertyId(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.TEAM}/${TeamGetters.GET_PREFERRED_PARTNER_PROPERTY_ID}`
      ]
    },
  },
})
