// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-ts-comment
// @ts-ignore
import { isProduction } from '@/utils'

const pushToDatalayer = function (payload: any): void {
  if (!isProduction) return
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.dataLayer) return
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.dataLayer.push(payload)
}

export const registerRegistration = (
  userId: number,
  partnerId: number,
): void => {
  pushToDatalayer({
    event: 'partner_registration',
    partner_id: partnerId,
    partner_portal_user_id: userId,
  })
}

export const registerPropertyAdded = (
  storeId: number,
  partnerId: number,
  userId: number,
  storeAddress: string,
): void => {
  pushToDatalayer({
    event: 'store_added',
    partner_id: partnerId,
    partner_portal_user_id: userId,
    store_id: storeId,
    store_address: storeAddress,
  })
}

export const registerTeamMemberAdded = (
  partnerId: number,
  userId: number,
  createdPartnerPortalUserId: number,
  position: string,
): void => {
  pushToDatalayer({
    event: 'team_member_added',
    partner_id: partnerId,
    partner_portal_user_id: userId,
    created_partner_portal_user_id: createdPartnerPortalUserId,
    created_partner_portal_user_position: position,
  })
}
