
import { defineComponent, PropType, reactive, toRefs, watch } from 'vue'
import {
  CoverageSelection,
  PlanRegistrationPaymentFields,
  UserWithAccessToPartnerProperty,
} from '@/types/plan-registration'
import { CoverageType, PlanIntervals } from '@/constants/coverages'
import { DiscountCode } from '@/types/discount-code'
import {
  CoveragesSummaryState,
  CoverageSummaryItem,
} from '@/types/coverages-summary'
import CoveragesSummary from '@/composables/coverages-summary'
import { getCoverageKey } from '@/services/coverages'
import { ModulesIdentifiers } from '@/store'
import { PlanRegistrationActions } from '@/store/modules/plan-registration/actions'
import { PlanRegistrationMutations } from '@/store/modules/plan-registration/mutations'
import PartnerPropertySelector from '@/components/common/PartnerPropertySelector.vue'
import SalesRepresentativeSelector from '@/components/common/SalesRepresentativeSelector.vue'
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerProperty } from '@/types/partner-property'
import InputMask from 'primevue/inputmask'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import { AppGetters } from '@/store/modules/app/getters'
import { registrationValidation } from '@/services/plan-registration/validations'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { UserState } from '@/types/user'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import { PartnerState } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { FIRST_MONTH_FREE_DISCOUNT_CODE } from '@/constants/plan-registration'
import PartnerStatusAlert from '@/components/registration/PartnerStatusAlert.vue'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { NewUserBasicInformation } from '@/types/access-administration'
import { TeamGetters } from '@/store/modules/team/getters'
import { RouteNames } from '@/constants/router'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { isInvalid } from '@/utils'
import { MapsSearchInputMode } from '@/constants/google-maps'
import { trackEvent } from '@/services/posthog'
import {
  PHONE_LENGTH_TO_REGISTER_EVENT,
  PostHogEvent,
} from '@/constants/posthog'

export default defineComponent({
  name: 'Checkout',
  components: {
    GoogleMapsInput,
    PartnerPropertySelector,
    SalesRepresentativeSelector,
    InputMask,
    Field,
    Form,
    ErrorMessage,
    PartnerStatusAlert,
  },
  setup(props: {
    coverageSelection: CoverageSelection[]
    discounts: DiscountCode[]
  }) {
    const { coverageSelection, discounts } = toRefs(props)
    const summary = reactive<CoveragesSummaryState>({
      annualizedAnnualCoverageCost: 0,
      annualizedMonthlyCoverageCost: 0,
      couponDiscount: null,
      monthlyDiscount: null,
      billed: {
        month: 0,
        year: 0,
      },
      items: [],
    })

    const coveragesSummary = new CoveragesSummary(summary)

    coveragesSummary.calculate(coverageSelection, discounts)

    watch(
      [coverageSelection, discounts],
      () => {
        coveragesSummary.calculate(coverageSelection, discounts)
      },
      { deep: true },
    )

    return {
      summary,
      CoverageType,
      getCoverageKey,
      PermissionScopes,
      PermissionValues,
      PartnerPrograms,
      registrationValidation: registrationValidation(),
      FIRST_MONTH_FREE_DISCOUNT_CODE,
    }
  },
  data() {
    return {
      newDiscountCode: '',
      stripeElement: {} as StripeCardElement,
      hasSalesDiscounPermission: false,
    }
  },
  mounted() {
    // Set first coverage selection as primary if not other is
    const primaryCoverageSelection = this.coverageSelection.find(
      (cs) => cs.primary,
    )
    if (
      !primaryCoverageSelection &&
      this.coverageSelection &&
      this.coverageSelection.length > 0
    ) {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PLAN_SELECTION_AS_PRIMARY}`,
        this.coverageSelection[0],
      )
    }

    // Load stripe stuff
    this.mountStripeElement()
  },
  methods: {
    isInvalid,
    handleAddressInput(addressObj: any) {
      if (addressObj && addressObj.formatted_address) {
        this.$store.commit(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_ADDRESS}`,
          addressObj.formatted_address,
        )
      }
    },
    handlePostalCodeInput(postalCodeObj: any) {
      if (typeof postalCodeObj === 'string') {
        this.$store.commit(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_POSTAL_CODE}`,
          postalCodeObj,
        )
        return
      }

      if (
        postalCodeObj &&
        postalCodeObj.address_components &&
        Array.isArray(postalCodeObj.address_components) &&
        postalCodeObj.address_components[0] &&
        postalCodeObj.address_components[0].short_name
      ) {
        this.$store.commit(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_POSTAL_CODE}`,
          postalCodeObj.address_components[0].short_name,
        )
      }
    },
    checkoutPriceCaption(summaryItem: CoverageSummaryItem): string {
      if (summaryItem.count == 1) {
        return `${this.partnerCurrencySymbol()}${summaryItem.fullPrice.toFixed(
          2,
        )} - ${summaryItem.coverageName}`
      } else if (summaryItem.count > 1) {
        return `${this.partnerCurrencySymbol()}${(
          summaryItem.fullPrice * summaryItem.count
        ).toFixed(
          2,
        )} (${this.partnerCurrencySymbol()}${summaryItem.fullPrice.toFixed(
          2,
        )}x${summaryItem.count}) - ${summaryItem.coverageName}`
      }
      return ''
    },
    discountCaption(ds: [DiscountCode, number]): string {
      return `-${this.partnerCurrencySymbol()}${ds[1].toFixed(2)} ${
        ds[0].alias
      }`
    },
    totalCaption(summary: CoveragesSummaryState): string {
      let totalAmount = summary.items.reduce(
        (accumulator, item) => accumulator + item.price * item.count,
        0,
      )

      let discounted = 0
      summary.monthlyDiscount?.forEach((val: number) => {
        discounted += val
      })

      totalAmount -= discounted

      if (totalAmount >= 1) {
        return `${this.partnerCurrencySymbol()}${totalAmount.toFixed(2)}`
      } else {
        return `${this.partnerCurrencySymbol()}1 (One time verification charge)`
      }
    },
    toggleDiscountCode(discountCode: DiscountCode | string) {
      this.$store.dispatch(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.TOGGLE_DISCOUNT_CODE}`,
        discountCode,
      )
    },
    wasDiscountCodeApplied(code: string): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.WAS_DISCOUNT_CODE_APPLIED}`
      ](code)
    },
    toggleFirstMonthFreeDiscount() {
      this.$store.dispatch(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.TOGGLE_FIRST_MONTH_FREE_DISCOUNT}`,
      )
    },
    registerPhoneNumberInput(
      coverageSelection: CoverageSelection,
      value: string,
    ) {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PLAN_SELECTION_PHONE}`,
        {
          coverageSelection,
          phoneNumber: value,
        },
      )
      if (value.length >= PHONE_LENGTH_TO_REGISTER_EVENT) {
        trackEvent(PostHogEvent.lead_phone_number_entered, {
          phone: value,
        })
      }
    },
    setCoverageAsPrimary(coverageSelection: CoverageSelection) {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PLAN_SELECTION_AS_PRIMARY}`,
        coverageSelection,
      )
    },
    mountStripeElement() {
      const elements = this.$stripe.elements()
      this.stripeElement = elements.create('card', {
        classes: { base: 'form-control' },
      })
      this.stripeElement.mount(this.$refs.cardInput as HTMLInputElement)
      this.stripeElement.on('change', this.handleChangeOnCreditCardInput)
    },
    async handleChangeOnCreditCardInput(event: StripeCardElementChangeEvent) {
      if (event.complete) {
        try {
          const tokenResult = await this.$stripe.createToken(this.stripeElement)
          if (tokenResult.error) throw Error(JSON.stringify(tokenResult.error))
          this.$store.commit(
            `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PAYMENT_INFORMATION_FILED}`,
            {
              field: 'creditCardToken',
              value: tokenResult.token,
            },
          )
          trackEvent(PostHogEvent.lead_payment_data_entered, {})
        } catch (e) {
          console.log(e)
        }
      }
    },
    setPaymentInformationField(
      field: PlanRegistrationPaymentFields,
      event: Event,
    ) {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PAYMENT_INFORMATION_FILED}`,
        {
          field,
          value: (event.target as HTMLInputElement).value,
        },
      )
    },
    async register(): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.SUBMIT_REGISTRATION}`,
        )
        this.$emit('resetCoverageCombo')
      } catch (e) {
        this.stripeElement.clear()
      }
    },
    onChangePartnerProperty(partnerProperty: PartnerProperty): void {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PARTNER_PROPERTY_ID}`,
        partnerProperty.id,
      )
      this.setHasPartnerPropertySalesRegisterDiscountPermission(partnerProperty)
    },
    onChangeSalesRep(salesRep: UserWithAccessToPartnerProperty): void {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_SALES_REP_ID}`,
        salesRep.userId,
      )
    },
    cancelCheckout(): void {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_PAYMENT_INFORMATION}`,
      )
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_STORE_AND_SALES_REP}`,
      )
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_DISCOUNTS}`,
      )
      this.$emit('cancelRegistration')
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
    setHasPartnerPropertySalesRegisterDiscountPermission(
      partnerProperty: PartnerProperty,
    ): void {
      this.hasSalesDiscounPermission = this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.CHECK_SALES_PERMISSION}`
      ](
        partnerProperty,
        PermissionScopes.PARTNER_PROPERTY_SALES_REGISTER_DISCOUNT,
        PermissionValues.ALLOW,
      )
    },
  },
  computed: {
    address(): string {
      return this.$store.state.planRegistration.address
    },
    postalCode(): string {
      return this.$store.state.planRegistration.postalCode
    },
    MapsSearchInputMode() {
      return MapsSearchInputMode
    },
    cardHolderFirstName(): string {
      return this.$store.state.planRegistration.paymentInformation
        .cardHolderFirstName
    },
    cardHolderLastName(): string {
      return this.$store.state.planRegistration.paymentInformation
        .cardHolderLastName
    },
    primaryEmailAddress(): string {
      return this.$store.state.planRegistration.paymentInformation
        .primaryEmailAddress
    },
    partnerPropertyId(): number {
      return this.$store.state.planRegistration.partnerPropertyId
    },
    salesRepresentativeId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.SALES_REPRESENTATIVE_ID}`
      ]
    },
    preferredSalesRepresentativeId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.PREFERRED_SALES_REPRESENTATIVE_ID}`
      ]
    },
    preferredPartnerPropertyId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.PREFERRED_PARTNER_PROPERTY_ID}`
      ]
    },
    creditCardToken(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.CARD_TOKEN}`
      ]
    },
    isFirstMonthFreeApplied: {
      get(): boolean {
        return this.$store.getters[
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.WAS_FIRST_MONTH_FREE_APPLIED}`
        ]
      },
      set(): void {
        // This function should remain empty, is created as a hack to keep ui component interaction consistence
      },
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    partner(): PartnerState {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    hasPartnerSalesRegisterDiscountPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_DISCOUNT,
        PermissionValues.ALLOW,
      )
    },
    partnerProperties(): PartnerProperty[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES_SALES_ACCESS}`
      ]
    },
    partnerPropertyFriendlyId(): string {
      const partner_property_id = this.partnerPropertyId
      const partner_property = this.partnerProperties.find((pp) => {
        return pp.id == partner_property_id
      })
      return partner_property ? partner_property.friendly_id : ''
    },
    partnerUsers(): NewUserBasicInformation[] {
      return this.$store.getters[
        `${ModulesIdentifiers.TEAM}/${TeamGetters.USERS}`
      ]
    },
    salesRepresentativeFriendlyId(): string {
      const sales_representative_id = this.salesRepresentativeId
      const sales_representative = this.partnerUsers.find((pu) => {
        return pu.id == sales_representative_id
      })
      return sales_representative ? sales_representative.friendly_id : ''
    },
  },
  props: {
    coverageSelection: {
      type: Array as PropType<CoverageSelection[]>,
      required: true,
    },
    discounts: {
      type: Array as PropType<DiscountCode[]>,
      required: false,
      default: () => [],
    },
    planInterval: {
      type: String as PropType<PlanIntervals>,
      required: true,
    },
    coverageType: {
      type: String as PropType<CoverageType>,
      required: false,
      default: '',
    },
  },
  emits: ['cancelRegistration', 'resetCoverageCombo'],
})
