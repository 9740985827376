
import { defineComponent } from 'vue'
import CopyText from '@/components/common/CopyText.vue'
import {
  PayableDocumentStatusOptions,
  PaymentFrontendOptions,
} from '@/constants/compensations'

export default defineComponent({
  name: 'PayoutCompensationDetailRow',
  props: {
    singleTimePayout: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      PayableDocumentStatusOptions,
      PaymentFrontendOptions,
    }
  },
  components: {
    CopyText,
  },
})
