import {
  AboutAkko,
  Alert,
  AppState,
  Country,
  PartnerPropertyType,
  PartnerType,
} from '@/types/app.d'
import { PartnerPropertyFormOptions } from '@/constants/partner-property'

export enum AppGetters {
  OPEN_SIDEBAR = 'openSidebar',
  APP_IS_LOADING = 'appIsLoading',
  LOADING_MESSAGE = 'loadingMessage',
  ALERTS = 'alerts',
  PARTNER_PROGRAM = 'partnerProgram',
  PARTNER_PROGRAM_ID = 'partnerProgramId',
  GET_ABOUT_AKKO = 'getAboutAkko',
  GET_IS_MOBILE = 'getIsMobile',
  GET_COUNTRY = 'getCountry',
  GET_PARTNER_TYPE = 'getPartnerType',
  GET_CURRENCY = 'getCurrency',
  GET_PARTNER_PROPERTY_TYPE = 'getPartnerPropertyType',
  GET_PARTNER_PROPERTY_TYPE_PHYSICAL_DEFAULT = 'getPartnerPropertyTypePhysicalDefault',
  GET_PARTNER_PROPERTY_TYPE_FIRST_PHYSICAL = 'getPartnerPropertyTypeFirstPhysical',
  GET_PARTNER_PROPERTY_TYPE_FIRST_MOBILE = 'getPartnerPropertyTypeFirstMobile',
  GET_PARTNER_PROPERTY_TYPE_FIRST_ELECTRONIC = 'getPartnerPropertyTypeFirstElectronic',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getters: any = {}

getters[AppGetters.OPEN_SIDEBAR] = (state: AppState): boolean => {
  return state.openSidebar
}

getters[AppGetters.APP_IS_LOADING] = (state: AppState): boolean => {
  return !!state.loadingQue.length
}

getters[AppGetters.LOADING_MESSAGE] = (state: AppState): string => {
  const operation = state.loadingQue.find((op) => !!op.message)
  return (operation && operation.message) || ''
}

getters[AppGetters.ALERTS] = (state: AppState): Alert[] => {
  return state.alerts
}

getters[AppGetters.PARTNER_PROGRAM] = (state: AppState): string => {
  return state.partnerProgram
}

getters[AppGetters.PARTNER_PROGRAM_ID] = (state: AppState): number => {
  return state.partnerProgramId
}

getters[AppGetters.GET_ABOUT_AKKO] = (state: AppState): AboutAkko[] => {
  return state.aboutAkko
}

getters[AppGetters.GET_IS_MOBILE] = (state: AppState): boolean => {
  return state.isMobile
}

getters[AppGetters.GET_COUNTRY] = (state: AppState): Country[] => {
  return state.country
}

getters[AppGetters.GET_PARTNER_TYPE] = (state: AppState): PartnerType[] => {
  return state.partnerType
}

getters[AppGetters.GET_PARTNER_PROPERTY_TYPE] = (
  state: AppState,
): PartnerPropertyType[] => {
  return state.partnerPropertyType
}

getters[AppGetters.GET_PARTNER_PROPERTY_TYPE_PHYSICAL_DEFAULT] = (
  state: AppState,
): PartnerPropertyType => {
  if (state.partnerPropertyType) {
    const physical_default = state.partnerPropertyType.find(
      (el) => el.is_physical_default,
    )
    return physical_default as PartnerPropertyType
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return null as any
}

getters[AppGetters.GET_PARTNER_PROPERTY_TYPE_FIRST_PHYSICAL] = (
  state: AppState,
): PartnerPropertyType => {
  if (state.partnerPropertyType) {
    const physical_default = state.partnerPropertyType.find(
      (el) => el.form_type == PartnerPropertyFormOptions.PHYSICAL_STORE,
    )
    return physical_default as PartnerPropertyType
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return null as any
}

getters[AppGetters.GET_PARTNER_PROPERTY_TYPE_FIRST_MOBILE] = (
  state: AppState,
): PartnerPropertyType => {
  if (state.partnerPropertyType) {
    const physical_default = state.partnerPropertyType.find(
      (el) => el.form_type == PartnerPropertyFormOptions.MOBILE_PARTNER,
    )
    return physical_default as PartnerPropertyType
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return null as any
}

getters[AppGetters.GET_PARTNER_PROPERTY_TYPE_FIRST_ELECTRONIC] = (
  state: AppState,
): PartnerPropertyType => {
  if (state.partnerPropertyType) {
    const physical_default = state.partnerPropertyType.find(
      (el) => el.form_type == PartnerPropertyFormOptions.ELECTRONIC_PARTNER,
    )
    return physical_default as PartnerPropertyType
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return null as any
}

export default getters
