import {
  Compensation,
  CompensationAmountFilter,
  CompensationState,
  SingleTimePayoutFilter,
  SingleTimePayout,
  SingleTimePayoutSearch,
} from '@/types/compensation'

export enum CompensationMutations {
  SET_COMPENSATIONS = 'setCompensations',
  SET_COMPENSATION_AMOUNT_FILTER = 'setCompensationAmountFilter',
  SET_COMPENSATION_SINGLE_PAYOUTS = 'setCompensationSinglePayouts',
  SET_COMPENSATION_SINGLE_PAYOUTS_FILTER = 'setCompensationSinglePayoutsFilter',
  FILTER_SINGLE_TIME_PAYOUTS = 'filterSingleTimePayouts',
  CLEAN_FILTER_SINGLE_TIME_PAYOUTS = 'CleanFilterSingleTimePayouts',
}

const mutations: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: CompensationState, newData: any) => void
} = {}

mutations[CompensationMutations.SET_COMPENSATIONS] = (
  state: CompensationState,
  compensation: Compensation,
): void => {
  state.compensation = compensation
}

mutations[CompensationMutations.SET_COMPENSATION_AMOUNT_FILTER] = (
  state: CompensationState,
  compensationAmountFilter: CompensationAmountFilter,
): void => {
  state.compensationAmountFilter = compensationAmountFilter
}

mutations[CompensationMutations.SET_COMPENSATION_SINGLE_PAYOUTS] = (
  state: CompensationState,
  singleTimePayouts: SingleTimePayout[],
): void => {
  state.singleTimePayouts = [...singleTimePayouts]
  state.singleTimePayoutsSearched = [...singleTimePayouts]
  state.singleTimePayoutsQuantity = state.singleTimePayouts.length
  state.singleTimePayoutsSearchedQuantity =
    state.singleTimePayoutsSearched.length
}

mutations[CompensationMutations.SET_COMPENSATION_SINGLE_PAYOUTS_FILTER] = (
  state: CompensationState,
  singleTimePayoutFilter: SingleTimePayoutFilter,
): void => {
  state.singleTimePayoutFilter = singleTimePayoutFilter
}

mutations[CompensationMutations.FILTER_SINGLE_TIME_PAYOUTS] = (
  state: CompensationState,
  singleTimePayoutSearch: SingleTimePayoutSearch,
): void => {
  state.singleTimePayoutsSearched = state.singleTimePayouts.filter((el) => {
    if (!singleTimePayoutSearch.phone) {
      return true
    }
    return el.phone.indexOf(singleTimePayoutSearch.phone) >= 0
  })
  state.singleTimePayoutsSearchedQuantity =
    state.singleTimePayoutsSearched.length
}

mutations[CompensationMutations.CLEAN_FILTER_SINGLE_TIME_PAYOUTS] = (
  state: CompensationState,
): void => {
  state.singleTimePayoutsSearched = [...state.singleTimePayouts]
  state.singleTimePayoutsSearchedQuantity =
    state.singleTimePayoutsSearched.length
}

export default mutations
