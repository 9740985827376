
import { defineComponent } from 'vue'
import PartnerPortalUserListTable from '@/components/representatives/PartnerPortalUserListTable.vue'
import CreateUser from '@/components/representatives/CreateUser.vue'
import Modal from '@/components/common/Modal.vue'
import ForceToCreatePartnerProperties from '@/components/common/ForceToCreatePartnerProperties.vue'
import { ModulesIdentifiers } from '@/store'
import { TeamActions } from '@/store/modules/team/actions'
import { TeamUser } from '@/types/team'
import { TeamGetters } from '@/store/modules/team/getters'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { UserGetters } from '@/store/modules/user/getters'
import { UserState } from '@/types/user'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'

export default defineComponent({
  name: 'Representatives',
  components: {
    PartnerPortalUserListTable,
    CreateUser,
    Modal,
    ForceToCreatePartnerProperties,
  },
  data() {
    return {
      createUser: false,
      userValues: {},
      emptyProperty: false,
    }
  },
  async mounted() {
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_PROPERTIES}`,
    )
    await this.$store.dispatch(`${ModulesIdentifiers.TEAM}/${TeamActions.LIST}`)
  },
  methods: {
    onUpdate(id: number) {
      const user: TeamUser =
        this.$store.getters[`${ModulesIdentifiers.TEAM}/${TeamGetters.USER}`](
          id,
        )
      this.createUser = true
      this.userValues = Object.assign({}, user)
    },
    onDelete(id: number) {
      this.$store.dispatch(
        `${ModulesIdentifiers.TEAM}/${TeamActions.DELETE}`,
        id,
      )
    },
    createNewUser() {
      this.createUser = true
      this.userValues = {}
    },
    closeCreateUserModal() {
      this.createUser = false
      this.userValues = {}
    },
  },
  computed: {
    partnerProgramId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM_ID}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    forceToCreatePartnerProperties(): boolean {
      const partnerProperties =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES}`
        ]
      return partnerProperties && partnerProperties.length === 0
    },
  },
})
