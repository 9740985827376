import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhysicalStorePropertyForm = _resolveComponent("PhysicalStorePropertyForm")!
  const _component_ElectronicPropertyForm = _resolveComponent("ElectronicPropertyForm")!
  const _component_MobilePropertyForm = _resolveComponent("MobilePropertyForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isPhysicalStore)
      ? (_openBlock(), _createBlock(_component_PhysicalStorePropertyForm, {
          key: 0,
          initialValues: _ctx.initialValues,
          isNewProperty: _ctx.isNewProperty,
          propertyType: _ctx.propertyType,
          onOnCancel: _cache[0] || (_cache[0] = () => _ctx.$emit('onCancel'))
        }, null, 8, ["initialValues", "isNewProperty", "propertyType"]))
      : _createCommentVNode("", true),
    (_ctx.isElectronic)
      ? (_openBlock(), _createBlock(_component_ElectronicPropertyForm, {
          key: 1,
          isNewProperty: _ctx.isNewProperty,
          initialValues: _ctx.initialValues,
          propertyType: _ctx.propertyType,
          onOnCancel: _cache[1] || (_cache[1] = () => _ctx.$emit('onCancel'))
        }, null, 8, ["isNewProperty", "initialValues", "propertyType"]))
      : _createCommentVNode("", true),
    (_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_MobilePropertyForm, {
          key: 2,
          initialValues: _ctx.initialValues,
          isNewProperty: _ctx.isNewProperty,
          propertyType: _ctx.propertyType,
          onOnCancel: _cache[2] || (_cache[2] = () => _ctx.$emit('onCancel'))
        }, null, 8, ["initialValues", "isNewProperty", "propertyType"]))
      : _createCommentVNode("", true)
  ], 64))
}