import {
  FilterReport,
  ReportDataTemplate,
  ReportState,
  SalesOverview,
} from '@/types/report'
import { MultiBarPayload } from '@/types/sales'

export enum ReportGetters {
  GET_FILTER = 'getFilter',
  GET_REPORT_BY_TYPE = 'getReportByType',
  GET_REPORTS = 'getReports',
  SALES_OVERVIEW = 'salesOverview',
}

const getters: { [key: string]: (state: ReportState) => void } = {}

getters[ReportGetters.GET_FILTER] = (state: ReportState): FilterReport => {
  return state.filter
}

getters[ReportGetters.GET_REPORT_BY_TYPE] =
  (state: ReportState) =>
  (reportType: string): MultiBarPayload => {
    return state.reports[reportType]
  }

getters[ReportGetters.GET_REPORTS] = (
  state: ReportState,
): ReportDataTemplate => {
  return state.reports
}

getters[ReportGetters.SALES_OVERVIEW] = (
  state: ReportState,
): SalesOverview[] => {
  return state.salesOverview
}

export default getters
