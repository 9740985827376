import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClaimsInstructionsApproved = _resolveComponent("ClaimsInstructionsApproved")!

  return (_openBlock(), _createBlock(_component_ClaimsInstructionsApproved, {
    replacementCost: _ctx.claimReplacementCost,
    screenReplacementDeductible: _ctx.claimScreenReplacementDeductible,
    repairDeductible: _ctx.claimRepairDeductible,
    replacementDeductible: _ctx.claimReplacementDeductible,
    coverageDeductible: _ctx.claimCoverageDeductible
  }, null, 8, ["replacementCost", "screenReplacementDeductible", "repairDeductible", "replacementDeductible", "coverageDeductible"]))
}