
import { defineComponent } from 'vue'
import {
  AkkoCoverageType,
  CoverageType,
  InjuredGadgetsCoverageType,
} from '@/constants/coverages'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'ActivateWarrantyPlanSelector',
  setup() {
    return {
      CoverageType,
      AkkoCoverageType,
      InjuredGadgetsCoverageType,
      PartnerPrograms,
    }
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
  props: {
    selectActivateWarranty: {
      type: Function,
      default: () => {
        /* */
      },
    },
  },
})
