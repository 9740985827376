import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyText = _resolveComponent("CopyText")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createElementVNode("span", null, [
        _createVNode(_component_CopyText, {
          text: _ctx.device.device_id.toString()
        }, null, 8, ["text"])
      ])
    ]),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.device_kind), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.device_brand), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.device_model), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.device_serial_number), 1)
  ]))
}