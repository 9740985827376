
import { defineComponent } from 'vue'
import EndUserClaimRow from '@/components/end-users/EndUserClaimRow.vue'

export default defineComponent({
  name: 'EndUserClaimTable',
  components: {
    EndUserClaimRow,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    claims: {
      type: Array,
      default() {
        return []
      },
    },
  },
})
