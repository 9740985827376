import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-209078b7")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  value: "all"
}
const _hoisted_3 = ["value", "selected"]
const _hoisted_4 = {
  key: 1,
  class: "text-color-primary"
}
const _hoisted_5 = { key: 2 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("select", {
            class: _normalizeClass(["form-select", _ctx.classNames]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.partnerPropertySelected = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChangePartnerProperty && _ctx.onChangePartnerProperty(...args)))
          }, [
            (_ctx.allowAll)
              ? (_openBlock(), _createElementBlock("option", _hoisted_2, _toDisplayString(_ctx.defaultValue), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerProperties, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option.id,
                value: option,
                selected: _ctx.isPartnerPropertySelected(option.id)
              }, _toDisplayString(option.name), 9, _hoisted_3))
            }), 128))
          ], 34), [
            [_vModelSelect, _ctx.partnerPropertySelected]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.noPartnerProperties)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, " There aren't any properties with permission to register plans "))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, "loading..."))
      : _createCommentVNode("", true)
  ], 64))
}