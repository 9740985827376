
import CurrencySelector from '@/components/common/CurrencySelector.vue'
import { defineComponent, PropType } from 'vue'
import { Currencies } from '@/constants/currencies'

export default defineComponent({
  name: 'MoneyAmount',
  components: { CurrencySelector },
  emits: ['onChange'],
  setup() {
    return {
      Currencies,
    }
  },
  props: {
    cssClasses: {
      type: String as PropType<string>,
      default: '',
    },
    value: {
      type: Number as PropType<number | undefined>,
      required: true,
    },
  },
  computed: {
    inputValue: {
      set(value: number) {
        this.$emit('onChange', value)
      },
      get(): number | undefined {
        return this.value
      },
    },
  },
})
