
import { defineComponent } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { Form } from 'vee-validate'
import { PartnerPropertyStore } from '@/types/partner-property'
import { ModulesIdentifiers } from '@/store'
import Header from '../common/Header.vue'
import { addPropertiesValidationSchema } from '@/services/authentication/validations'
import NewPropertyFields from './NewPropertyFields.vue'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { RouteNames } from '@/constants/router'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { PartnerActions } from '@/store/modules/partner/actions'
import { UserActions } from '@/store/modules/user/actions'
import { UserGetters } from '@/store/modules/user/getters'
import { identifyUser } from '@/services/intercom'
import { identifyUser as identifyUserLogRocket } from '@/services/logrocket'
import { Partner } from '@/types/partner'

export default defineComponent({
  name: 'AddProperties',
  components: {
    Header,
    Form,
    NewPropertyFields,
    SpinnerLoading,
  },
  data() {
    return {
      validationSchema: addPropertiesValidationSchema(),
      stores: [{ id: Date.now() }],
      error: false,
      errorMessage: '',
      initialProperties: cloneDeep(
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES}`
        ],
      ),
    }
  },
  async mounted() {
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER}/${PartnerActions.READ_PARTNER}`,
    )
    await this.$store.dispatch(
      `${ModulesIdentifiers.USER}/${UserActions.GET_CURRENT_USER_INFORMATION}`,
    )
    const currentUser =
      this.$store.getters[`${ModulesIdentifiers.USER}/${UserGetters.USER}`]
    const partner =
      this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    // Identify user on Intercom
    identifyUser(
      currentUser,
      (partner as Partner).friendlyId,
      (partner as Partner).totalNumberOfPlansSold,
      (partner as Partner).totalNumberOfActivePlansSold,
      (partner as Partner).mostRecentPlanSold,
      (partner as Partner).firstPlanSold,
      (partner as Partner).salesPast30,
    )
    // Identify user on LogRocket
    identifyUserLogRocket(currentUser, partner)
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: { stores: PartnerPropertyStore[] }): Promise<any> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.PUT_PROPERTIES}`,
          { stores: values.stores, partnerId: this.getPartnerId },
        )
        this.$router.push({ name: RouteNames.PAYPAL_EMAIL })
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    onRemoveForm(store: { id: number }) {
      const index = this.stores.indexOf(store)
      if (index === -1) return
      this.stores.splice(index, 1)
    },
    onAddNewProperty() {
      this.stores.push({ id: Date.now() })
    },
  },
  computed: {
    getPartnerId() {
      const path = `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_ID}`
      return this.$store.getters[path]
    },
  },
})
