import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    compensation: {},
    compensationAmountFilter: null,
    singleTimePayouts: [],
    singleTimePayoutsQuantity: 0,
    singleTimePayoutFilter: {},
    singleTimePayoutsSearched: [],
    singleTimePayoutsSearchedQuantity: 0,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
