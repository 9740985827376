const appId = process.env.VUE_APP_INTERCOM_KEY

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-ts-comment
// @ts-ignore
const intercomInstance = function (...args): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.Intercom) return
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.Intercom(...args)
}

export const bootChat = function (): void {
  intercomInstance('boot', {
    app_id: appId,
    Partner: true,
  })
}

export const cleanAndReStartSession = function (): void {
  intercomInstance('shutdown')
  bootChat()
}

export const identifyUser = function (
  user: {
    email: string
    firstName: string
    lastName: string
    id: number
    positionTitle: string
    friendlyId: string
    intercomHashes: { webHash: string }
  },
  partnerFriendlyId: string,
  partnerTotalPlansSold: number,
  partnerTotalActivePlansSold: number,
  partnerLastPlanSold: Date,
  partnerFirstPlanSold: Date,
  salesPast30: number,
): void {
  intercomInstance('update', {
    email: user.email,
    name: `${user.firstName}  ${user.lastName}`,
    user_id: user.friendlyId,
    Partner: true,
    positionTitle: user.positionTitle,
    Partner_id: partnerFriendlyId,
    total_sales: partnerTotalPlansSold,
    total_active_sales: partnerTotalActivePlansSold,
    last_plan_sold: partnerLastPlanSold,
    first_plan_sold: partnerFirstPlanSold,
    sales_past30: salesPast30,
    user_hash: user.intercomHashes.webHash,
  })
}

export const showChat = function (): void {
  intercomInstance('show')
}
