
import VueApexCharts from 'vue3-apexcharts'
import { defineComponent, PropType } from 'vue'
import { MultiBarPayload } from '@/types/sales.d'

export default defineComponent({
  name: 'MultiBar',
  components: {
    VueApexCharts,
  },
  props: {
    _series: {
      type: Array as PropType<MultiBarPayload[]>,
      default: () => [{}],
    },
    _categories: {
      type: Array as PropType<string[]>,
      default: () => [''],
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['00-00-0000'],
        },
        yaxis: {
          title: {
            text: 'sales',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (value: number | string) => {
              return `${value} sales`
            },
          },
        },
      },
    }
  },
  computed: {
    getSeries(): MultiBarPayload[] {
      return this._series
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getChartOptions(): any {
      return {
        ...this.chartOptions,
        xaxis: {
          categories: this._categories,
        },
      }
    },
  },
})
