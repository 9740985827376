
import { defineComponent } from 'vue'
import TrashIcon from '@/components/common/icons/Trash-Icon.vue'
import { ModulesIdentifiers } from '@/store'
import { ProductGetters } from '@/store/modules/products/getters'
import ChosenProductInfo from '@/components/ChosenProductInfo.vue'
import { PlanIntervals } from '@/constants/coverages'
import { ProductsMutations } from '@/store/modules/products/mutations'
import { DiscountCode } from '@/types/discount-code'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'

export default defineComponent({
  name: 'ProductSummaryCard',
  components: { ChosenProductInfo, TrashIcon },
  setup() {
    return { PlanIntervals }
  },
  beforeUpdate() {
    if (!this.isIntervalValid(this.selectedInterval)) {
      if (this.validIntervalsInCart.length) {
        this.setInterval(
          this.validIntervalsInCart[0].toUpperCase() as PlanIntervals,
        )
      }
    }
  },
  methods: {
    setInterval(interval: PlanIntervals) {
      return this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.SET_INTERVAL}`,
        interval,
      )
    },
    getDiscountItemAmountCaption(ds: [DiscountCode, number]): string {
      if (ds[0].alias === 'First Month Free') {
        return `${ds[0].alias} applied (-${
          this.partnerCurrencySymbol
        }${ds[1].toFixed(2)})`
      }

      return `${ds[0].amount}% ${ds[0].alias} applied (-${
        this.partnerCurrencySymbol
      }${ds[1].toFixed(2)})`
    },
    deleteProductFromCart(productUiId: string) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.REMOVE_PRODUCT_FROM_CART}`,
        productUiId,
      )
    },
    isIntervalValid(interval: string) {
      return (
        this.validIntervalsInCart.findIndex(
          (item) => item === interval.toLowerCase(),
        ) !== -1
      )
    },
  },
  computed: {
    doesUserSelectedProducts() {
      const cart =
        this.$store.getters[
          `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_IN_CART}`
        ]
      for (const productGroupKey in cart) {
        if (cart[productGroupKey].length > 0) {
          return true
        }
      }
      return false
    },
    individualProductsInCart() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_IN_CART}`
      ]
    },
    validIntervalsInCart(): string[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_VALID_PRODUCTS_PRICES_INTERVALS_IN_CART}`
      ]
    },
    selectedInterval() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_CART_INTERVAL}`
      ]
    },
    discounts() {
      return this.$store.state.planRegistration.discounts
    },
    planInterval() {
      return this.$store.state.products.productCartInterval
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
    couponDiscount() {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.GET_COUPON_DISCOUNT_MAP}`
      ]
    },
    annualizedCosts() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_ANNUALIZED_COST}`
      ]
    },
    totalBilled(): { month: number; year: number } {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.GET_BILLED_AMOUNT_INDIVIDUAL_PRODUCT}`
      ]
    },
    annualDiscount(): number {
      const annualizedCosts =
        this.$store.getters[
          `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_ANNUALIZED_COST}`
        ]
      return (
        annualizedCosts[PlanIntervals.MONTHLY.toLowerCase()] -
        annualizedCosts[PlanIntervals.ANNUAL.toLowerCase()]
      )
    },
  },
})
