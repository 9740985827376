export const colors = [
  '#dc3a35',
  '#084d61',
  '#ffc857',
  '#187e8a',
  '#c58e1e',
  '#ed706c',
  '#0e7e9f',
  '#e77322',
  '#457abb',
  '#9845bb',
  '#bb45a2',
]
