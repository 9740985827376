<template>
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.242641 0.241734C-0.0502527 0.534628 -0.0502526 1.0095 0.242641 1.30239L3.42462 4.48438L0.242641 7.66636C-0.0502526 7.95925 -0.0502527 8.43412 0.242641 8.72702C0.535534 9.01991 1.01041 9.01991 1.3033 8.72702L4.48528 5.54504L7.66726 8.72702C7.96015 9.01991 8.43503 9.01991 8.72792 8.72702C9.02082 8.43412 9.02081 7.95925 8.72792 7.66636L5.54594 4.48438L8.72792 1.30239C9.02081 1.0095 9.02082 0.534628 8.72792 0.241734C8.43503 -0.0511589 7.96015 -0.0511586 7.66726 0.241734L4.48528 3.42371L1.3033 0.241734C1.01041 -0.0511588 0.535534 -0.0511589 0.242641 0.241734Z"
      fill="currentColor"
    />
  </svg>
</template>
