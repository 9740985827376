import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerProperty = _resolveComponent("PartnerProperty")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.css}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPartnerProperties, (store) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-md-6 col-sm-12 mb-3",
          key: store.id
        }, [
          _createVNode(_component_PartnerProperty, {
            store: store,
            onUpdate: _ctx.onUpdate
          }, null, 8, ["store", "onUpdate"])
        ]))
      }), 128))
    ])
  ], 2))
}