export enum PlanIntervals {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
}

export enum AkkoCoveragesTags {
  STUDENT_ANNUAL = 'STUDENT_ANNUAL',
  STUDENT_MONTHLY = 'STUDENT_MONTHLY',
  ADULT_ANNUAL = 'ADULT_ANNUAL',
  ADULT_MONTHLY = 'ADULT_MONTHLY',
}

export enum AkkoCoverageType {
  ADULT = 'ADULT',
  STUDENT = 'STUDENT',
}

export enum InjuredGadgetsCoveragesTags {
  PLAN_ANNUAL = 'INJURED_GADGETS_PLAN_BUNDLED_ANNUAL',
  PLAN_MONTHLY = 'INJURED_GADGETS_PLAN_BUNDLED_MONTHLY',
  PLAN_PRO_ANNUAL = 'INJURED_GADGETS_PLAN_PRO_ANNUAL',
  PLAN_PRO_MONTHLY = 'INJURED_GADGETS_PLAN_PRO_MONTHLY',
  PLAN_PHONE_ANNUAL = 'INJURED_GADGETS_PLAN_PHONE_ANNUAL',
  PLAN_PHONE_MONTHLY = 'INJURED_GADGETS_PLAN_PHONE_MONTHLY',
  PLAN_FOLDABLE_PHONE_ANNUAL = 'INJURED_GADGETS_PLAN_FOLDABLE_PHONE_ANNUAL',
  PLAN_FOLDABLE_PHONE_MONTHLY = 'INJURED_GADGETS_PLAN_FOLDABLE_PHONE_MONTHLY',
}

export enum InjuredGadgetsCoverageType {
  PLAN_BUNDLED = 'PLAN_BUNDLED',
  PLAN_PRO = 'PLAN_PRO',
  PLAN_PHONE = 'PLAN_PHONE',
  PLAN_FOLDABLE_PHONE = 'PLAN_FOLDABLE_PHONE',
}

export enum CoverageType {
  AKKO = 'akko',
  phone = 'phone',
  BUSINESS_PLAN = 'business_plan',
  HOME_ELECTRONIC_REGULAR_PLAN = 'home_electronic_regular_plan',
  HOME_ELECTRONIC_PLUS_PLAN = 'home_electronic_plus_plan',
  ACTIVATE_WARRANTY = 'activate_warranty',
  EXTENDED_WARRANTY_FOR_FIG = 'extended_warranty_for_fig',
}

export enum HomeRegularCoveragesTags {
  HOME_REGULAR_ANNUAL = 'HOME_REGULAR_ANNUAL',
  HOME_REGULAR_MONTHLY = 'HOME_REGULAR_MONTHLY',
}

export enum HomePlusCoveragesTags {
  HOME_PLUS_ANNUAL = 'HOME_PLUS_ANNUAL',
  HOME_PLUS_MONTHLY = 'HOME_PLUS_MONTHLY',
}

export enum HomeRegularCoverageType {
  REGULAR = 'HOME_REGULAR',
}

export enum HomePlusCoverageType {
  PLUS = 'HOME_PLUS',
}
