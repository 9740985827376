
import { defineComponent, PropType } from 'vue'
import { DistanceUnits } from '@/constants/distance-units'

export default defineComponent({
  name: 'DistanceUnitSelector',
  setup() {
    return {
      DistanceUnits,
      selection: '' as DistanceUnits,
    }
  },
  props: {
    cssClasses: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    defaultValue: {
      type: String as PropType<DistanceUnits>,
      required: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['changeSelection'],
  created() {
    if (this.defaultValue) {
      this.selection = this.defaultValue
    }
  },
  methods: {
    emitUnitSelection(selection: DistanceUnits) {
      this.$emit('changeSelection', selection)
    },
  },
})
