
import { defineComponent, PropType, ref } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { ProductGetters } from '@/store/modules/products/getters'
import CloseIcon from '@/components/common/icons/Close-Icon.vue'

export default defineComponent({
  name: 'DeviceProductPicker',
  components: { CloseIcon },
  setup() {
    const selectedMake = ref('')
    const selectedModel = ref('')
    return {
      selectedMake,
      selectedModel,
    }
  },
  props: {
    productGroupName: {
      type: String as PropType<string>,
      required: true,
    },
    productGroupDisplayName: {
      type: String as PropType<string>,
      required: true,
    },
  },
  methods: {
    makeSelected(make: string) {
      this.selectedMake = make
    },
    modelSelected(model: string) {
      this.selectedModel = model
      this.$emit('makeAndModelSelected', {
        make: this.selectedMake,
        model: this.selectedModel,
      })
    },
  },
  computed: {
    devicesMakes() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_DEVICE_MAKE_FROM_PRODUCT_GROUP}`
      ](this.productGroupName)
    },
    deviceModels() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_DEVICE_MODEL_FROM_PRODUCT_GROUP_AND_MAKE}`
      ]({ productGroupName: this.productGroupName, make: this.selectedMake })
    },
  },
})
