import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  state: {
    authed: false,
    email: '',
    phone: '',
    token: '',
    permissions: {},
    partnerPropertiesByScope: {},
    termsAccepted: null,
    resetPassword: null,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
