
import { defineComponent } from 'vue'
import EndUserClaimDeviceRow from '@/components/end-users/EndUserClaimDeviceRow.vue'

export default defineComponent({
  name: 'EndUserClaimDeviceTable',
  components: {
    EndUserClaimDeviceRow,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    devices: {
      type: Array,
      default() {
        return []
      },
    },
  },
})
