import {
  ClaimsState,
  Claim,
  ClaimsSearch,
  ClaimDeviceReplacementCost,
  ClaimDeviceDeductible,
} from '@/types/claim'
import { ClaimDeductibleType } from '@/constants/claim'

export enum ClaimsMutations {
  LIST = 'list',
  ADD = 'add',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  FILTER = 'filter',
  SET_REPLACEMENT_COST = 'setReplacementCost',
  SET_DEDUCTIBLES = 'setDeductibles',
  SET_SCREEN_REPLACEMENT_DEDUCTIBLE = 'setScreenReplacementDeductible',
  SET_REPAIR_DEDUCTIBLE = 'setrepairDeductible',
  SET_REPLACEMENT_DEDUCTIBLE = 'setreplacementDeductible',
  SET_COVERAGE_DEDUCTIBLES = 'setCoverageDeductibles',
  CLEAN_DEDUCTIBLES = 'cleanDeductibles',
}

const mutations: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: ClaimsState, newData: any) => void
} = {}

mutations[ClaimsMutations.LIST] = (state: ClaimsState, data: Claim[]): void => {
  state.claims = [...data]
}

mutations[ClaimsMutations.ADD] = (state: ClaimsState, data: Claim): void => {
  state.claim = { ...data }
}

mutations[ClaimsMutations.FILTER] = (
  state: ClaimsState,
  filter: ClaimsSearch,
): void => {
  state.filter = { ...filter }
}

mutations[ClaimsMutations.CREATE] = (state: ClaimsState, data: Claim): void => {
  const claims = [...state.claims]
  claims.push({ ...data })
  state.claims = [...claims]
}

mutations[ClaimsMutations.UPDATE] = (state: ClaimsState, data: Claim): void => {
  const index = state.claims.findIndex((user: Claim) => user.id === data.id)
  state.claims[index] = { ...data }
}

mutations[ClaimsMutations.DELETE] = (state: ClaimsState, id: number): void => {
  state.claims = state.claims.filter((user: Claim) => user.id !== id)
}

mutations[ClaimsMutations.SET_REPLACEMENT_COST] = (
  state: ClaimsState,
  data: ClaimDeviceReplacementCost,
): void => {
  state.replacementCost = { ...data }
}

mutations[ClaimsMutations.SET_DEDUCTIBLES] = (
  state: ClaimsState,
  data: ClaimDeviceDeductible[],
): void => {
  state.deductibles = { ...data }
}

mutations[ClaimsMutations.SET_SCREEN_REPLACEMENT_DEDUCTIBLE] = (
  state: ClaimsState,
  data: ClaimDeviceDeductible[],
): void => {
  const deductible = data.find(
    (d) => d.type === ClaimDeductibleType.SCREEN_REPLACEMENT,
  )
  if (deductible) state.screenReplacementDeductible = deductible.value
}

mutations[ClaimsMutations.SET_REPAIR_DEDUCTIBLE] = (
  state: ClaimsState,
  data: ClaimDeviceDeductible[],
): void => {
  const deductible = data.find((d) => d.type === ClaimDeductibleType.REPAIR)
  if (deductible) state.repairDeductible = deductible.value
}

mutations[ClaimsMutations.SET_REPLACEMENT_DEDUCTIBLE] = (
  state: ClaimsState,
  data: ClaimDeviceDeductible[],
): void => {
  const deductible = data.find(
    (d) => d.type === ClaimDeductibleType.REPLACEMENT,
  )
  if (deductible) state.replacementDeductible = deductible.value
}

mutations[ClaimsMutations.SET_COVERAGE_DEDUCTIBLES] = (
  state: ClaimsState,
  data: ClaimDeviceDeductible[],
): void => {
  const deductible = data.find(
    (d) => d.type === ClaimDeductibleType.DEDUCTIBLES,
  )
  if (deductible) state.coverageDeductible = deductible.value
}

mutations[ClaimsMutations.CLEAN_DEDUCTIBLES] = (state: ClaimsState): void => {
  state.replacementDeductible = 0
  state.repairDeductible = 0
  state.screenReplacementDeductible = 0
  state.coverageDeductible = 0
}

export default mutations
