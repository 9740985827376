
import { defineComponent } from 'vue'
import Modal from '@/components/common/Modal.vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PartnerActions } from '@/store/modules/partner/actions'
import { addPaypalEmailValidationSchema } from '@/services/authentication/validations'
import { getNextMonth, getPreviousMonth } from '@/services/compensations'
import { getMonthName } from '@/utils'
import { CUT_DAY } from '@/constants/compensations'
import PayoutCompensationBody from '@/components/common/compensation/PayoutCompensationBody.vue'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'

export default defineComponent({
  name: 'PayoutInfo',
  props: {
    css: {
      type: String,
      default: '',
    },
  },
  components: {
    SpinnerLoading,
    Modal,
    Form,
    Field,
    ErrorMessage,
    PayoutCompensationBody,
  },
  data() {
    return {
      validationSchema: addPaypalEmailValidationSchema(),
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      updatePayoutEmail: false,
      cutDay: CUT_DAY,
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: { email: string }): Promise<any> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER}/${PartnerActions.UPDATE_PAYOUT_EMAIL}`,
          values,
        )
        this.updatePayoutEmail = false
        this.error = false
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    onToggleForm(): void {
      this.updatePayoutEmail = !this.updatePayoutEmail
    },
  },
  computed: {
    getPaypalEmail(): string {
      const email =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PAYPAL_EMAIL}`
        ]
      if (isEmpty(email)) {
        if (this.updatePayoutEmail) {
          return ''
        } else {
          return 'No email provided.'
        }
      } else {
        return email
      }
    },
    nextMonth: () => {
      let month = getNextMonth()
      return getMonthName(month)
    },
    prevMonth: () => {
      let month = getPreviousMonth()
      return getMonthName(month)
    },
    canViewCompensationAmount(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_ADMIN_PAYOUT_AMOUNT,
        PermissionValues.VIEW,
      )
    },
  },
})
