
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Modal',
  props: {
    centered: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    mandatory: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    size: {
      type: String,
      default: 'modal-lg',
    },
  },
  data() {
    return {
      initialScrollPosition: 0,
      documentHeight: 0,
    }
  },
  mounted() {
    this.initialScrollPosition = window.scrollY
    window.scroll(0, 0)
    this.documentHeight = window.document.body.clientHeight
  },
  unmounted() {
    window.scroll(0, this.initialScrollPosition)
  },
})
