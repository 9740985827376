
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { EndUser, EndUserState } from '@/types/end-user'
import { EndUsersGetters } from '@/store/modules/end-users/getters'
import { EndUsersActions } from '@/store/modules/end-users/actions'
import EndUserRow from '@/components/end-users/EndUserRow.vue'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'EndUserTable',
  components: {
    EndUserRow,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    recordsByPage: {
      type: Number,
      default: 1,
    },
    onDelete: {
      type: Function,
    },
    onAuthClaimAsUser: {
      type: Function,
    },
    showDeleteUser: {
      type: Boolean,
      default: false,
    },
    showAuthClaimAsUser: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewFirstPage(): void {
      if (this.endUserState.currentPage != this.endUserState.firstPage) {
        this.getEndUsersByPage(this.endUserState.firstPage)
      }
    },
    viewSecondPage(): void {
      if (this.endUserState.currentPage != this.endUserState.secondPage) {
        this.getEndUsersByPage(this.endUserState.secondPage)
      }
    },
    viewThirdPage(): void {
      if (this.endUserState.currentPage != this.endUserState.thirdPage) {
        this.getEndUsersByPage(this.endUserState.thirdPage)
      }
    },
    previousPage(): void {
      if (this.endUserState.currentPage > 1) {
        this.getEndUsersByPage(this.endUserState.currentPage - 1)
      }
    },
    nextPage(): void {
      if (this.endUserState.currentPage < this.endUserState.maxPage) {
        this.getEndUsersByPage(this.endUserState.currentPage + 1)
      }
    },
    getEndUsersByPage(currentPage: number): void {
      this.$store.dispatch(
        `${ModulesIdentifiers.END_USER}/${EndUsersActions.CURRENT_PAGE}`,
        currentPage,
      )
    },
  },
  computed: {
    endUsers(): EndUser[] {
      return this.$store.getters[
        `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USERS}`
      ]
    },
    endUsersByPage(): EndUser[] {
      return this.$store.getters[
        `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USERS_BY_PAGE}`
      ]
    },
    endUserState(): EndUserState {
      return this.$store.getters[
        `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USER_STATE}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
})
