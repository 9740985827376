import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_EyesOpen = _resolveComponent("EyesOpen")!
  const _component_EyesClosed = _resolveComponent("EyesClosed")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      class: _normalizeClass(_ctx.classNames),
      name: _ctx.name,
      id: _ctx.id,
      type: _ctx.showPassword ? 'text' : 'password'
    }, null, 8, ["class", "name", "id", "type"]),
    _createElementVNode("button", {
      class: "btn btn-outline-secondary",
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTogglePassword && _ctx.onTogglePassword(...args)))
    }, [
      (!_ctx.showPassword)
        ? (_openBlock(), _createBlock(_component_EyesOpen, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.showPassword)
        ? (_openBlock(), _createBlock(_component_EyesClosed, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ErrorMessage, {
      class: "invalid-feedback",
      name: _ctx.name
    }, null, 8, ["name"])
  ]))
}