import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyText = _resolveComponent("CopyText")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createElementVNode("span", null, [
        _createVNode(_component_CopyText, {
          text: _ctx.user.friendly_id
        }, null, 8, ["text"])
      ])
    ]),
    _createElementVNode("td", null, _toDisplayString(` ${_ctx.user.first_name} ${_ctx.user.last_name}`), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.user.position_title), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.user.email), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.getPartnerProperyName(_ctx.user.partner_property_id)), 1),
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.user.sold_phone_plans || 0), 1),
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.user.sold_akko_plans || 0), 1)
  ]))
}