import isEmpty from 'lodash/isEmpty'
import memoize from 'lodash/memoize'
import { DateTime } from 'luxon'
import { Store } from 'vuex'
import { State } from '@/types/vuex'
import {
  PartnerPropertySales,
  PartnerPropertyDaySales,
  MultiBarSeries,
  SalesSubState,
  MultiBarPayload,
  SalesAmountReport,
} from '@/types/sales'
import { FilterReport, ReportDataTemplateAmount } from '@/types/report'
import { ModulesIdentifiers } from '@/store'
import { SalesActions } from '@/store/modules/sales/actions'
import { SalesGetters } from '@/store/modules/sales/getters'
import { SalesMutations } from '@/store/modules/sales/mutations'
import { ReportGetters } from '@/store/modules/report/getters'
import { ReportMutations } from '@/store/modules/report/mutations'
import { ReportTypeNames, ReportsRange } from '@/store/modules/report'
import { SelectorOptions } from '@/constants/partner-property'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'

export const makeFilters = (filters: {
  [key: string]: string
}): { [key: string]: string } => {
  const { partnerPropertyId } = filters
  let filter = {}
  if (partnerPropertyId !== SelectorOptions.ALL) {
    filter = { property_id: partnerPropertyId }
  }
  return filter
}

export class Report {
  store: Store<State>

  constructor(store: Store<State>) {
    this.store = store
  }

  generateSeries = memoize((data: { [key: string]: PartnerPropertySales }) => {
    let categories: string[] = []
    let series: MultiBarSeries[] = []
    if (!isEmpty(data)) {
      const partners: string[] = Object.keys(data)
      const ranges: PartnerPropertyDaySales[][] = Object.values(data).map(
        (value: PartnerPropertySales) => value.range,
      )
      categories = ranges[0].map(({ date }) =>
        DateTime.fromISO(date).toFormat('LLL dd'),
      )
      series = partners.map((partnerPropertyId: string, index: number) => {
        const sales = ranges[index].map((range) => range.sales)
        return {
          name: this.getPartnerPropertyName(partnerPropertyId),
          data: sales,
        }
      })
    }

    return {
      categories,
      series,
    }
  })

  getPartnerPropertyName = (id: string): string => {
    const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES_NAMES}`
    const storeNames = this.store.getters[key]
    return storeNames[id]
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatchRequestReportSales = (params: { [key: string]: any }): void => {
    this.store.dispatch(
      `${ModulesIdentifiers.SALES}/${SalesActions.REQUEST_SALES}`,
      {
        params: { ...params, range: ReportsRange.LAST_7_DAYS },
        mutation: SalesMutations.SET_SALES_FILTER_TO_LAST_7_DAYS,
      },
    )
    this.store.dispatch(
      `${ModulesIdentifiers.SALES}/${SalesActions.REQUEST_SALES}`,
      {
        params: { ...params, range: ReportsRange.LAST_30_DAYS },
        mutation: SalesMutations.SET_SALES_FILTER_TO_LAST_30_DAYS,
      },
    )
    this.store.dispatch(
      `${ModulesIdentifiers.SALES}/${SalesActions.REQUEST_SALES}`,
      {
        params: { ...params, range: ReportsRange.LAST_MONTH },
        mutation: SalesMutations.SET_SALES_FILTER_TO_LAST_MONTH,
      },
    )
    this.store.dispatch(
      `${ModulesIdentifiers.SALES}/${SalesActions.REQUEST_SALES}`,
      {
        params: { ...params, range: ReportsRange.TODAY },
        mutation: SalesMutations.SET_SALES_FILTER_TO_LAST_TODAY,
      },
    )
    this.store.dispatch(
      `${ModulesIdentifiers.SALES}/${SalesActions.REQUEST_SALES}`,
      {
        params: { ...params, range: ReportsRange.TOTAL },
        mutation: SalesMutations.SET_SALES_FILTER_TO_TOTAL,
      },
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatchRequestReportSalesByTeam = (params: { [key: string]: any }): void => {
    this.store.dispatch(
      `${ModulesIdentifiers.SALES}/${SalesActions.REQUEST_SALES}`,
      {
        params: { ...params, range: ReportsRange.LAST_MONTH },
        mutation: params['product_report']
          ? SalesMutations.SET_PRODUCT_SALES_FILTER_TO_MONTH_BY_TEAM
          : SalesMutations.SET_SALES_FILTER_TO_MONTH_BY_TEAM,
      },
    )
  }

  dispatchRequestReportSalesCustomRange(params: {
    [key: string]: string
  }): void {
    this.store.dispatch(
      `${ModulesIdentifiers.SALES}/${SalesActions.REQUEST_SALES}`,
      {
        params: { ...params },
        mutation: SalesMutations.SET_SALES_FILTER_TO_CUSTOM_MONTH,
      },
    )
  }

  getReportSalesData = (getter: SalesGetters): MultiBarPayload => {
    const sales: SalesSubState =
      this.store.getters[`${ModulesIdentifiers.SALES}/${getter}`]
    return this.generateSeries(sales.data)
  }

  updateReportsStore = (
    key: string,
    payload: MultiBarPayload | ReportDataTemplateAmount,
  ): void => {
    this.store.commit(
      `${ModulesIdentifiers.REPORT}/${ReportMutations.SET_REPORTS}`,
      {
        [key]: { ...payload },
      },
    )
  }

  onReportSalesStateChange = ({ type }: { type: string }): void => {
    const last7days = `${ModulesIdentifiers.SALES}/${SalesMutations.SET_SALES_FILTER_TO_LAST_7_DAYS}`
    if (type === last7days) {
      const salesData: MultiBarPayload = this.getReportSalesData(
        SalesGetters.GET_LAST_7_DAYS,
      )
      this.updateReportsStore(ReportTypeNames.last7days, salesData)
    }
    const last30days = `${ModulesIdentifiers.SALES}/${SalesMutations.SET_SALES_FILTER_TO_LAST_30_DAYS}`
    if (type === last30days) {
      const salesData: MultiBarPayload = this.getReportSalesData(
        SalesGetters.GET_LAST_30_DAYS,
      )
      this.updateReportsStore(ReportTypeNames.last30days, salesData)
    }
    const lastmonth = `${ModulesIdentifiers.SALES}/${SalesMutations.SET_SALES_FILTER_TO_LAST_MONTH}`
    if (type === lastmonth) {
      const salesData: MultiBarPayload = this.getReportSalesData(
        SalesGetters.GET_LAST_MONTH,
      )
      this.updateReportsStore(ReportTypeNames.lastmonth, salesData)
    }
    const today = `${ModulesIdentifiers.SALES}/${SalesMutations.SET_SALES_FILTER_TO_LAST_TODAY}`
    if (type === today) {
      const salesData: MultiBarPayload = this.getReportSalesData(
        SalesGetters.GET_TODAY,
      )
      this.updateReportsStore(ReportTypeNames.today, salesData)
    }
    const total = `${ModulesIdentifiers.SALES}/${SalesMutations.SET_SALES_FILTER_TO_TOTAL}`
    if (type === total) {
      const salesData: { data: ReportDataTemplateAmount } =
        this.store.getters[
          `${ModulesIdentifiers.SALES}/${SalesGetters.GET_TOTAL}`
        ]
      this.updateReportsStore(ReportTypeNames.total, salesData.data)
    }
    const customMonth = `${ModulesIdentifiers.SALES}/${SalesMutations.SET_SALES_FILTER_TO_CUSTOM_MONTH}`
    if (type === customMonth) {
      const salesData: MultiBarPayload = this.getReportSalesData(
        SalesGetters.GET_CUSTOM_MONTH,
      )
      this.updateReportsStore(ReportTypeNames.customMonth, salesData)
    }
  }

  getLast7days(): MultiBarPayload {
    const getter = `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_REPORT_BY_TYPE}`
    return this.store.getters[getter](ReportTypeNames.last7days)
  }

  getLast30days(): MultiBarPayload {
    const getter = `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_REPORT_BY_TYPE}`
    return this.store.getters[getter](ReportTypeNames.last30days)
  }

  getLastmonth(): MultiBarPayload {
    const getter = `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_REPORT_BY_TYPE}`
    return this.store.getters[getter](ReportTypeNames.lastmonth)
  }

  getCustomMonth(): MultiBarPayload {
    const getter = `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_REPORT_BY_TYPE}`
    return this.store.getters[getter](ReportTypeNames.customMonth)
  }

  getToday(): MultiBarPayload {
    const getter = `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_REPORT_BY_TYPE}`
    return this.store.getters[getter](ReportTypeNames.today)
  }

  getTotal(): SalesAmountReport {
    const getter = `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_REPORT_BY_TYPE}`
    return this.store.getters[getter](ReportTypeNames.total)
  }

  getFilters(): FilterReport {
    const getter = `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_FILTER}`
    return this.store.getters[getter]
  }

  areReportsEmpty(): boolean {
    const last7days: MultiBarPayload = this.getLast7days()
    const last30days: MultiBarPayload = this.getLast30days()
    const lastmonth: MultiBarPayload = this.getLastmonth()
    if (isEmpty(last7days)) return true
    if (isEmpty(last30days)) return true
    if (isEmpty(lastmonth)) return true
    if (isEmpty(last7days.series)) return true
    if (isEmpty(last30days.series)) return true
    if (isEmpty(lastmonth.series)) return true
    return false
  }
}
