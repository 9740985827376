
import { defineComponent } from 'vue'
import { MARKETING_EMAIL } from '@/constants/marketing-materials'
import { FeatureFlagGetters } from '@/store/modules/feature-flag/getters'
import { FeatureFlagOptions } from '@/constants/feature-flag'
import { PartnerIFrames } from '@/constants/partner-iframes'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'

export default defineComponent({
  name: 'MarketingMaterials',
  setup() {
    return {
      MARKETING_EMAIL,
    }
  },
  computed: {
    isEnableMarketingMaterialsIframeFeature(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_PORTAL_MARKETING_MATERIALS_IFRAME)
    },
    iFrameMarketingMaterials(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_IFRAME_URL}`
      ](PartnerIFrames.TAB_CODE_MARKETING_MATERIALS)
    },
    hasPartnerMarketingMaterialViewIFramePermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_MARKETING_MATERIALS_VIEW_IFRAME,
        PermissionValues.ALLOW,
      )
    },
  },
})
