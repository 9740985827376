
import { defineComponent, PropType } from 'vue'
import { Product } from '@/types/product'
import CloseIcon from '@/components/common/icons/Close-Icon.vue'

export default defineComponent({
  name: 'NoDeviceProductPicker',
  components: { CloseIcon },
  setup(props: { products: Product[] }) {
    return {}
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    selectedProduct: {
      type: Object as PropType<Product | null>,
      required: false,
    },
    productGroupDisplayName: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: String as PropType<string>,
    },
  },
})
