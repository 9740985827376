
import { defineComponent } from 'vue'
import { RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'ResetYourPassword',
  setup() {
    return {
      RoutePath,
    }
  },
})
