import { TeamState, TeamUser } from '@/types/team'

export enum TeamMutations {
  LIST = 'list',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  SET_PREFERRED_PARTNER_PROPERTY_ID = 'setPreferredPartnerPropertyId',
}

const mutations: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: TeamState, newData: any) => void
} = {}

mutations[TeamMutations.LIST] = (state: TeamState, data: TeamUser[]): void => {
  state.users = [...data]
}

mutations[TeamMutations.CREATE] = (state: TeamState, data: TeamUser): void => {
  const users = [...state.users]
  users.push({ ...data })
  state.users = [...users]
}

mutations[TeamMutations.UPDATE] = (state: TeamState, data: TeamUser): void => {
  const index = state.users.findIndex((user: TeamUser) => user.id === data.id)
  state.users[index] = { ...data }
}

mutations[TeamMutations.DELETE] = (state: TeamState, id: number): void => {
  state.users = state.users.filter((user: TeamUser) => user.id !== id)
}

mutations[TeamMutations.SET_PREFERRED_PARTNER_PROPERTY_ID] = function (
  state: TeamState,
  preferredPartnerPropertyId: string,
): void {
  state.preferredPartnerPropertyId = preferredPartnerPropertyId
}

export default mutations
