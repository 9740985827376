
import { RouteNames, RoutePath } from '@/constants/router'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tab',
  setup() {
    return {
      RouteNames,
      RoutePath,
    }
  },
  methods: {
    isActiveClass(routeName: string): string {
      return this.$router.currentRoute.value.name === routeName
        ? 'btn-ligh'
        : 'btn-primary'
    },
    isActiveRoute(routeName: string): boolean {
      return this.$router.currentRoute.value.name === routeName
    },
    isActive(routeName: string): boolean {
      return this.$router.currentRoute.value.name === routeName
    },
  },
})
