
import { defineComponent, reactive, ref } from 'vue'
import isEmpty from 'lodash/isEmpty'
import ActivateWarrantyPlanSelector from '@/components/registration/ActivateWarrantyPlanSelector.vue'
import SummaryCard from '@/components/common/SummaryCard.vue'
import Modal from '@/components/common/Modal.vue'
import Checkout from '@/components/registration/Checkout.vue'
import { PlanIntervals, CoverageType } from '@/constants/coverages'
import {
  CoverageSelection,
  CoverageSelectionMap,
} from '@/types/plan-registration'
import { DiscountCode } from '@/types/discount-code'
import { ModulesIdentifiers, key } from '@/store'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import RegistrationSummary from '@/components/registration/RegistrationSummary.vue'
import ForceToCreatePartnerProperties from '@/components/common/ForceToCreatePartnerProperties.vue'
import { useStore } from 'vuex'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerState } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import RequireToAcceptTerms from '@/components/registration/RequireToAcceptTerms.vue'
import { PartnerProgramsIds } from '@/constants/partner-programs'
import { AppGetters } from '@/store/modules/app/getters'
import { UserState } from '@/types/user'
import PartnerStatusAlert from '@/components/registration/PartnerStatusAlert.vue'
import CoveragesCombo from '@/composables/coverages-combo'
import { TeamActions } from '@/store/modules/team/actions'

export default defineComponent({
  name: 'ActivateWarrantyPlan',
  components: {
    RequireToAcceptTerms,
    ActivateWarrantyPlanSelector,
    SummaryCard,
    Checkout,
    Modal,
    RegistrationSummary,
    ForceToCreatePartnerProperties,
    PartnerStatusAlert,
  },
  setup() {
    const store = useStore(key)
    const interval = ref(PlanIntervals.MONTHLY)
    const goToCheckout = ref(false)

    store.watch(
      (store) => store.planRegistration.registrationSummary,
      (value, oldValue) => {
        if (!oldValue && value) {
          goToCheckout.value = false
        }
      },
    )
    return {
      PlanIntervals,
      goToCheckout,
      interval,
      PartnerProgramsIds,
    }
  },
  data() {
    return {
      emptyProperty: false,
      coverageCombo: {},
      coverageTypeSelected: '',
    }
  },
  async mounted() {
    const selectionMap = reactive<CoverageSelectionMap>({
      indexer: 0,
      selections: {},
    })
    this.coverageCombo = new CoveragesCombo(selectionMap, this.$store)

    const partnerProperties = this.$store.getters[
      `${ModulesIdentifiers.USER}/${UserGetters.PARTNER_PROPERTIES_WITH_ACCESS}`
    ](`${PermissionScopes.REGISTER_PLAN}:${PermissionValues.ALLOW}`)
    this.emptyProperty = isEmpty(partnerProperties)
    await this.$store.dispatch(`${ModulesIdentifiers.TEAM}/${TeamActions.LIST}`)
  },
  methods: {
    setPlanInterval(newInterval: PlanIntervals): void {
      this.interval = newInterval
    },
    triggerRegistering(): void {
      this.coverageTypeSelected = ''
      this.goToCheckout = true
    },
    cancelRegistration(): void {
      this.goToCheckout = false
    },
    intervalSelected(interval: PlanIntervals): boolean {
      return interval == this.interval
    },
    addActivateWarranty(type: CoverageType) {
      this.coverageTypeSelected = type
      this.goToCheckout = true
    },
  },
  computed: {
    coverageSelection(): {
      plans: CoverageSelection[]
      discounts: DiscountCode[]
    } {
      return this.$store.state.planRegistration
    },
    registrationSummary(): {
      phoneNumber: string[]
      transactionId: string
    } | null {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.REGISTRATION_SUMMARY}`
      ]
    },

    partnerInformation(): PartnerState {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    partnerProgramId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM_ID}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    forceToCreatePartnerProperties(): boolean {
      return (
        this.emptyProperty &&
        (this.currentUser.termsAccepted ||
          this.partnerProgramId === PartnerProgramsIds.INJURED_GADGETS_ID)
      )
    },
    requireToAcceptTerms(): boolean {
      return (
        !this.currentUser.termsAccepted &&
        this.partnerProgramId !== PartnerProgramsIds.INJURED_GADGETS_ID
      )
    },
  },
})
