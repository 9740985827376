
import { ModulesIdentifiers } from '@/store'
import { ReportGetters } from '@/store/modules/report/getters'
import { SalesOverview } from '@/types/report'
import { defineComponent } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import { colors } from '@/constants/colors'
import { RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'SalesOverview',
  components: {
    VueApexCharts,
  },
  setup() {
    return {
      colors,
      RoutePath,
    }
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options(): any {
      return {
        chart: {
          type: 'donut',
          width: '100px',
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
        },
        colors: colors,
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      }
    },
    series(): number[] {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.SALES_OVERVIEW}`
      const salesOverview: SalesOverview[] = this.$store.getters[key]
      return salesOverview.map((sales: SalesOverview) => sales.percent)
    },
    totalSales(): number {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.SALES_OVERVIEW}`
      return this.$store.getters[key][0].total
    },
    salesOverview(): SalesOverview[] {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.SALES_OVERVIEW}`
      return this.$store.getters[key]
    },
  },
})
