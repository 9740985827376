
import { defineComponent } from 'vue'
import { DateTime } from 'luxon'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { isInvalid } from '@/utils'
import { claimSearchFormValidationSchema } from '@/services/authentication/validations'
import { ModulesIdentifiers } from '@/store'
import { ClaimActions } from '@/store/modules/claim/actions'
import { Claim, ClaimsSearch } from '@/types/claim'
import { ClaimGetters } from '@/store/modules/claim/getters'
import { showChat } from '@/services/intercom'
import { UserGetters } from '@/store/modules/user/getters'
import { UserState } from '@/types/user'
import { Partner, PartnerPermissions } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { ClaimStatus, ClaimStatusUserFriendly } from '@/constants/claim'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { RoutePath, RouteNames } from '@/constants/router'

export default defineComponent({
  name: 'Claims',
  components: {
    // ClaimsInstructions,
    InputMask,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      phoneMaskedValue: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      validationSchema: claimSearchFormValidationSchema(),
      ClaimStatus,
    }
  },
  setup() {
    return {
      RoutePath,
      RouteNames,
    }
  },
  methods: {
    isInvalid,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: ClaimsSearch): Promise<any> {
      if (!this.hasPermission) return true
      try {
        let action = `${ModulesIdentifiers.CLAIMS}/${ClaimActions.SEARCH}`
        await this.$store.dispatch(action, values)
        this.error = false
        this.errorMessage = ''

        if (this.claim && this.claim.devices) {
          this.$router.push({ name: RouteNames.HOME_CLAIMS_STATUS })
        }
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    formatDate: (date: string): string => {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)
    },
    formatLocal: (year: number, month: number, day: number): string => {
      return DateTime.local(year, month, day).toLocaleString(DateTime.DATE_MED)
    },
    contactAkkoTeam() {
      showChat()
    },
    getClaimStatusCaption(claim: Claim): string {
      if (
        claim.partnerStatus &&
        claim.partnerStatus in ClaimStatusUserFriendly
      ) {
        return ClaimStatusUserFriendly[claim.partnerStatus as ClaimStatus]
      } else {
        return 'To be defined'
      }
    },
    getBorderAccordingStatus(status: string | null): string {
      if (!status || status === ClaimStatus.COMPLETED) {
        return ''
      }

      if (status === ClaimStatus.APPROVED) {
        return 'container-status-proceed'
      }

      if (
        status === ClaimStatus.DENIED_FRAUD ||
        status === ClaimStatus.DENIED_NOT_A_COVERED_LOSS ||
        status === ClaimStatus.DENIED_NOT_VERIFIABLE
      ) {
        return 'container-status-denied'
      }

      if (
        status === ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED ||
        status === ClaimStatus.WAITING_ON_CUSTOMER ||
        status === ClaimStatus.WAITING_ON_REPAIR_PROVIDER
      ) {
        return 'container-status-waiting'
      }

      if (
        status === ClaimStatus.IN_REVIEW_AKKO ||
        status === ClaimStatus.INITIAL_REVIEW_AKKO ||
        status === ClaimStatus.NEW ||
        status === ClaimStatus.REPAIR_IN_PROCESS
      ) {
        return 'container-status-in-review'
      }

      return ''
    },
    isActive(path: string): boolean {
      return this.$router.currentRoute.value.name === path
    },
  },
  computed: {
    claim(): Claim {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.CLAIM}`
      ]
    },
    filter(): ClaimsSearch {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.FILTER}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    currentPartner(): Partner {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    hasPermission(): boolean {
      const key = `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_PERMISSIONS}`
      const permissions = this.$store.getters[key]
      const REGULAR_REPAIR = PermissionScopes.PARTNER_REPAIRS_REGULAR_REPAIR
      const REPAIRS_FIRST = PermissionScopes.PARTNER_REPAIRS_REPAIRS_FIRST
      const VALUE = PermissionValues.ALLOW
      const finding = (pp: PartnerPermissions) => {
        return (
          (pp.scope === REGULAR_REPAIR && pp.value === VALUE) ||
          (pp.scope === REPAIRS_FIRST && pp.value === VALUE)
        )
      }
      const hasPermission = permissions.find(finding)
      return !!hasPermission
    },
  },
})
