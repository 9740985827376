
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import Modal from '@/components/common/Modal.vue'
import Avatar from '@/components/account/Avatar.vue'
import UpdatePassword from '@/components/account/UpdatePassword.vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { isInvalid } from '@/utils'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { UserProfileFormValidationSchema } from '@/services/authentication/validations'
import { UserState, UserUpdateForm } from '@/types/user'
import { UserActions } from '@/store/modules/user/actions'
import CopyText from '@/components/common/CopyText.vue'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'UserProfile',
  components: {
    Modal,
    Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    Avatar,
    UpdatePassword,
    CopyText,
  },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  data() {
    return {
      validationSchema: UserProfileFormValidationSchema(),
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      formValues: {},
      updateProfile: false,
      updatePassword: false,
    }
  },
  methods: {
    isInvalid,
    onToggleForm(): void {
      this.updateProfile = !this.updateProfile
      this.formValues = this.fillDefaultValues()
      this.error = false
      this.errorMessage = ''
    },
    onTogglePassword(status: boolean): void {
      this.updatePassword = status
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: UserUpdateForm): Promise<any> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.UPDATE_USER}`,
          values,
        )
        this.updateProfile = false
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    fillDefaultValues() {
      const user =
        this.$store.getters[`${ModulesIdentifiers.USER}/${UserGetters.USER}`]
      return {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
      }
    },
  },
  computed: {
    user(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    partner() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    shouldResetPassword(): boolean {
      const resetPassword =
        this.$store.getters[
          `${ModulesIdentifiers.USER}/${UserGetters.RESET_PASSWORD}`
        ]
      return resetPassword
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
  },
})
