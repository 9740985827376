
import { defineComponent } from 'vue'
import EndUserClaimDeviceTable from '@/components/end-users/EndUserClaimDeviceTable.vue'
import EyesOpenIcon from '@/components/common/icons/Eyes-Open.vue'
import EyesClosedIcon from '@/components/common/icons/Eyes-Closed.vue'
import CopyText from '@/components/common/CopyText.vue'

export default defineComponent({
  name: 'EndUserClaimRow',
  props: {
    claim: {
      type: Object,
      required: true,
    },
  },
  components: {
    CopyText,
    EyesOpenIcon,
    EyesClosedIcon,
    EndUserClaimDeviceTable,
  },
  data() {
    return {
      showDevices: false,
    }
  },
  methods: {
    onViewDevices() {
      this.showDevices = !this.showDevices
    },
  },
})
