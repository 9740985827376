import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  state: {
    userToBeCreated: {
      fistName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      position: '',
      idNumber: '',
      partnerPropertyId: NaN,
    },
    users: [],
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
