import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-729906aa")
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["disabled"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DistanceUnitSelector = _resolveComponent("DistanceUnitSelector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(["form-control", _ctx.cssClasses]),
      type: "number",
      disabled: _ctx.disabled,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputValue = $event))
    }, null, 10, _hoisted_2), [
      [_vModelText, _ctx.inputValue]
    ]),
    _createVNode(_component_DistanceUnitSelector, {
      disabled: true,
      "default-value": _ctx.DistanceUnits.ml
    }, null, 8, ["default-value"])
  ]))
}