
import { defineComponent, PropType, toRefs, watch } from 'vue'
import {
  AkkoCoverageType,
  CoverageType,
  InjuredGadgetsCoverageType,
  PlanIntervals,
} from '@/constants/coverages'
import { CoverageSelection } from '@/types/plan-registration'
import CoveragesCombo from '@/composables/coverages-combo'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import PlanSelect from '@/components/common/PlanSelect.vue'
import CloseIcon from '@/components/common/icons/Close-Icon.vue'
import PlanInfo from '@/components/common/PlanInfo.vue'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { coveragesGetters } from '@/store/modules/coverages/getters'

export default defineComponent({
  name: 'IndividualPlanSelector',
  components: { PlanInfo, CloseIcon, PlanSelect },
  setup(props: {
    planInterval: PlanIntervals
    coverageSelection: CoverageSelection[]
    coverageCombo: CoveragesCombo
  }) {
    const { planInterval, coverageSelection, coverageCombo } = toRefs(props)

    watch(planInterval, () =>
      coverageCombo.value.recalculateCoverages(planInterval.value),
    )

    watch(
      coverageSelection,
      (newValues, prevValues) => {
        if (newValues?.length === 0 && prevValues?.length > 0) {
          coverageCombo.value.selectionMap.indexer = 0
          coverageCombo.value.selectionMap.selections = {}
          coverageCombo.value.recalculateCoverages(planInterval.value)
        }
      },
      { deep: true },
    )

    coverageCombo.value.recalculateCoverages(planInterval.value)
    return {
      CoverageType,
      AkkoCoverageType,
      InjuredGadgetsCoverageType,
      PartnerPrograms,
      PlanIntervals,
    }
  },
  methods: {
    isCoverageType(type: CoverageType | null): boolean {
      return this.coverageCombo.coverageType === type
    },
    addPhonePlan() {
      this.coverageCombo.addPhonePlan(this.planInterval)
    },
    addAkkoPlan() {
      this.coverageCombo.addAkkoPlan(this.planInterval)
    },
    getDisplayName(planCoverage: any): string {
      const display_name = planCoverage[PlanIntervals.MONTHLY].displayName
      const price = planCoverage[PlanIntervals.MONTHLY].price
      return display_name.replace('{price}', price.toString())
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
    hasPartnerSalesRegisterPlanAkkoPlansPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_PLAN_AKKO_PLANS,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerSalesRegisterPlanPhonePlansPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_PLAN_PHONE_PLANS,
        PermissionValues.ALLOW,
      )
    },
    planBundledIGCoverage() {
      return this.$store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.INJURED_GADGETS_COVERAGES}`
      ](InjuredGadgetsCoverageType.PLAN_BUNDLED)
    },
    planBundledProIGCoverage() {
      return this.$store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.INJURED_GADGETS_COVERAGES}`
      ](InjuredGadgetsCoverageType.PLAN_PRO)
    },
    planPhoneIGCoverage() {
      return this.$store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.INJURED_GADGETS_COVERAGES}`
      ](InjuredGadgetsCoverageType.PLAN_PHONE)
    },
    planFoldablePhoneIGCoverage() {
      return this.$store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.INJURED_GADGETS_COVERAGES}`
      ](InjuredGadgetsCoverageType.PLAN_FOLDABLE_PHONE)
    },
    coverages() {
      return this.$store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.COVERAGES}`
      ]
    },
  },
  props: {
    planInterval: {
      type: String as PropType<PlanIntervals>,
      required: true,
    },
    coverageSelection: {
      type: Array as PropType<CoverageSelection[]>,
      required: true,
    },
    coverageCombo: {
      type: Object as PropType<CoveragesCombo>,
      required: true,
    },
  },
})
