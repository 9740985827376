import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-f1464fe2")
const _hoisted_1 = { class: "font-size-75-rem" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "font-size-75-rem" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyText = _resolveComponent("CopyText")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createElementVNode("span", null, [
        _createVNode(_component_CopyText, {
          text: _ctx.singleTimePayout.phone
        }, null, 8, ["text"])
      ])
    ]),
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.singleTimePayout.item_covered), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.singleTimePayout.code_name || ''), 1),
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.singleTimePayout.discount_amount || 0), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.singleTimePayout.final_amount || 0), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.singleTimePayout.date_created), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.singleTimePayout.payment_release_date), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.singleTimePayout.payable_status_name ==
        _ctx.PayableDocumentStatusOptions.PAID
          ? _ctx.PaymentFrontendOptions.PAID
          : _ctx.PaymentFrontendOptions.NOT_PAID), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(`${_ctx.singleTimePayout.sales_rep_first_name} ${_ctx.singleTimePayout.sales_rep_last_name}`), 1)
  ]))
}