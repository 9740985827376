
import { defineComponent } from 'vue'
import CopyText from '@/components/common/CopyText.vue'

export default defineComponent({
  name: 'EndUserClaimDeviceRow',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  components: {
    CopyText,
  },
})
