import {
  PartnerPropertyState,
  PartnerPropertyStore,
  RepairDeviceType,
  SellDeviceType,
  CellularServiceType,
} from '@/types/partner-property'

export enum PartnerPropertyMutations {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  SET_PROPERTIES = 'setProperties',
  SET_PROPERTIES_SALES_ACCESS = 'setPropertiesSalesAccess',
  SET_PARTNER_ID = 'setPartnerId',
  SET_PAYPAL_EMAIL = 'setPaypalEmail',
  SET_REPAIR_DEVICES = 'setRepairDevices',
  SET_SELL_DEVICES = 'setSellDevices',
  SET_CELLULAR_SERVICES = 'setCellularServices',
}

const mutations: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: PartnerPropertyState, newData: any) => void
} = {}

mutations[PartnerPropertyMutations.READ] = (
  state: PartnerPropertyState,
  id: number,
): void => {
  // todo
}

mutations[PartnerPropertyMutations.CREATE] = (
  state: PartnerPropertyState,
  newValues: PartnerPropertyStore,
): void => {
  const properties = [...state.properties]
  properties.push({ ...newValues })
  state.properties = [...properties]
}

mutations[PartnerPropertyMutations.UPDATE] = (
  state: PartnerPropertyState,
  data: PartnerPropertyStore,
): void => {
  const index = state.properties.findIndex(
    (store: PartnerPropertyStore) => store.id === data.id,
  )
  state.properties[index] = { ...data }
}

mutations[PartnerPropertyMutations.DELETE] = (
  state: PartnerPropertyState,
  id: number,
): void => {
  state.properties = state.properties.filter(
    (store: PartnerPropertyStore) => store.id !== id,
  )
}

mutations[PartnerPropertyMutations.SET_PROPERTIES] = (
  state: PartnerPropertyState,
  properties: PartnerPropertyStore[],
): void => {
  state.properties = properties
  const tmpStoreNames: { [key: string]: string } = {}
  properties.map((store: PartnerPropertyStore) => {
    tmpStoreNames[store.id as number] = store.name
  })
  state.propertiesNames = { ...tmpStoreNames }
}

mutations[PartnerPropertyMutations.SET_PROPERTIES_SALES_ACCESS] = (
  state: PartnerPropertyState,
  properties: PartnerPropertyStore[],
): void => {
  state.propertiesSalesAccess = properties
}

mutations[PartnerPropertyMutations.SET_PARTNER_ID] = (
  state: PartnerPropertyState,
  partnerId: number,
): void => {
  state.partnerId = partnerId
}

mutations[PartnerPropertyMutations.SET_PAYPAL_EMAIL] = (
  state: PartnerPropertyState,
  paypalEmail: string,
): void => {
  state.paypalEmail = paypalEmail
}

mutations[PartnerPropertyMutations.SET_REPAIR_DEVICES] = (
  state: PartnerPropertyState,
  deviceTypes: RepairDeviceType[],
): void => {
  state.repairDeviceTypes = deviceTypes
}

mutations[PartnerPropertyMutations.SET_SELL_DEVICES] = (
  state: PartnerPropertyState,
  deviceTypes: SellDeviceType[],
): void => {
  state.sellDeviceTypes = deviceTypes
}

mutations[PartnerPropertyMutations.SET_CELLULAR_SERVICES] = (
  state: PartnerPropertyState,
  cellularServiceTypes: CellularServiceType[],
): void => {
  state.cellularServiceTypes = cellularServiceTypes
}

export default mutations
