import { CoverageState } from '@/types/coverage'

export enum CoveragesMutations {
  SET_COVERAGES = 'setCoverages',
  SET_DEVICES = 'setDevices',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mutations: any = {}

mutations[CoveragesMutations.SET_DEVICES] = function (
  state: CoverageState,
  rawDevices: never[],
): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state.devices = rawDevices.map((rd: any) => ({
    id: rd.id,
    displayOrder: rd.display_order,
    maker: rd.make,
    model: rd.model,
    monthlyCoverageId: rd.monthly_coverage_id,
    annualCoverageId: rd.annual_coverage_id,
    replacementCost: rd.replacement_cost,
    repairDeductible: rd.repair_deductible,
    replacementDeductible: rd.replacement_deductible,
    screenReplacementDeductible: rd.screen_replacement_deductible,
  }))
}

mutations[CoveragesMutations.SET_COVERAGES] = function (
  state: CoverageState,
  rawCoverages: never[],
): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state.coverages = rawCoverages.map((rc: any) => ({
    deductible: rc.deductible,
    id: rc.id,
    isFeatured: rc.is_featured,
    itemCovered: rc.item_covered,
    maxCoverage: rc.max_coverage,
    name: rc.name,
    planInterval: rc.plan_interval,
    planType: rc.plan_type,
    price: rc.price,
    tags: rc.tags || [],
    displayName: rc.display_name,
  }))
}

export default mutations
