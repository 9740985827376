import {
  SalesState,
  SalesSubState,
  SalesSubStateFixedAmount,
  SalesSubStateByTeam,
} from '@/types/sales'

export enum SalesGetters {
  GET_TOTAL = 'getTotal',
  GET_LAST_7_DAYS = 'getLast7days',
  GET_LAST_30_DAYS = 'getLast30days',
  GET_LAST_MONTH = 'getLastMonth',
  GET_CUSTOM_MONTH = 'getCustomMonth',
  GET_TODAY = 'getToday',
  GET_CUSTOM_MONTH_BY_TEAM = 'getCustomMonthByTeam',
  GET_PRODUCT_CUSTOM_MONTH_BY_TEAM = 'getProductCustomMonthByTeam',
}

const getters: { [key: string]: (state: SalesState) => void } = {}

getters[SalesGetters.GET_LAST_7_DAYS] = (state: SalesState): SalesSubState => {
  return state.last7days
}

getters[SalesGetters.GET_LAST_30_DAYS] = (state: SalesState): SalesSubState => {
  return state.last30days
}

getters[SalesGetters.GET_LAST_MONTH] = (state: SalesState): SalesSubState => {
  return state.lastmonth
}

getters[SalesGetters.GET_CUSTOM_MONTH] = (state: SalesState): SalesSubState => {
  return state.customMonth
}

getters[SalesGetters.GET_TODAY] = (state: SalesState): SalesSubState => {
  return state.today
}

getters[SalesGetters.GET_TODAY] = (state: SalesState): SalesSubState => {
  return state.today
}

getters[SalesGetters.GET_TOTAL] = (
  state: SalesState,
): SalesSubStateFixedAmount => {
  return state.total
}

getters[SalesGetters.GET_CUSTOM_MONTH_BY_TEAM] = (
  state: SalesState,
): SalesSubStateByTeam => {
  return state.customMonthByTeam
}

export default getters
