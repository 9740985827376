import { State } from '@/types/vuex'
import { MutationPayload, Store } from 'vuex'
import { PlanRegistrationMutations } from '@/store/modules/plan-registration/mutations'
import { PlanRegistrationActions } from '@/store/modules/plan-registration/actions'
import { ModulesIdentifiers } from '@/store'
import {
  BULK_DISCOUNT_CODE,
  MULTIPLAN_DISCOUNT_CODE,
} from '@/constants/plan-registration'
import { PartnerPrograms } from '@/constants/partner-programs'
import { ProductsMutations } from '@/store/modules/products/mutations'
import { ProductGetters } from '@/store/modules/products/getters'

export const toggleMultiplanDiscount = (
  mutation: MutationPayload,
  state: State,
  store: Store<State>,
): void => {
  if (state[ModulesIdentifiers.APP].partnerProgram != PartnerPrograms.AKKO)
    return

  if (
    mutation.type !=
    `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PLAN_SELECTION}`
  )
    return

  if (
    state[ModulesIdentifiers.PLAN_REGISTRATION].plans.length > 1 &&
    state[ModulesIdentifiers.PLAN_REGISTRATION].plans.length < 10
  ) {
    store.dispatch(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.ADD_DISCOUNT_CODE}`,
      {
        code: MULTIPLAN_DISCOUNT_CODE,
        alias: 'Multi-plan Discount',
      },
    )
  }

  if (state[ModulesIdentifiers.PLAN_REGISTRATION].plans.length <= 1) {
    store.commit(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.REMOVE_DISCOUNT_CODE}`,
      MULTIPLAN_DISCOUNT_CODE,
    )
  }

  if (state[ModulesIdentifiers.PLAN_REGISTRATION].plans.length >= 10) {
    store.commit(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.REMOVE_DISCOUNT_CODE}`,
      MULTIPLAN_DISCOUNT_CODE,
    )
    store.dispatch(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.ADD_DISCOUNT_CODE}`,
      {
        code: BULK_DISCOUNT_CODE,
        alias: 'Bulk discount',
      },
    )
  }

  if (state[ModulesIdentifiers.PLAN_REGISTRATION].plans.length < 10) {
    store.commit(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.REMOVE_DISCOUNT_CODE}`,
      BULK_DISCOUNT_CODE,
    )
  }
}

const toggleMultiProductDiscount = (
  mutation: MutationPayload,
  state: State,
  store: Store<State>,
): void => {
  if (state[ModulesIdentifiers.APP].partnerProgram != PartnerPrograms.AKKO)
    return

  const defineProductInCart = `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.DEFINE_PRODUCT_ON_CART}`
  const defineProductInCartFromMakeAndModel = `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.DEFINE_PRODUCT_ON_CART_FROM_MODEL_AND_MAKE}`
  const removeIndividualProductFromCart = `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.REMOVE_PRODUCT_FROM_CART}`

  if (
    mutation.type != defineProductInCart &&
    mutation.type != defineProductInCartFromMakeAndModel &&
    mutation.type != removeIndividualProductFromCart
  )
    return

  const productsInCart =
    store.getters[
      `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_CART_FLAT_LIST}`
    ]

  if (productsInCart.length > 1 && productsInCart.length < 10) {
    store.dispatch(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.ADD_DISCOUNT_CODE}`,
      {
        code: MULTIPLAN_DISCOUNT_CODE,
        alias: 'Multi-plan Discount',
      },
    )
  }

  if (productsInCart.length <= 1) {
    store.commit(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.REMOVE_DISCOUNT_CODE}`,
      MULTIPLAN_DISCOUNT_CODE,
    )
  }

  if (productsInCart.length >= 10) {
    store.commit(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.REMOVE_DISCOUNT_CODE}`,
      MULTIPLAN_DISCOUNT_CODE,
    )
    store.dispatch(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.ADD_DISCOUNT_CODE}`,
      {
        code: BULK_DISCOUNT_CODE,
        alias: 'Bulk discount',
      },
    )
  }

  if (productsInCart.length < 10) {
    store.commit(
      `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.REMOVE_DISCOUNT_CODE}`,
      BULK_DISCOUNT_CODE,
    )
  }
}

export default [toggleMultiplanDiscount, toggleMultiProductDiscount]
