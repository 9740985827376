
import { RouteNames } from '@/constants/router'
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerActions } from '@/store/modules/partner/actions'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { UserActions } from '@/store/modules/user/actions'

export default defineComponent({
  name: 'RequireToAcceptTerms',
  components: {
    SpinnerLoading,
  },
  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    rejectTerms(): void {
      this.$router.push({ name: RouteNames.HOME_MENU })
    },
    async acceptTerms(): Promise<void> {
      this.isSubmitting = true
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.ACCEPT_TERMS_AND_CONDITIONS}`,
        )
      } catch (e) {
        this.isSubmitting = false
      }
    },
  },
})
