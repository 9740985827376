import { TeamState, TeamUser } from '@/types/team'

export enum TeamGetters {
  USER = 'user',
  USERS = 'users',
  GET_PREFERRED_PARTNER_PROPERTY_ID = 'getPreferredPartnerPropertyId',
}

const getters: { [key: string]: (state: TeamState) => void } = {}

getters[TeamGetters.USER] =
  (state: TeamState) =>
  (id: number): TeamUser | undefined => {
    return state.users.find((store: TeamUser) => store.id === id)
  }

getters[TeamGetters.USERS] = (state: TeamState): TeamUser[] => {
  return state.users
}

getters[TeamGetters.GET_PREFERRED_PARTNER_PROPERTY_ID] = (
  state: TeamState,
): string => {
  return state.preferredPartnerPropertyId
}

export default getters
