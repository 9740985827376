
import { defineComponent, PropType } from 'vue'
import ProductInfo from '@/components/common/ProductInfo.vue'
import NoDeviceProductPicker from '@/components/common/NoDeviceProductPicker.vue'
import {
  Product,
  ProductGroupMarketingAttributes,
  ProductInCart,
} from '@/types/product'
import { ModulesIdentifiers } from '@/store'
import { ProductsMutations } from '@/store/modules/products/mutations'
import { ProductGetters } from '@/store/modules/products/getters'
import DeviceProductPicker from '@/components/common/DeviceProductPicker.vue'

export default defineComponent({
  name: 'ProductSelect',
  components: { DeviceProductPicker, ProductInfo, NoDeviceProductPicker },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    partnerProgram: {
      type: String,
      default: '',
    },
    planInterval: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    another: {
      type: Boolean,
      default: false,
    },
    marketingProperties: {
      type: Object as PropType<ProductGroupMarketingAttributes>,
    },
    doesSelectionRequireItemSelection: {
      type: Boolean,
      default: false,
    },
    productGroupName: {
      type: String,
    },
  },
  data() {
    return {
      includeOpen: false,
    }
  },
  methods: {
    toggleInclude() {
      this.includeOpen = !this.includeOpen
    },
    submitProductSelection(productId: string, index: number) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.DEFINE_PRODUCT_ON_CART}`,
        {
          index,
          product: this.products.find((p) => p.id === productId),
          productGroupName: this.productGroupName,
        },
      )
    },
    submitMakeAndModelSelection(
      { make, model }: { make: string; model: string },
      index: number,
    ) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.DEFINE_PRODUCT_ON_CART_FROM_MODEL_AND_MAKE}`,
        {
          productGroupName: this.productGroupName,
          index,
          make,
          model,
        },
      )
    },
    addProductToCart() {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.ADD_PRODUCT_TO_CART}`,
        this.productGroupName,
      )
    },
    removeProductSelection(product: ProductInCart) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.REMOVE_PRODUCT_FROM_CART}`,
        product.uiId,
      )
    },
    removeNullProductSelection(index: number) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.REMOVE_NULL_PRODUCT_FROM_CART}`,
        { index, groupName: this.productGroupName },
      )
    },
  },
  computed: {
    productsInCart(): Array<Product | null> {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_ON_PRODUCT_CART}`
      ](this.productGroupName)
    },
  },
})
