import { PartnerState, PartnerIFrame } from '@/types/partner'

export enum PartnerMutations {
  SET_PARTNER = 'setPartner',
  SET_PARTNER_ID = 'setPartnerId',
  SET_PAYPAL_EMAIL = 'setPaypalEmail',
  SET_DISTRIBUTOR_SLUG = 'setDistributorSlug',
  SET_SETTINGS = 'setSettings',
  SET_PARTNER_IFRAMES = 'setPartnerIFrames',
}

const mutations: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: PartnerState, newData: any) => void
} = {}

mutations[PartnerMutations.SET_PARTNER] = (
  state: PartnerState,
  partner: PartnerState,
): void => {
  state.partnerId = partner.id
  state.friendlyId = partner.friendlyId
  state.payoutEmail = partner.payoutEmail
  state.name = partner.name
  state.website = partner.website
  state.countryId = partner.countryId
  state.countryName = partner.countryName
  state.currencyId = partner.currencyId
  state.currencyName = partner.currencyName
  state.currencySymbol = partner.currencySymbol
  state.currencyCode = partner.currencyCode
  state.partnerTypeId = partner.partnerTypeId
  state.partnerType = partner.partnerType
  state.totalNumberOfPlansSold = partner.totalNumberOfPlansSold
  state.totalNumberOfActivePlansSold = partner.totalNumberOfActivePlansSold
  state.mostRecentPlanSold = partner.mostRecentPlanSold
  state.firstPlanSold = partner.firstPlanSold
  state.salesPast30 = partner.salesPast30
  state.discountCodes = partner.discountCodes
  state.permissions = partner.permissions
}

mutations[PartnerMutations.SET_PARTNER_ID] = (
  state: PartnerState,
  partnerId: number,
): void => {
  state.partnerId = partnerId
}

mutations[PartnerMutations.SET_PAYPAL_EMAIL] = (
  state: PartnerState,
  paypalEmail: string,
): void => {
  state.payoutEmail = paypalEmail
}

mutations[PartnerMutations.SET_DISTRIBUTOR_SLUG] = (
  state: PartnerState,
  slug: string,
): void => {
  state.distributorSlug = slug
}

mutations[PartnerMutations.SET_SETTINGS] = (
  state: PartnerState,
  settings: { [key: string]: boolean },
): void => {
  state.settings = settings
}

mutations[PartnerMutations.SET_PARTNER_IFRAMES] = (
  state: PartnerState,
  partnerIFrames: PartnerIFrame[],
): void => {
  state.partnerIFrames = partnerIFrames
}

export default mutations
