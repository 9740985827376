
import { defineComponent } from 'vue'
import PayoutCompensationTitle from '@/components/common/compensation/PayoutCompensationTitle.vue'
import PayoutCompensationBody from '@/components/common/compensation/PayoutCompensationBody.vue'

export default defineComponent({
  name: 'PayoutCompensation',
  props: {
    cssContainer: {
      type: String,
      default: '',
    },
    cssBody: {
      type: String,
      default: '',
    },
  },
  components: {
    PayoutCompensationTitle,
    PayoutCompensationBody,
  },
})
