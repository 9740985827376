
import { defineComponent } from 'vue'
import PlanInfo from '@/components/common/PlanInfo.vue'
import { CoverageType } from '@/constants/coverages'

export default defineComponent({
  name: 'PlanSelect',
  components: { PlanInfo },
  props: {
    partnerProgram: {
      type: String,
      default: '',
    },
    planInterval: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    btnClick: {
      type: Function,
      default: () => {
        console.log('btnClick')
      },
    },
    another: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      includeOpen: false,
    }
  },
  computed: {
    isAKKOPlan() {
      return this.type === CoverageType.AKKO
    },
    isPhonePlan() {
      return this.type === CoverageType.phone
    },
    isBusinessPlan() {
      return this.type === CoverageType.BUSINESS_PLAN
    },
    isHomeRegularPlan() {
      return this.type === CoverageType.HOME_ELECTRONIC_REGULAR_PLAN
    },
    isHomePlusPlan() {
      return this.type === CoverageType.HOME_ELECTRONIC_PLUS_PLAN
    },
  },
  methods: {
    toggleInclude() {
      this.includeOpen = !this.includeOpen
    },
  },
})
