
import { defineComponent } from 'vue'
import Clipboard from '@/components/common/icons/Clipboard-Icon.vue'
import { formatPhone } from '@/utils'

export default defineComponent({
  name: 'CopyText',
  components: {
    Clipboard,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    needFormatPhone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copied: false,
      timer: 0,
    }
  },
  methods: {
    formatPhone,
    onCopy() {
      this.copied = true
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.copied = false
      }, 900)
      navigator.clipboard.writeText(this.text)
    },
  },
})
