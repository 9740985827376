
import DeleteIcon from '@/components/common/icons/Delete-Icon.vue'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { defineComponent, PropType } from 'vue'
import { MapsSearchInputMode } from '@/constants/google-maps'

export default defineComponent({
  name: 'RemovableGoogleMapsItem',
  components: { DeleteIcon, GoogleMapsInput },
  emits: ['placeSelected', 'onChange', 'onDelete'],
  props: {
    name: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    id: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    placeHolder: {
      type: String as PropType<string>,
      required: false,
      default: 'Search for a location',
    },
    cssClasses: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    value: {
      type: String as PropType<string>,
      default: '',
    },
    initialValue: {
      type: String as PropType<string>,
      default: '',
    },
    inputType: {
      type: String as PropType<MapsSearchInputMode>,
      required: false,
      default: MapsSearchInputMode.PLACE,
    },
    preventDeletion: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
})
