
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { CompensationActions } from '@/store/modules/compensation/actions'
import { CompensationGetters } from '@/store/modules/compensation/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { SingleTimePayoutFilter, SingleTimePayout } from '@/types/compensation'
import { DateTime } from 'luxon'
import LastTwelveMonthsSelector from '@/components/common/LastTwelveMonthsSelector.vue'
import AkkoCard from '@/components/common/AkkoCard.vue'
import PayoutCompensationDetailRow from '@/components/common/compensation/PayoutCompensationDetailRow.vue'
import PayoutCompensationDetailSearch from '@/components/common/compensation/PayoutCompensationDetailSearch.vue'
import { getNextMonth } from '@/services/compensations'

export default defineComponent({
  name: 'PayoutCompensationDetail',
  components: {
    AkkoCard,
    LastTwelveMonthsSelector,
    PayoutCompensationDetailRow,
    PayoutCompensationDetailSearch,
  },
  props: {
    isExternalContainer: {
      type: Boolean,
      default: true,
    },
    cssContainer: {
      type: String,
      default: '',
    },
    cssBody: {
      type: String,
      default: '',
    },
  },
  emits: ['onClose'],
  mounted() {
    let current = DateTime.now()
    if (current.day >= 15) {
      current = getNextMonth()
    }
    this.getSingleTimePayouts(current.month, current.year)
  },
  data() {
    return {
      error: false,
      errorMessage: '' as string,
      loading: true as boolean,
    }
  },
  methods: {
    async getSingleTimePayouts(monthIn: number, yearIn: number): Promise<void> {
      try {
        const singleTimePayoutFilter: SingleTimePayoutFilter = {
          month: monthIn,
          year: yearIn,
        }
        await this.$store.dispatch(
          `${ModulesIdentifiers.COMPENSATION}/${CompensationActions.GET_SINGLE_TIME_PAYOUTS}`,
          singleTimePayoutFilter,
        )
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    async handleChangeOnMonthSelection(param: { month: number; year: number }) {
      this.getSingleTimePayouts(param.month, param.year)
    },
  },
  computed: {
    listSingleTimePayouts(): SingleTimePayout[] {
      const key = `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED}`
      return this.$store.getters[key]
    },
    singlePayoutsSearchQuantity(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED_QUANTITY}`
      ]
    },
    singlePayoutsSummaryAmount(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SUMMARY_AMOUNT}`
      ]
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
  },
})
