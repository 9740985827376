import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    partnerId: null,
    payoutEmail: null,
    distributorSlug: null,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
