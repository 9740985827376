<template>
  <div>
    <header>
      <slot name="header"></slot>
    </header>
    <div class="card">
      <div class="card-body">
        <slot name="main"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AkkoCard',
}
</script>

<style scoped></style>
