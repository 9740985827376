import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "value", "name", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    placeholder: _ctx.placeHolder,
    class: _normalizeClass(_ctx.cssClasses),
    value: _ctx.value,
    name: _ctx.name,
    id: _ctx.id,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
    onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
    ref: "placesInput"
  }, null, 42, _hoisted_1))
}