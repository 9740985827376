
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SpinnerLoading',
  props: {
    css: {
      type: String,
      default: '',
    },
  },
})
