
import HomeIcon from '@/components/common/icons/Home-Icon.vue'
import SalesIcon from '@/components/common/icons/Sales-Icons.vue'
import SettingsIcon from '@/components/common/icons/Settings-Icon.vue'
import MessageIcon from '@/components/common/icons/Message-Icon.vue'
import PeopleIcon from '@/components/common/icons/People-Icon.vue'
import GridIcon from '@/components/common/icons/Grid-Icon.vue'
import FaqIcon from '@/components/common/icons/Faq-Icon.vue'
import MarketingIcon from '@/components/common/icons/Marketing-Icon.vue'
import ClaimsIcon from '@/components/common/icons/Claims-Icon.vue'
import YoutubeIcon from '@/components/common/icons/Youtube-Icon.vue'
import UsersIcon from '@/components/common/icons/Users-Icon.vue'
import { RouteNames, RoutePath } from '@/constants/router'
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { showChat } from '@/services/intercom'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { FeatureFlagGetters } from '@/store/modules/feature-flag/getters'
import { FeatureFlagOptions } from '@/constants/feature-flag'

export default defineComponent({
  name: 'Navigation',
  components: {
    HomeIcon,
    SalesIcon,
    MessageIcon,
    FaqIcon,
    PeopleIcon,
    GridIcon,
    MarketingIcon,
    ClaimsIcon,
    SettingsIcon,
    YoutubeIcon,
    UsersIcon,
  },
  setup() {
    return {
      PartnerPrograms,
      RouteNames,
      RoutePath,
      showChat,
    }
  },
  methods: {
    isActive(navigationOption: string | string[]): boolean {
      if (Array.isArray(navigationOption)) {
        for (let option in navigationOption) {
          if (navigationOption[option] === this.$router.currentRoute.value.name)
            return true
        }
        return false
      } else {
        return this.$router.currentRoute.value.name == navigationOption
      }
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
    accessTeams(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.ACCESS_TEAMS}`
      ]
    },
    accessProperties(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.ACCESS_PROPERTIES}`
      ]
    },
    accessSales(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.ACCESS_SALES}`
      ]
    },
    accessEndUsers(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_PORTAL_END_USERS, PermissionValues.VIEW)
    },
    hasPartnerRegisterNewPlanViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_REGISTER_NEW_PLAN_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerSalesReportViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_SALES_REPORT_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerClaimsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_CLAIMS_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerTeamViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_TEAM_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerCustomersViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_CUSTOMERS_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPropertiesViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_PROPERTIES_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerMarketingMaterialsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_MARKETING_MATERIALS_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerSettingsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_SETTINGS_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerTrainingViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_TRAINING_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerFaqsInfoViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_FAQS_INFO_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPortalRegisterNewPlanViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_REGISTER_NEW_PLAN_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalSalesReportViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_SALES_REPORT_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalClaimsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_PORTAL_CLAIMS_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPortalTeamViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_PORTAL_TEAM_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPortalCustomersViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_CUSTOMERS_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalPropertiesViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_PROPERTIES_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalMarketingMaterialsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_MARKETING_MATERIALS_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalSettingsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_SETTINGS_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalTrainingViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_TRAINING_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalFaqsInfoViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_FAQS_INFO_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerBusinessAndSchoolViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_BUSINESS_AND_SCHOOL_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalBusinessAndSchoolViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_BUSINESS_AND_SCHOOL_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    isEnableBusinessAndSchoolFeature(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_PORTAL_BUSINESS_AND_SCHOOL_TAB)
    },
  },
})
