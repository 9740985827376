import { ActionContext } from 'vuex'
import axios from 'axios'
import { ReportState } from '@/types/report'
import { State as RootState } from '@/types/vuex'
import { SALES_OVERVIEW } from '@/services/api'
import { parseNetworkErrorMessage } from '@/utils/index'
import { ReportMutations } from './mutations'

export enum ReportActions {
  SALES_OVERVIEW = 'salesOverview',
}

const actions: {
  [key: string]: (
    context: ActionContext<ReportState, RootState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ) => void
} = {}

actions[ReportActions.SALES_OVERVIEW] = async (
  context: ActionContext<ReportState, RootState>,
) => {
  try {
    const response = await axios.get(`${SALES_OVERVIEW}`)
    const { data } = response.data
    context.commit(ReportMutations.SET_SALES_OVERVIEW, data)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

export default actions
