
import { key, ModulesIdentifiers } from '@/store'
import {
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
  toRefs,
  watch,
} from 'vue'
import { UserWithAccessToPartnerProperty } from '@/types/plan-registration'
import { useStore } from 'vuex'
import { PlanRegistrationActions } from '@/store/modules/plan-registration/actions'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import { maskLastName, maskPhoneNumber } from '@/utils'

export default defineComponent({
  name: 'SalesRepresentativeSelector',
  props: {
    partnerPropertyId: {
      type: Number as PropType<number>,
    },
    validate: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    name: {
      type: String as PropType<string>,
      required: false,
      default: 'Sales representative selector',
    },
    autoSelect: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    preferredSalesRepId: {
      type: Number as PropType<number>,
      required: false,
      default: NaN,
    },
    userId: {
      type: Number as PropType<number>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  emits: ['changeSelection'],
  setup(props: any) {
    const store = useStore(key)
    const { partnerPropertyId, autoSelect } = toRefs(props)
    const instance = getCurrentInstance()

    watch(partnerPropertyId, async () => {
      await store.dispatch(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.GET_USERS_WITH_ACCESS_TO_PARTNER_PROPERTY}`,
        partnerPropertyId.value,
      )
    })

    store.watch(
      (store) => store.planRegistration.usersWithAccessToPartnerProperty,
      (value) => {
        if (value.length > 0 && autoSelect) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          instance?.proxy.autoSelectSalesRepresentative()
        }
      },
      { deep: true },
    )

    return {
      loading: ref(true),
    }
  },
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      userSelected: null as any,
    }
  },
  methods: {
    maskLastName,
    maskPhoneNumber,
    onChangePartnerProperty(): void {
      this.$emit('changeSelection', this.userSelected)
    },
    autoSelectSalesRepresentative() {
      let salesRep
      if (this.preferredSalesRepId && !isNaN(this.preferredSalesRepId)) {
        salesRep =
          this.$store.state.planRegistration.usersWithAccessToPartnerProperty.find(
            (user: UserWithAccessToPartnerProperty) =>
              user.userId === this.preferredSalesRepId,
          )
      } else {
        salesRep =
          this.$store.state.planRegistration.usersWithAccessToPartnerProperty.find(
            (user: UserWithAccessToPartnerProperty) =>
              user.userId === this.userId,
          )
      }
      if (salesRep) {
        this.userSelected = salesRep
        return this.$emit('changeSelection', salesRep)
      }
    },
    isOptionSelected(optionId: number): boolean {
      return optionId === this.userSelected
    },
  },
  computed: {
    usersWithAccess(): UserWithAccessToPartnerProperty[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.USER_WITH_ACCESS_TO_PP}`
      ]
    },
  },
})
