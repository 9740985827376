import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-63e01b40")
const _hoisted_1 = { class: "auth-container" }
const _hoisted_2 = { class: "card-body p-4" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_akko_header = _resolveComponent("akko-header")!
  const _component_AuthTab = _resolveComponent("AuthTab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_akko_header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(`card ${_ctx.css}`)
      }, [
        _createVNode(_component_AuthTab),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ], 2)
    ])
  ], 64))
}