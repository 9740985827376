import { ActionContext } from 'vuex'
import axios from 'axios'
import { PartnerState } from '@/types/partner'
import { State as RootState } from '@/types/vuex'
import {
  PARTNER,
  PARTNER_STRATEGY_UI_SETTINGS,
  PARTNER_IFRAMES,
} from '@/services/api'
import { PartnerMutations } from '@/store/modules/partner/mutations'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { parseNetworkErrorMessage, pushAlert } from '@/utils'
import { AlertType } from '@/constants/alerts'

export enum PartnerActions {
  READ_PARTNER = 'readPartner',
  READ_PAYOUT_EMAIL = 'readPayoutEmail',
  UPDATE_PAYOUT_EMAIL = 'updatePayoutEmail',
  GET_PARTNER_SETTINGS = 'gerPartnerSettings',
  GET_PARTNER_IFRAMES = 'gerPartnerIframes',
}

const actions: {
  [key: string]: (
    context: ActionContext<PartnerState, RootState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ) => void
} = {}

actions[PartnerActions.READ_PARTNER] = async (
  context: ActionContext<PartnerState, RootState>,
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<any> => {
  try {
    const { data } = await axios.get(`${PARTNER}partner-basic-data/`)
    const {
      id,
      friendly_id,
      payout_email,
      name,
      website,
      country_id,
      country_name,
      currency_id,
      currency_name,
      currency_symbol,
      currency_code,
      partner_type_id,
      total_number_of_plans_sold,
      total_number_of_plans_sold_that_are_active,
      most_recent_plan_sold,
      first_plan_sold,
      sales_past30,
      partner_type,
      discount_codes,
      permissions,
    } = data.data
    context.commit(PartnerMutations.SET_PARTNER, {
      id,
      friendlyId: friendly_id,
      payoutEmail: payout_email,
      name: name,
      website: website,
      countryId: country_id,
      countryName: country_name,
      currencyId: currency_id,
      currencyName: currency_name,
      currencySymbol: currency_symbol,
      currencyCode: currency_code,
      totalNumberOfPlansSold: total_number_of_plans_sold,
      totalNumberOfActivePlansSold: total_number_of_plans_sold_that_are_active,
      mostRecentPlanSold: most_recent_plan_sold,
      firstPlanSold: first_plan_sold,
      salesPast30: sales_past30,
      partnerTypeId: partner_type_id,
      partnerType: partner_type,
      permissions: permissions,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      discountCodes: discount_codes.map((rawCodePayload: any) => ({
        code: rawCodePayload.code,
        codeName: rawCodePayload.code_name,
        amountType: rawCodePayload.amount_type,
        amount: rawCodePayload.amount,
        numberMonths: rawCodePayload.number_months,
        discountLevel: rawCodePayload.discount_Level,
        coupon: rawCodePayload.coupon,
        alias: rawCodePayload.alias
          ? rawCodePayload.alias
          : rawCodePayload.code_name,
        disclaimer: rawCodePayload.disclaimer,
      })),
    })
    context.dispatch(PartnerActions.GET_PARTNER_SETTINGS).then()
    context.dispatch(PartnerActions.GET_PARTNER_IFRAMES).then()
    return data
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerActions.READ_PAYOUT_EMAIL] = async (
  context: ActionContext<PartnerState, RootState>,
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<any> => {
  const partnerId = context.getters[PartnerGetters.GET_PARTNER_ID]
  try {
    const { data } = await axios.get(`${PARTNER}${partnerId}/payout-email/`)
    const { email } = data.data
    context.commit(PartnerMutations.SET_PAYPAL_EMAIL, email)
    return data
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}
actions[PartnerActions.UPDATE_PAYOUT_EMAIL] = async (
  context: ActionContext<PartnerState, RootState>,
  { email }: { email: string },
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<any> => {
  const partnerId = context.getters[PartnerGetters.GET_PARTNER_ID]
  const payload = { email, partnerId }
  try {
    await axios.patch(`${PARTNER}${partnerId}/payout-email/`, payload)
    context.commit(PartnerMutations.SET_PAYPAL_EMAIL, email)
    const SUCCESS_MESSAGE = 'PayPal Email Added'
    pushAlert(context, SUCCESS_MESSAGE, AlertType.SUCCESS)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerActions.GET_PARTNER_SETTINGS] = async (
  context: ActionContext<PartnerState, RootState>,
): Promise<void> => {
  try {
    const url = PARTNER_STRATEGY_UI_SETTINGS.replace(
      '{1}',
      context.state.partnerId.toString(),
    )
    const response = await axios.get(url)
    context.commit(
      PartnerMutations.SET_SETTINGS,
      response.data.data.ui_settings,
    )
  } catch (err) {
    context.commit(PartnerMutations.SET_SETTINGS, {})
  }
}

actions[PartnerActions.GET_PARTNER_IFRAMES] = async (
  context: ActionContext<PartnerState, RootState>,
): Promise<void> => {
  try {
    const url = PARTNER_IFRAMES.replace(
      '{0}',
      context.state.partnerId.toString(),
    )
    const response = await axios.get(url)
    context.commit(PartnerMutations.SET_PARTNER_IFRAMES, response.data.data)
  } catch (err) {
    context.commit(PartnerMutations.SET_PARTNER_IFRAMES, [])
  }
}

export default actions
