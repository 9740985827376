import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueApexCharts = _resolveComponent("VueApexCharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueApexCharts, {
      type: "bar",
      height: "350",
      options: _ctx.getChartOptions,
      series: _ctx.getSeries
    }, null, 8, ["options", "series"])
  ]))
}