
import { defineComponent, PropType } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import isEmpty from 'lodash/isEmpty'
import { isInvalid } from '@/utils'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyStore } from '@/types/partner-property'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { GooglePlaceResponse } from '@/types/app'
import { partnerPropertyPhysicalStoreFormValidationSchema } from '@/services/authentication/validations'
import { PartnerPropertyType } from '@/types/app'
import RepairDeviceTypeSelector from '@/components/common/RepairDeviceTypeSelector.vue'
import SellDeviceTypeSelector from '@/components/common/SellDeviceTypeSelector.vue'
import CellularServiceTypeSelector from '@/components/common/CellularServiceTypeSelector.vue'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'physical-store-property-form',
  components: {
    Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
    GoogleMapsInput,
    RepairDeviceTypeSelector,
    SellDeviceTypeSelector,
    CellularServiceTypeSelector,
  },
  props: {
    initialValues: {
      type: Object as PropType<PartnerPropertyStore | null>,
      default: null,
    },
    propertyType: {
      type: Object as PropType<PartnerPropertyType>,
      required: true,
    },
    isNewProperty: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  emits: ['onCancel'],
  data() {
    return {
      error: false,
      errorMessage: '',
      phoneMaskedValue: this.initialValues?.phone || '',
      placeValue: this.initialValues?.place || '',
      // eslint-disable-next-line
      placeDetails: null as any,
      formValues: this.setInitialValues(),
      validPlace: true,
      validPlaceTimeId: 0,
      validationSchema: partnerPropertyPhysicalStoreFormValidationSchema(),
    }
  },
  methods: {
    isEmpty,
    isInvalid,
    setInitialValues() {
      if (isEmpty(this.initialValues)) return {}
      let obj: Omit<PartnerPropertyStore, 'id'> = {
        property_type: null as any,
        friendly_id: this.initialValues?.friendly_id || '',
        place: this.initialValues?.place || '',
        email: this.initialValues?.email || '',
        name: this.initialValues?.name || '',
        phone: this.initialValues?.phone || '',
        website: this.initialValues?.website || undefined,
        provides_repairs: this.initialValues?.provides_repairs ? true : false,
        repair_devices: this.initialValues?.provides_repairs
          ? this.initialValues.repair_devices
          : [],
        provides_sells: this.initialValues?.provides_sells ? true : false,
        sell_devices: this.initialValues?.sell_devices
          ? this.initialValues.sell_devices
          : [],
        place_details: null,
        provides_sell_cellular_services: this.initialValues
          ?.provides_sell_cellular_services
          ? true
          : false,
        cellular_services: this.initialValues?.cellular_services
          ? this.initialValues.cellular_services
          : [],
      }
      obj = { ...obj, ...this.initialValues }
      if (this.initialValues?.repair_devices) {
        obj['repair_devices'] =
          this.initialValues?.repair_devices.map((value: any) =>
            value.repair_device_id.toString(),
          ) || []
      }
      if (this.initialValues?.sell_devices) {
        obj['sell_devices'] =
          this.initialValues?.sell_devices.map(
            (value: any) => value.sell_device_id,
          ) || []
      }
      if (this.initialValues?.cellular_services) {
        obj['cellular_services'] =
          this.initialValues?.cellular_services.map(
            (value: any) => value.mobile_operator_id,
          ) || []
      }
      return obj
    },
    handlePlaceInput(place: GooglePlaceResponse) {
      this.placeValue = `${place.name} ${place.formatted_address}`
      this.placeDetails = {
        name: place.name,
        formatted_address: place.formatted_address,
        business_status: place.business_status,
        formatted_phone_number: place.formatted_phone_number,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        icon: place.icon,
        icon_background_color: place.icon_background_color,
        icon_mask_base_uri: place.icon_mask_base_uri,
        international_phone_number: place.international_phone_number,
        place_id: place.place_id,
        price_level: place.price_level,
        rating: place.rating,
        reference: place.reference,
        url: place.url,
        user_ratings_total: place.user_ratings_total,
        utc_offset_minutes: place.utc_offset_minutes,
        vicinity: place.vicinity,
        website: place.website,
      }
      this.validPlace = true
      clearTimeout(this.validPlaceTimeId)
    },
    async onSubmit(values: PartnerPropertyStore) {
      if (!this.validPlace) return
      try {
        let action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.CREATE}`
        let data = {
          ...values,
          property_type: this.propertyType,
          place_details: this.placeDetails,
        }
        if (!this.isNewProperty) {
          action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.UPDATE}`
          data = { ...data, id: this.initialValues?.id }
        }
        await this.$store.dispatch(action, data)
        this.$emit('onCancel')
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    onChange(): void {
      this.validPlaceTimeId = setTimeout(() => {
        this.validPlace = false
      }, 1000)
    },
  },
  computed: {
    isInjuredGadgets(): boolean {
      return (
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
        ] === PartnerPrograms.INJURED_GADGETS
      )
    },
  },
})
