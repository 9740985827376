
import { defineComponent } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { formatPhone, isInvalid } from '@/utils'
import { singleTimePayoutSearchValidationSchema } from '@/services/compensation/validations'
import { ModulesIdentifiers } from '@/store'
import { SingleTimePayoutSearch } from '@/types/compensation'
import { CompensationActions } from '@/store/modules/compensation/actions'
import { CompensationGetters } from '@/store/modules/compensation/getters'

export default defineComponent({
  name: 'PayoutCompensationDetailSearch',
  components: {
    InputMask,
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    hasPermission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneMaskedValue: '',
      serial_number: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      hasFiltered: false,
      validationSchema: singleTimePayoutSearchValidationSchema(),
    }
  },
  methods: {
    isInvalid,
    formatPhone,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: SingleTimePayoutSearch): Promise<any> {
      try {
        let action = `${ModulesIdentifiers.COMPENSATION}/${CompensationActions.FILTER_SINGLE_TIME_PAYOUTS}`
        await this.$store.dispatch(action, values)
        this.hasFiltered = Boolean(values.phone)
        this.error = false
        this.errorMessage = ''
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onClear(): Promise<any> {
      try {
        let action = `${ModulesIdentifiers.COMPENSATION}/${CompensationActions.CLEAN_FILTER_SINGLE_TIME_PAYOUTS}`
        await this.$store.dispatch(action)
        this.hasFiltered = false
        this.phoneMaskedValue = ''
        this.error = false
        this.errorMessage = ''
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
  },
  computed: {
    singlePayoutsQuantity(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_QUANTITY}`
      ]
    },
    singlePayoutsSearchQuantity(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED_QUANTITY}`
      ]
    },
  },
})
