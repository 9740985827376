import { ActionContext } from 'vuex'
import axios from 'axios'
import { FeatureFlagState } from '@/types/feature-flag'
import { State as RootState } from '@/types/vuex'
import { GET_FEATURE_FLAGS } from '@/services/api'
import { FeatureFlagMutations } from '@/store/modules/feature-flag/mutations'
import { parseNetworkErrorMessage } from '@/utils'

export enum FeatureFlagActions {
  GET_FEATURE_FLAGS_ACCESS = 'getFeatureFlagsAccess',
}

const actions: {
  [key: string]: (
    context: ActionContext<FeatureFlagState, RootState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ) => void
} = {}

actions[FeatureFlagActions.GET_FEATURE_FLAGS_ACCESS] = async (
  context: ActionContext<FeatureFlagState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.get(`${GET_FEATURE_FLAGS}`)
    const { data } = response.data
    context.commit(FeatureFlagMutations.SET_FEATURE_FLAGS_ACCESS, data)
    return data
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

export default actions
