
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClaimsInstructionsApproved',
  props: {
    replacementCost: {
      type: Number,
      required: true,
    },
    screenReplacementDeductible: {
      type: Number,
      required: true,
    },
    repairDeductible: {
      type: Number,
      required: true,
    },
    replacementDeductible: {
      type: Number,
      required: true,
    },
    coverageDeductible: {
      type: Number,
      required: true,
    },
  },
})
