import { Store } from 'vuex'
import { State } from '@/types/vuex'
import planRegistrationHooks from '@/store/modules/plan-registration/hooks'

export const BusinessLogicEngine = (store: Store<State>): void => {
  // called when the store is initialized
  store.subscribe((mutation, state) => {
    // called after every mutation.
    // The mutation comes in the format of `{ type, payload }`.
    planRegistrationHooks.forEach((hook) => hook(mutation, state, store))
  })
}
