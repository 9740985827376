
import { defineComponent, PropType } from 'vue'
import { CoverageSummaryItem } from '@/types/coverages-summary'
import { CoverageType, PlanIntervals } from '@/constants/coverages'
import { DiscountCode } from '@/types/discount-code'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'

export default defineComponent({
  name: 'SelectedPlanInfo',
  setup() {
    return { CoverageType }
  },
  props: {
    summaryItem: {
      type: Object as PropType<CoverageSummaryItem>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getSummaryItemTitle(summaryItem: CoverageSummaryItem): string {
      return `${
        summaryItem.type == CoverageType.phone
          ? `${summaryItem.phoneMaker} ${summaryItem.phoneModel}`
          : summaryItem.type == CoverageType.AKKO
          ? summaryItem.akkoCoverage
              ?.replace('- Annual', '')
              ?.replace('- Monthly', '')
          : summaryItem.coverageName
      }`
    },
    getSummaryItemPrice(summaryItem: CoverageSummaryItem): string {
      const interval =
        summaryItem.interval == PlanIntervals.ANNUAL.toLocaleLowerCase()
          ? '/ year'
          : '/ month'
      return `${this.partnerCurrencySymbol()}${summaryItem.price.toFixed(
        2,
      )} ${interval}`
    },
    getSummaryItemCaption(summaryItem: CoverageSummaryItem): string {
      const main =
        summaryItem.type == CoverageType.phone
          ? `${summaryItem.phoneMaker} ${summaryItem.phoneModel}`
          : summaryItem.type == CoverageType.AKKO
          ? summaryItem.akkoCoverage
          : summaryItem.coverageName
      const interval =
        summaryItem.interval == PlanIntervals.ANNUAL.toLocaleLowerCase()
          ? '/yr.'
          : '/mo.'
      const priceEach = summaryItem.count > 1 ? ' each' : ''
      return `${this.partnerCurrencySymbol()}${summaryItem.price.toFixed(
        2,
      )} ${interval} - ${main} ${priceEach}`
    },
    getDiscountItemCaption(discount: DiscountCode): string {
      if (discount.amountType == 'PERCENT') {
        return `${discount.amount}% ${discount.alias} applied`
      }
      return ''
    },
    getDiscountItemAmountCaption(ds: [DiscountCode, number]): string {
      return `${this.partnerCurrencySymbol()}${ds[1].toFixed(2)} (${
        ds[0].alias
      })`
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
  },
})
