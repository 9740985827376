
import { defineComponent, PropType } from 'vue'
import { ClaimStatus } from '@/constants/claim'
import { RoutePath, RouteNames } from '@/constants/router'
import { Claim } from '@/types/claim'
import { ModulesIdentifiers } from '@/store'
import { ClaimGetters } from '@/store/modules/claim/getters'
import { ClaimActions } from '@/store/modules/claim/actions'
import { showChat } from '@/services/intercom'
import { UserState } from '@/types/user'
import { Partner } from '@/types/partner'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import ClaimsInstructionsByDevice from '@/components/claim/ClaimsInstructionsByDevice.vue'

export default defineComponent({
  name: 'ClaimsInstructions',
  components: {
    ClaimsInstructionsByDevice,
  },
  async mounted() {
    if (this.claim && this.claim.devices) {
      const initialDeviceId = this.claim.devices[0].id
      await this.activeTab(`Tab${initialDeviceId}`, initialDeviceId)
    }
  },
  setup() {
    return {
      ClaimStatus,
      RoutePath,
      RouteNames,
    }
  },
  data() {
    return {
      tabNameActive: '',
      hasFinishedLoading: false,
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
    }
  },
  props: {
    claimStatus: {
      type: String as PropType<ClaimStatus>,
      required: true,
    },
  },
  computed: {
    claim(): Claim {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.CLAIM}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    currentPartner(): Partner {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
  },
  methods: {
    contactAkkoTeam() {
      showChat()
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async activeTab(tabName: string, deviceId: number): Promise<any> {
      this.hasFinishedLoading = false
      this.tabNameActive = tabName
      try {
        let action = `${ModulesIdentifiers.CLAIMS}/${ClaimActions.GET_REPLACEMENT_COST_BY_DEVICE}`
        await this.$store.dispatch(action, deviceId)

        action = `${ModulesIdentifiers.CLAIMS}/${ClaimActions.GET_DEDUCTIBLES_BY_DEVICE}`
        let deductiblesFilter = {
          claimId: this.claim.id,
          deviceId: deviceId,
          userId: this.claim.filingUser.id,
        }
        await this.$store.dispatch(action, deductiblesFilter)
        this.error = false
        this.errorMessage = ''
        this.hasFinishedLoading = true
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    isActiveTab(tabName: string): boolean {
      return this.tabNameActive === tabName
    },
  },
})
