
import { defineComponent, PropType } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PlanIntervals } from '@/constants/coverages'
import { ProductGetters } from '@/store/modules/products/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PartnerSettings } from '@/constants/partner-settings'

export default defineComponent({
  name: 'ProductInfo',
  props: {
    marketingProperties: {
      type: Object,
    },
    open: {
      type: Boolean,
      default: false,
    },
    productGroupName: {
      type: String,
    },
    wereProductAddedToCard: {
      type: Boolean as PropType<boolean>,
    },
  },
  data() {
    return {
      includeOpen: false,
    }
  },
  setup() {
    return {
      PlanIntervals,
    }
  },
  methods: {
    toggleInclude() {
      this.includeOpen = !this.includeOpen
    },
    formattedNumberIntegerPart(num: number): number {
      const numToWork = num / 100
      return Math.floor(numToWork)
    },
    formattedNumberDecimalPart(num: number): string {
      const numToWork = num / 100
      const decimal = numToWork - Math.floor(numToWork)
      if (decimal == 0) return ''
      return `.${decimal.toFixed(2).substring(2)}`
    },
  },
  computed: {
    productPriceRange(): { min: number; max: number } {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PLAN_GROUP_PRICE_RANGE_PER_MONTH}`
      ](this.productGroupName)
    },
    deductiblePriceRange(): { min: number; max: number } {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PLAN_GROUP_DEDUCTIBLE_RANGE}`
      ](this.productGroupName)
    },
    hidePriceRange(): boolean {
      return !!this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_SETTING_VALUE}`
      ](PartnerSettings.PARTNER_PORTAL_HIDE_PRICE_RANGE)
    },
  },
})
