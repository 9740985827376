
import { defineComponent } from 'vue'
import CopyText from '@/components/common/CopyText.vue'
import EndUserClaimTable from '@/components/end-users/EndUserClaimTable.vue'
import EndUserDeviceTable from '@/components/end-users/EndUserDeviceTable.vue'
import EyesOpenIcon from '@/components/common/icons/Eyes-Open.vue'
import EyesClosedIcon from '@/components/common/icons/Eyes-Closed.vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'

export default defineComponent({
  name: 'EndUserRow',
  props: {
    endUser: {
      type: Object,
      required: true,
    },
    onDelete: {
      type: Function,
    },
    onAuthClaimAsUser: {
      type: Function,
    },
    showDeleteUser: {
      type: Boolean,
      default: false,
    },
    showAuthClaimAsUser: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CopyText,
    EndUserClaimTable,
    EndUserDeviceTable,
    EyesOpenIcon,
    EyesClosedIcon,
  },
  data() {
    return {
      showClaims: false,
      showDevices: false,
      deleting: false,
    }
  },
  methods: {
    onDeleting(deleting: boolean) {
      this.deleting = deleting
    },
    onViewClaims() {
      this.showClaims = !this.showClaims
    },
    onViewDevices() {
      this.showDevices = !this.showDevices
    },
  },
  computed: {
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
  },
})
