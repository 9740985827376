
import { defineComponent } from 'vue'
import { formatPhone } from '@/utils'
import { claimSearchFormValidationSchema } from '@/services/authentication/validations'
import { ModulesIdentifiers } from '@/store'
import { Claim } from '@/types/claim'
import { ClaimGetters } from '@/store/modules/claim/getters'
import { showChat } from '@/services/intercom'
import { UserGetters } from '@/store/modules/user/getters'
import { UserState } from '@/types/user'
import { Partner, PartnerPermissions } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { ClaimStatus, ClaimStatusUserFriendly } from '@/constants/claim'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import ClaimsInstructions from '@/components/claim/ClaimsInstructions.vue'
import ClaimsInstructionsHeader from '@/components/claim/ClaimsInstructionsHeader.vue'

export default defineComponent({
  name: 'ClaimStatusAndUpdates',
  components: {
    ClaimsInstructions,
    ClaimsInstructionsHeader,
  },
  data() {
    return {
      phoneMaskedValue: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      validationSchema: claimSearchFormValidationSchema(),
      ClaimStatus,
    }
  },
  methods: {
    formatPhone,
    contactAkkoTeam() {
      showChat()
    },
    getClaimStatusCaption(claim: Claim): string {
      if (
        claim.partnerStatus &&
        claim.partnerStatus in ClaimStatusUserFriendly
      ) {
        return ClaimStatusUserFriendly[claim.partnerStatus as ClaimStatus]
      } else {
        return 'To be defined'
      }
    },
    getBorderAccordingStatus(status: string | null): string {
      if (!status || status === ClaimStatus.COMPLETED) {
        return ''
      }

      if (status === ClaimStatus.APPROVED) {
        return 'container-status-proceed'
      }

      if (
        status === ClaimStatus.DENIED_FRAUD ||
        status === ClaimStatus.DENIED_NOT_A_COVERED_LOSS ||
        status === ClaimStatus.DENIED_NOT_VERIFIABLE
      ) {
        return 'container-status-denied'
      }

      if (
        status === ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED ||
        status === ClaimStatus.WAITING_ON_CUSTOMER ||
        status === ClaimStatus.WAITING_ON_REPAIR_PROVIDER
      ) {
        return 'container-status-waiting'
      }

      if (
        status === ClaimStatus.IN_REVIEW_AKKO ||
        status === ClaimStatus.INITIAL_REVIEW_AKKO ||
        status === ClaimStatus.NEW ||
        status === ClaimStatus.REPAIR_IN_PROCESS
      ) {
        return 'container-status-in-review'
      }

      return ''
    },
  },
  computed: {
    claim(): Claim {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.CLAIM}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    currentPartner(): Partner {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
  },
})
