
import { defineComponent } from 'vue'
import Modal from '@/components/common/Modal.vue'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import LoadingIcon from '@/components/common/icons/Loading-Icon.vue'

export default defineComponent({
  name: 'Loading',
  components: {
    Modal,
    LoadingIcon,
  },
  computed: {
    message(): string {
      return (
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.LOADING_MESSAGE}`
        ] || 'Loading ...'
      )
    },
  },
})
