import { ActionContext } from 'vuex'
import { CoverageState } from '@/types/coverage'
import { State as RootState } from '@/types/vuex'
import { CoveragesMutations } from '@/store/modules/coverages/mutations'
import axios from 'axios'
import {
  GET_COVERAGES,
  GET_PHONE_MODELS_AND_RELATED_COVERAGES,
} from '@/services/api'

export enum CoveragesActions {
  LOAD_COVERAGES = 'loadCoverages',
  LOAD_DEVICES = 'loadDevices',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actions: any = {}

actions[CoveragesActions.LOAD_COVERAGES] = async function (
  context: ActionContext<CoverageState, RootState>,
): Promise<void> {
  try {
    const payload = {
      currency_id: context.rootState.partner.currencyId,
    }
    const result = await axios.get(GET_COVERAGES, { params: payload })
    context.commit(CoveragesMutations.SET_COVERAGES, result.data)
  } catch (err) {
    context.commit(CoveragesMutations.SET_COVERAGES, [])
    // TODO Log error and notify user
  }
}

actions[CoveragesActions.LOAD_DEVICES] = async function (
  context: ActionContext<CoverageState, RootState>,
): Promise<void> {
  try {
    const payload = {
      currency_id: context.rootState.partner.currencyId,
    }
    const result = await axios.get(GET_PHONE_MODELS_AND_RELATED_COVERAGES, {
      params: payload,
    })
    context.commit(CoveragesMutations.SET_DEVICES, result.data)
  } catch (err) {
    context.commit(CoveragesMutations.SET_DEVICES, [])
    // TODO Log error and notify user
  }
}

export default actions
