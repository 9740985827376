import { ActionContext } from 'vuex'
import axios from 'axios'
import { SalesState, RequestFilter } from '@/types/sales'
import { State as RootState } from '@/types/vuex'
import { REPORT_SALES } from '@/services/api'
import { parseNetworkErrorMessage, stringfyParams } from '@/utils/index'
import { ModulesIdentifiers } from '@/store'
import { AppActions } from '../app/actions'
import { v4 as uuidv4 } from 'uuid'

export enum SalesActions {
  REQUEST_SALES = 'requestSales',
}

const actions: {
  [key: string]: (
    context: ActionContext<SalesState, RootState>,
    filter: RequestFilter,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>
} = {}

actions[SalesActions.REQUEST_SALES] = async (
  context: ActionContext<SalesState, RootState>,
  { params, mutation }: RequestFilter,
) => {
  const action = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const operation = { id: uuidv4(), show: false }
  try {
    context.dispatch(action, { ...operation, show: true }, { root: true })
    const queryString = stringfyParams(params)
    const response = await axios.get(`${REPORT_SALES}?${queryString}`)
    const { data } = response
    context.commit(mutation, { data })
    context.dispatch(action, operation, { root: true })
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

export default actions
