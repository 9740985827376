import {
  DeviceModelCatalog,
  Product,
  ProductInCart,
  ProductState,
} from '@/types/product'

export enum ProductGetters {
  GET_PLANS_FROM_CATALOG = 'getPlansFromCatalog',
  GET_PLANS_FROM_CATALOG_FILTERED_BY_CATEGORY = 'getPlansFromCatalogFilteredByCategory',
  GET_PLAN_GROUP_PRICE_RANGE_PER_MONTH = 'getPlanPriceRangePerMonth',
  GET_PLAN_GROUP_DEDUCTIBLE_RANGE = 'getPlanGroupDeductibleRange',
  GET_PRODUCTS_ON_PRODUCT_CART = 'getProductOnProductCart',
  GET_DEVICE_MAKE_FROM_PRODUCT_GROUP = 'getDeviceMakeFromProductGroup',
  GET_DEVICE_MODEL_FROM_PRODUCT_GROUP_AND_MAKE = 'getDeviceModelFromProductGroupAndMake',
  GET_PRODUCTS_IN_CART = 'getProductsInCart',
  GET_VALID_PRODUCTS_PRICES_INTERVALS_IN_CART = 'getValidProductsPricesIntervalsInCart',
  GET_PRODUCT_GROUP_NAME = 'getProductGroupName',
  GET_PRODUCT_CART_INTERVAL = 'getProductCardInterval',
  GET_PRODUCT_CART_FLAT_LIST = 'getProductCartFlatList',
  DOES_OTHER_PRODUCT_GROUPS_HAVE_PRODUCTS_IN_CART = 'doesOtherProductGroupsHaveProductsInCart',
  ARE_THEY_PRODUCTS_IN_CART = 'areTheyProductsInCart',
  GET_PRODUCT_PRICE_FROM_INTERVAL = 'getProductPriceFromInterval',
  GET_PRODUCTS_ANNUALIZED_COST = 'getProductsAnnualizedCost',
  GET_PRODUCT_TARGET_PRICES = 'getProductTargetPrices',
  GET_PRODUCT_GROUP_MARKETING_ATTRIBUTES_FROM_PRODUCT_GROUP_NAME = 'getProductGroupMarketingAttributesFromProductGroupName',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getters: any = {}

getters[
  ProductGetters.GET_PRODUCT_GROUP_MARKETING_ATTRIBUTES_FROM_PRODUCT_GROUP_NAME
] = function (state: ProductState) {
  return (productGroupName: string) => {
    const productGroup = state.products.find(
      (pg) => pg.groupName == productGroupName,
    )
    if (productGroup) {
      return productGroup.marketingAttributes
    }
    return null
  }
}

getters[ProductGetters.GET_PLANS_FROM_CATALOG_FILTERED_BY_CATEGORY] = function (
  state: ProductState,
) {
  return (category: string) =>
    state.products.filter((p) => p.planCategory === category)
}

getters[ProductGetters.GET_PLANS_FROM_CATALOG] = function (
  state: ProductState,
) {
  return state.products
}

getters[ProductGetters.GET_PLAN_GROUP_PRICE_RANGE_PER_MONTH] = function (
  state: ProductState,
) {
  return (groupName: string): { min: number; max: number } => {
    const productGroup = state.products.find(
      (productGroup) => productGroup.groupName === groupName,
    )
    if (!productGroup) {
      return { min: NaN, max: NaN }
    }
    return productGroup.products.reduce(
      (prev, product, index): { min: number; max: number } => {
        const priceMinMaxHolder = product.prices.reduce(
          (prevVal, price, index): { min: number; max: number } => {
            let currentPrice = NaN
            currentPrice =
              price.currentPrice *
              state.intervalMonthlyTransformationRatios[price.interval]

            if (currentPrice > prevVal.max) {
              prevVal.max = currentPrice
            }
            if (currentPrice < prevVal.min) {
              prevVal.min = currentPrice
            }
            return prevVal
          },
          { min: Infinity, max: 0 },
        )
        if (priceMinMaxHolder.max > prev.max) {
          prev.max = priceMinMaxHolder.max
        }
        if (priceMinMaxHolder.min < prev.min) {
          prev.min = priceMinMaxHolder.min
        }
        return prev
      },
      { min: Infinity, max: 0 },
    )
  }
}

getters[ProductGetters.GET_PLAN_GROUP_DEDUCTIBLE_RANGE] = function (
  state: ProductState,
) {
  return (groupName: string): { min: number; max: number } => {
    const productGroup = state.products.find(
      (productGroup) => productGroup.groupName === groupName,
    )
    if (!productGroup) {
      return { min: NaN, max: NaN }
    }

    if (productGroup.marketingAttributes?.deductibles) {
      const values: number[] = []
      for (const key in productGroup.marketingAttributes.deductibles) {
        values.push(productGroup.marketingAttributes.deductibles[key])
      }
      return { min: Math.min(...values), max: Math.max(...values) }
    }

    return productGroup.products.reduce(
      (prev, product, index): { min: number; max: number } => {
        const valuesToCompare = []

        if (product.deductibles.deductible !== null)
          valuesToCompare.push(product.deductibles.deductible)
        if (product.deductibles.repairDeductible !== null)
          valuesToCompare.push(product.deductibles.repairDeductible)
        if (product.deductibles.replacementDeductible !== null)
          valuesToCompare.push(product.deductibles.replacementDeductible)
        if (product.deductibles.screenRepairDeductible !== null)
          valuesToCompare.push(product.deductibles.screenRepairDeductible)

        const max = Math.max(...valuesToCompare)
        const min = Math.min(...valuesToCompare)

        if (max > prev.max) {
          prev.max = max
        }
        if (min < prev.min) {
          prev.min = min
        }
        return prev
      },
      { min: Infinity, max: 0 },
    )
  }
}

getters[ProductGetters.GET_PRODUCTS_ON_PRODUCT_CART] = function (
  state: ProductState,
) {
  return (productGroupName: string): Array<Product | null> => {
    if (state.productCart[productGroupName]) {
      return state.productCart[productGroupName]
    }
    return []
  }
}

getters[ProductGetters.GET_DEVICE_MAKE_FROM_PRODUCT_GROUP] = function (
  state: ProductState,
) {
  return (productGroupName: string) => {
    const productGroup = state.products.find(
      (pg) => pg.groupName === productGroupName,
    )
    if (!productGroup) return []

    let devices = productGroup.products.reduce(
      (prev: DeviceModelCatalog[], product, _) => {
        prev = prev.concat(product.deviceModels)
        return prev
      },
      [],
    )

    devices = devices.sort((d1, d2) =>
      d1.displayOrder > d2.displayOrder ? 1 : -1,
    )

    return Array.from(new Set(devices.map((d) => d.make)))
  }
}

getters[ProductGetters.GET_DEVICE_MODEL_FROM_PRODUCT_GROUP_AND_MAKE] =
  function (state: ProductState) {
    return ({
      productGroupName,
      make,
    }: {
      productGroupName: string
      make: string
    }): string[] => {
      const productGroup = state.products.find(
        (pg) => pg.groupName === productGroupName,
      )
      if (!productGroup) return []

      let devices = productGroup.products.reduce(
        (prev: DeviceModelCatalog[], product, _) => {
          prev = prev.concat(product.deviceModels)
          return prev
        },
        [],
      )

      devices = devices
        .sort((d1, d2) => (d1.displayOrder > d2.displayOrder ? 1 : -1))
        .filter((d) => d.make === make)

      return Array.from(new Set(devices.map((d) => d.model)))
    }
  }

getters[ProductGetters.GET_PRODUCTS_IN_CART] = function (state: ProductState) {
  const productHolder: any = {}

  for (const group in state.productCart) {
    productHolder[group] = state.productCart[group].filter((p) => p !== null)
  }

  return productHolder
}

getters[ProductGetters.GET_VALID_PRODUCTS_PRICES_INTERVALS_IN_CART] = function (
  state: ProductState,
) {
  let products: any = []

  for (const group in state.productCart) {
    products = products.concat(
      state.productCart[group].filter((p) => p !== null),
    )
  }

  const intervals: any = {}

  // Remove any duplicates
  products = [...new Set(products)]

  for (const product of products) {
    for (const price of product.prices) {
      if (intervals[price.interval]) {
        intervals[price.interval]++
      } else {
        intervals[price.interval] = 1
      }
    }
  }

  const resultIntervals = []

  for (const intervalsKey in intervals) {
    if (intervals[intervalsKey] === products.length) {
      resultIntervals.push(intervalsKey)
    }
  }

  return resultIntervals
}

getters[ProductGetters.GET_PRODUCT_GROUP_NAME] = function (
  state: ProductState,
) {
  return (productGroupIdentifier: string): string => {
    const productGroup = state.products.find(
      (ip) => ip.groupName === productGroupIdentifier,
    )
    return productGroup?.marketingAttributes?.groupName || ''
  }
}

getters[ProductGetters.GET_PRODUCT_CART_INTERVAL] = function (
  state: ProductState,
) {
  return state.productCartInterval
}

getters[ProductGetters.GET_PRODUCT_CART_FLAT_LIST] = function (
  state: ProductState,
) {
  return Object.keys(state.productCart).reduce(
    (prevProductGroupInCart: Product[], productGroupInCartKey, index) => {
      return prevProductGroupInCart.concat(
        state.productCart[productGroupInCartKey].filter(
          (p) => p !== null,
        ) as Product[],
      )
    },
    [],
  )
}

getters[ProductGetters.DOES_OTHER_PRODUCT_GROUPS_HAVE_PRODUCTS_IN_CART] =
  function (state: ProductState) {
    return (groupName: string) => {
      for (const key in state.productCart) {
        if (key !== groupName && state.productCart[key].length > 0) {
          return true
        }
      }
      return false
    }
  }

getters[ProductGetters.ARE_THEY_PRODUCTS_IN_CART] = function (
  state: ProductState,
) {
  for (const key in state.productCart) {
    if (state.productCart[key].length > 0) {
      return true
    }
  }
  return false
}

getters[ProductGetters.GET_PRODUCT_PRICE_FROM_INTERVAL] = function (
  state: ProductState,
) {
  for (const key in state.productCart) {
    if (state.productCart[key].length > 0) {
      return true
    }
  }
  return false
}

getters[ProductGetters.GET_PRODUCTS_ANNUALIZED_COST] = function (
  state: ProductState,
) {
  const annualizedCostByInterval: any = {}

  const products = Object.keys(state.productCart).reduce(
    (prevProductGroupInCart: ProductInCart[], productGroupInCartKey, index) => {
      return prevProductGroupInCart.concat(
        state.productCart[productGroupInCartKey].filter(
          (p) => p !== null,
        ) as ProductInCart[],
      )
    },
    [],
  )

  for (const product of products) {
    for (const price of product.prices) {
      if (!annualizedCostByInterval[price.interval])
        annualizedCostByInterval[price.interval] = 0
      annualizedCostByInterval[price.interval] += price.currentPrice
    }
  }

  for (const intervalKey in annualizedCostByInterval) {
    annualizedCostByInterval[intervalKey] =
      (state.intervalMonthlyTransformationRatios[intervalKey] *
        12 *
        annualizedCostByInterval[intervalKey]) /
      100
  }

  return annualizedCostByInterval
}

getters[ProductGetters.GET_PRODUCT_TARGET_PRICES] = function (
  state: ProductState,
  getters: any,
) {
  const flatCart = getters[ProductGetters.GET_PRODUCT_CART_FLAT_LIST]
  const interval = state.productCartInterval

  return flatCart.map((product: ProductInCart) =>
    product.prices.find((price) => price.interval === interval.toLowerCase()),
  )
}

export default getters
