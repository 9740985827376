
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { AppMutations } from '@/store/modules/app/mutations'
import { AppGetters } from '@/store/modules/app/getters'
import {
  PartnerPrograms,
  PartnerProgramsIds,
} from '@/constants/partner-programs'
import { MOBILE_WINDOW_WIDTH_BREAKPOINT } from '@/constants/window-property'
import Loading from '@/components/common/Loading.vue'
import Alerts from '@/components/common/Alerts.vue'
import { bootChat } from '@/services/intercom'
import { setPartnerProgram } from '@/services/api'
import { isProduction } from '@/utils'

export default defineComponent({
  components: {
    Loading,
    Alerts,
  },
  beforeCreate() {
    // default akko program
    let partnerProgramId = PartnerProgramsIds.AKKO_ID
    let partnerProgram = PartnerPrograms.AKKO

    if (window.location.origin.indexOf(PartnerPrograms.INJURED_GADGETS) != -1) {
      partnerProgramId = PartnerProgramsIds.INJURED_GADGETS_ID
      partnerProgram = PartnerPrograms.INJURED_GADGETS
    }
    // ... other programs

    setPartnerProgram(partnerProgramId)
    this.$store.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SET_PARTNER_PROGRAM}`,
      { partnerProgram, partnerProgramId },
    )
  },
  beforeMount() {
    this.processIsMobile()
    window.addEventListener('resize', this.processIsMobile)
  },
  mounted() {
    if (window.location.origin.indexOf(PartnerPrograms.INJURED_GADGETS) == -1) {
      bootChat()
      // Load HudSpot only for Akko pp, not for when running a partner program
      this.loadHubSpotScript()
    }
  },
  computed: {
    templateClasses(): string[] {
      return [
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
        ],
      ]
    },
    appIsLoading(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.APP_IS_LOADING}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
  },
  methods: {
    loadHubSpotScript(): void {
      if (isProduction) {
        const hsScript = document.createElement('script')
        const head = document.getElementsByTagName('head')[0]
        hsScript.setAttribute('type', 'text/javascript')
        hsScript.setAttribute('id', 'text/javascript')
        hsScript.setAttribute('src', '//js.hs-scripts.com/19752408.js')
        hsScript.async = true
        hsScript.defer = true
        head.appendChild(hsScript)
      }
    },
    getWindowWidth(): number {
      const windowWidth = window.innerWidth
      return windowWidth
    },
    processIsMobile(): void {
      const isMobileWindow =
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.GET_IS_MOBILE}`
        ]
      if (this.getWindowWidth() <= MOBILE_WINDOW_WIDTH_BREAKPOINT) {
        if (!isMobileWindow) this.setIsMobile(true)
      } else {
        if (isMobileWindow || isMobileWindow == undefined)
          this.setIsMobile(false)
      }
    },
    setIsMobile(isMobile: boolean): void {
      this.$store.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.SET_IS_MOBILE}`,
        isMobile,
      )
    },
  },
})
