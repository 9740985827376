
import { defineComponent } from 'vue'
import Modal from '@/components/common/Modal.vue'
import PartnerPropertyList from '@/components/properties/PartnerPropertyList/Index.vue'
import CreatePartnerProperty from '@/components/properties/PartnerPropertyList/CreatePartnerProperty.vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyStore } from '@/types/partner-property'

export default defineComponent({
  name: 'Properties',
  components: {
    PartnerPropertyList,
    CreatePartnerProperty,
    Modal,
  },
  data() {
    return {
      createUser: false,
      createPartnerProperty: false,
      partnerPropertyValues: {},
    }
  },
  async mounted() {
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_REPAIR_DEVICES}`,
    )
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_SELL_DEVICES}`,
    )
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_CELLULAR_SERVICES}`,
    )
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_PROPERTIES}`,
    )
  },
  methods: {
    onUpdate(id: number) {
      const partner: PartnerPropertyStore =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PARTNER_PROPERTY}`
        ](id)
      this.createPartnerProperty = true
      this.partnerPropertyValues = Object.assign({}, partner)
    },
    onCreatePartnerProperty() {
      this.createPartnerProperty = true
      this.partnerPropertyValues = {}
    },
    onClosePartnerPropertyModal() {
      this.createPartnerProperty = false
      this.partnerPropertyValues = {}
    },
  },
  computed: {
    getPartnerProperties(): PartnerPropertyStore[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES}`
      ]
    },
  },
})
