import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { SalesSubState, SalesSubStateFixedAmount } from '@/types/sales.d'

const defaultState: SalesSubState = {
  loading: true,
  error: false,
  errorMessage: '',
  data: {},
}

const defaultAmountState: SalesSubStateFixedAmount = {
  loading: true,
  error: false,
  errorMessage: '',
  data: {},
}

export default {
  state: {
    last7days: { ...defaultState },
    last30days: { ...defaultState },
    lastmonth: { ...defaultState },
    total: { ...defaultAmountState },
    today: { ...defaultState },
    customMonthByTeam: { ...defaultState },
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
