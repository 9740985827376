
import { defineComponent } from 'vue'
import { Form } from 'vee-validate'
import { ModulesIdentifiers } from '@/store'
import { updatePasswordFormValidationSchema } from '@/services/authentication/validations'
import { UserActions } from '@/store/modules/user/actions'
import InputPassword from '@/components/account/InputPassword.vue'
import { UserMutations } from '@/store/modules/user/mutations'
import { UserGetters } from '@/store/modules/user/getters'

export default defineComponent({
  name: 'UpdatePassword',
  components: {
    Form,
    InputPassword,
  },
  data() {
    return {
      validationSchema: updatePasswordFormValidationSchema(),
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
    }
  },
  emits: ['onFinish'],
  methods: {
    async onSubmit(values: {
      password: string
      repeat_password: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }): Promise<any> {
      const { password } = values
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.PUT_PASSWORD}`,
          password,
        )
        this.$store.commit(
          `${ModulesIdentifiers.USER}/${UserMutations.SET_RESET_PASSWORD}`,
        )
        this.$emit('onFinish', false)
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    isInvalid(errors: { [key: string]: string }, name: string): string {
      return errors[name] ? 'is-invalid' : ''
    },
  },
  computed: {
    shouldResetPassword(): boolean {
      const resetPassword =
        this.$store.getters[
          `${ModulesIdentifiers.USER}/${UserGetters.RESET_PASSWORD}`
        ]
      return resetPassword
    },
  },
})
