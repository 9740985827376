import { UserState, UserUpdateForm, UserPermissions } from '@/types/user'

export enum UserMutations {
  SET_USER_DATA = 'setUserData',
  SET_LOGOUT = 'setLogout',
  SET_PARTNER_PROPERTY_PERMISSIONS = 'setPartnerPropertyPermissions',
  SET_PARTNER_PROPERTIES_WITH_ACCESS = 'setPartnerPropertiesWithAccess',
  UPDATE_USER = 'updateUser',
  SET_RESET_PASSWORD = 'setResetPassword',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mutations: any = {}

mutations[UserMutations.SET_USER_DATA] = (
  state: UserState,
  data: { [key: string]: any },
): void => {
  if (data) {
    state.id = data.id
    state.friendlyId = data.friendly_id
    state.token = data.token
    state.email = data.email
    state.phone = data.phone
    state.firstName = data.first_name
    state.lastName = data.last_name
    state.idNumber = data.id_number
    state.positionTitle = data.position_title
    state.termsAccepted = data.terms_accepted
    state.resetPassword = data.reset_password
    state.authed = true
    state.intercomHashes = data.intercom_hashes
      ? {
          androidHash: data.intercom_hashes.android_hash,
          iosHash: data.intercom_hashes.ios_hash,
          webHash: data.intercom_hashes.web_hash,
        }
      : null
  }
}

mutations[UserMutations.SET_LOGOUT] = (state: UserState): void => {
  state.email = ''
  state.phone = ''
  state.token = ''
  state.authed = false
}

mutations[UserMutations.SET_PARTNER_PROPERTY_PERMISSIONS] = (
  state: UserState,
  permissions: UserPermissions,
): void => {
  state.permissions = permissions
}

mutations[UserMutations.SET_PARTNER_PROPERTIES_WITH_ACCESS] = (
  state: UserState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: { scope: string; value: string; rawPartnerProperties: any[] },
): void => {
  state.partnerPropertiesByScope[`${payload.scope}:${payload.value}`] =
    payload.rawPartnerProperties.map((raw) => ({
      email: raw.email,
      friendly_id: raw.friendly_id,
      id: raw.id,
      name: raw.name,
      phone: raw.phone,
      place: raw.place,
      provides_repairs: raw.provides_repairs,
      repair_devices: raw.repair_devices,
      website: raw.website,
      permissions: [],
    }))
}

mutations[UserMutations.UPDATE_USER] = (
  state: UserState,
  values: UserUpdateForm,
): void => {
  state.firstName = values.first_name
  state.lastName = values.last_name
  state.email = values.email
}

mutations[UserMutations.SET_RESET_PASSWORD] = (state: UserState): void => {
  state.resetPassword = false
}

export default mutations
