
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyStore } from '@/types/partner-property'
import PartnerProperty from './PartnerProperty.vue'

export default defineComponent({
  name: 'PartnerPropertyList',
  props: {
    css: {
      type: String,
      default: '',
    },
    onUpdate: {
      type: Function,
    },
  },
  components: {
    PartnerProperty,
  },
  computed: {
    getPartnerProperties(): PartnerPropertyStore[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES}`
      ]
    },
  },
})
