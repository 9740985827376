import posthog from 'posthog-js'
import { PostHogEvent } from '@/constants/posthog'

export const initTracking = function (): void {
  posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
    api_host: process.env.VUE_APP_POSTHOG_HOST,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true, // Highly recommended as a minimum!!
      },
    },
  })
}

export const trackEvent = function (
  event: PostHogEvent,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Record<string, any>,
): void {
  posthog.capture(event, payload)
}

export const identifyUser = function (userId: string): void {
  posthog.identify(`partner_user_${userId}`, { usedPartnerPortal: true })
}

export const reset = function (): void {
  posthog.reset()
}
