
import { defineComponent } from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { SignupFormFields } from '@/types/user'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import AuthLayout from './Layout.vue'
import { signupValidationSchema } from '@/services/authentication/validations'
import { PartnerActions } from '@/store/modules/partner/actions'
import { RouteNames } from '@/constants/router'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { AppActions } from '@/store/modules/app/actions'
import { AboutAkko, Country, PartnerType } from '@/types/app'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import { AboutAkkoOptions } from '@/constants/signup'
import { PartnerMutations } from '@/store/modules/partner/mutations'

export default defineComponent({
  name: 'Signup',
  components: {
    AuthLayout,
    Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
  },
  data() {
    return {
      validationSchema: signupValidationSchema(
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
        ],
      ),
      error: false,
      // eslint-disable-next-line
      errorMesage: '' as any,
      phoneMaskedValue: '',
      aboutAkko: '',
      ABOUT_AKKO_DESTRIBUTOR_ID: AboutAkkoOptions.ABOUT_AKKO_DESTRIBUTOR_ID,
      ABOUT_AKKO_OTHER_ID: AboutAkkoOptions.ABOUT_AKKO_OTHER_ID,
      AKKO_PROGRAM: PartnerPrograms.AKKO,
    }
  },
  mounted() {
    this.$store.dispatch(
      `${ModulesIdentifiers.APP}/${AppActions.GET_ABOUT_AKKO}`,
    )
    this.$store.dispatch(`${ModulesIdentifiers.APP}/${AppActions.GET_COUNTRY}`)
    this.$store.dispatch(
      `${ModulesIdentifiers.APP}/${AppActions.GET_PARTNER_TYPE}`,
    )
    this.$store.commit(
      `${ModulesIdentifiers.PARTNER}/${PartnerMutations.SET_DISTRIBUTOR_SLUG}`,
      this.$route?.params?.distributorSlug || null,
    )
  },
  methods: {
    isInvalid(errors: { [key: string]: string }, name: string): string {
      return errors[name] ? 'is-invalid' : ''
    },
    async onSubmit(values: SignupFormFields): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.SIGNUP_USER}`,
          values,
        )
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER}/${PartnerActions.READ_PARTNER}`,
          values,
        )
        this.$router.push({ name: RouteNames.ADD_PROPERTIES })
      } catch (error) {
        this.error = true
        this.errorMesage = error
      }
    },
  },
  computed: {
    aboutAkkoOptions(): AboutAkko[] {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_ABOUT_AKKO}`
      return this.$store.getters[key]
    },
    partnerProgram(): string {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      return this.$store.getters[key]
    },
    countryOptions(): Country[] {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_COUNTRY}`
      return this.$store.getters[key]
    },
    partnerTypeOptions(): PartnerType[] {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_PARTNER_TYPE}`
      return this.$store.getters[key]
    },
  },
})
